interface Colors {
  grey: string;
  lightGrey: string;
  red: string;
  orange: string;
  black: string;
}

const Colors: Colors = {
  grey: "#666",
  lightGrey: "#ccc",
  red: "#cc0000",
  orange: '#ff914d',
  black: "#000000"
};

export default Colors;
