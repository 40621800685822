import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const LoginButton: React.FC = () => {
  const { loginWithPopup } = useAuth0();

  return <button className="formexperiencebutton3" onClick={() => loginWithPopup()}>Sign in to continue</button>;
};

export const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <a className="signout" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Sign Out
    </a>
  );
};
