import React, { useState } from 'react';
import { GetQuestionDisplay, generateGUID } from '../util';
import HistoryTrend from './HistoryTrend';
import { InterviewObject } from './types';
import { useAppContext } from '../appContext';
import Nav from './nav';

interface HistoryScreenProps {
  questions?: { [key: string]: InterviewObject[] };
  AttemptedQuestionSelected?: (question: string, index: number | null) => void;
  adminView?: boolean;
}

function HistoryScreen(props: HistoryScreenProps) {
  const [showTrend, setShowTrend] = useState(false);
  const { state, reducers, navigateThenRun } = useAppContext();

  const {
      setloading,
      setprompt,
      setcurrScreen,
      setfeedback,
      setAction,
      setshowFeedback,
      setshowHistoryModal,
      setdisplayPastFeedback,
      setpastInfo,
      setretryPast} = reducers;

    const showPastFeedback = (question: string, i: number | null = null): void => {
        if (state.profileUser && state.profileUser.answeredQuestions) {
            let q = state.profileUser.answeredQuestions[question];
            let ind = i === null ? q.length - 1 : i;
            let info = q[ind];
            setpastInfo({...info, index: ind});
            setfeedback(info["feedback"]);
            setAction(info["actionSteps"]);
            setcurrScreen(3);
            setshowFeedback(true);
            setprompt(info["transcript"]);
            setshowHistoryModal(false);
            setdisplayPastFeedback(question);
            setretryPast(false);
            setloading(false);
        }
    }

  function getAttemptedQuestions() {
    var cont: JSX.Element[] = [];
    let questions: string[] = [];
    if(questions1){
      questions = Object.keys(questions1);
    }
    for (let i = 0; i < questions.length; i++) {
      cont[i] = (
        <React.Fragment key={`q${generateGUID()}`}>
          <div className={`wrapper displayquestionBank2`}>
            <p>{GetQuestionDisplay(questions[i], null)}</p>
            <div className="dropdown">
              <select
                id="dropdown"
                defaultValue={-1}
                onChange={(e) => {
                  AttemptedQuestionSelected!(questions[i], Number(e.target.value));
                }}
              >
                <option disabled value={-1}>
                  Date and Time
                </option>
                {questions1![questions[i]].map((x, ind) => (
                  <option key={generateGUID()} value={ind}>
                    {"timestamp" in x
                      ? x["timestamp"].slice(0, x["timestamp"].lastIndexOf(':'))
                      : ind}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return cont;
  }


  const questions1=  !props.adminView ? state.profileUser && state.profileUser.answeredQuestions ? state.profileUser.answeredQuestions: {} : props.questions;
  const AttemptedQuestionSelected = !props.adminView ? (e:any, i:any) => {
    navigateThenRun(()=>showPastFeedback(e, i),'/pastfeedback')} : props.AttemptedQuestionSelected;

  document.body.style.backgroundColor = 'white';

  return (
    <>
    { !props.adminView && <Nav/> }
    <div className="startsection instructions">
      <div className="title2">
        <h2> {props.adminView ? 'Member Progress' : 'My History'} </h2>
      </div>
      {/* {props.adminView && <p>{props.memberName}</p>} */}
      <div className="historyScreenButtons">
        <div>
          <button
            className={!showTrend ? 'formexperiencebuttonHistorySelected' : 'formexperiencebuttonEnd'}
            onClick={() => setShowTrend(false)}
          >
            Past Feedback{' '}
          </button>
          <button
            className={showTrend ? 'formexperiencebuttonHistorySelected' : 'formexperiencebuttonEnd'}
            onClick={() => setShowTrend(true)}
          >
            {' '}
            Progress Trends{' '}
          </button>
        </div>
        <div className="choices-new-questionbank2">
          {!showTrend && (
            <>
              <div className="questionbank-header">
                <b>Select a Question Date and Time to See your past Feedback</b>
              </div>
              {getAttemptedQuestions()}
            </>
          )}
          {showTrend && <HistoryTrend questions={questions1 ? questions1 : {}} />}
        </div>
      </div>
    </div>
    </>
  );
}

export default HistoryScreen;
