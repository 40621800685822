import { Product } from './types';

/** Stripe publishable key */
export const STRIPE_PUBLISHABLE_KEY = "pk_live_51OdSrXF9aP240xtGSrlCczzXplXOPdqyoRwwO3oF68UbDtqj38Z1BsxTT1F4mBgmY7DAQ8FwjBbzWQkY2cQQFBBK00tuOpt77b";

/** Stripe product plan ids */
export const SIXMONTH_PRODUCT_ID = "price_1PB4iLF9aP240xtGkxe7ZUn9";
export const MONTHLY_PRODUCT_ID = "price_1PB4idF9aP240xtGHuOTBOKL";
export const PACK10_PRODUCT_ID = "price_1OpjpmF9aP240xtGzK8Gd4x1";

/** Stripe product plans */
export const PRODUCT_PLANS: Product[] = [
  {
    id: MONTHLY_PRODUCT_ID,
    name: "Monthly",
    description: "Unlimited access to all features.",
    price: 44.99,
    cadence: "month"
  },
  {
    id: SIXMONTH_PRODUCT_ID,
    name: "Semi-Annually",
    description: "Unlimited access to all features.",
    price: 199.99,
    cadence: "6 months"
  }
];
