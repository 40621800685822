import React from 'react';
import ReactModal from 'react-modal';

interface ErrorModalProps {
  showApiError: boolean;
  toggleApiError: (show: boolean) => void;
  errorText: string;
}

const ErrorModal: React.FC<ErrorModalProps> = (props) => {
  const customStyles: ReactModal.Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      maxWidth: '60vw',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1.5rem',
      backgroundColor: '#f4f4f5'
    },
  };

  return (
    <ReactModal
      isOpen={props.showApiError}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => props.toggleApiError(false)}
      ariaHideApp={false}
    >
      <div className="startsection scroll instructions">
        <h2>{props.errorText}</h2>
      </div>
    </ReactModal>
  );
}

export default ErrorModal;
