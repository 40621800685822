import React from 'react';
import ReactModal from 'react-modal';
import Loading from 'react-loading';

interface LoadingOverlayProps {
  loadingOverlay: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {
  const customStyles: ReactModal.Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      maxWidth: '60vw',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      backgroundColor: 'transparent'
    },
  };

  return (
    <ReactModal
      isOpen={props.loadingOverlay}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
    >
      <div className="loading">
        <Loading
          type="bubbles"
          color="#000000"
          height={200}
          width={100}
        />
      </div>
    </ReactModal>
  );
}

export default LoadingOverlay;
