import React from 'react';
import ReactModal from 'react-modal';
import IntroForm from './IntroForm';

interface IntroFormModalProps {
  showIntroForm: boolean;
  toggleintroFormModal: () => void;
  email: string;
  verified: boolean;
  submitForm: (form: any) => void;
  refreshUser: () => void;
  canClosePopup: boolean;
}

function IntroFormModal(props: IntroFormModalProps) {
  const customStyles: ReactModal.Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      maxWidth: '80vw',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1.5rem',
      backgroundColor: '#f4f4f5',
    },
  };

  return (
    <ReactModal
      isOpen={props.showIntroForm}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      onRequestClose={props.toggleintroFormModal}
      ariaHideApp={false}
    >
      <div className="startsection instructions">
        <IntroForm
          email={props.email}
          verified={props.verified}
          submitForm={(form) => props.submitForm(form)}
          refreshUser={props.refreshUser}
        />
      </div>
    </ReactModal>
  );
}

export default IntroFormModal;