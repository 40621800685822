import Loading from "react-loading";
import "./custom.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Nav from "./components/nav";
import LandingPage from "./components/LandingPage";
import { useAppContext } from "./appContext";
import { useLocation, useNavigate } from "react-router-dom";
import { getPaymentInfo, removePaymentInfo } from "./components/Pay";
import { useEffect } from "react";

interface AppProps {}

export default function App(props: AppProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, reducers, startFunc, stopAudio, navigateThenRun } =
    useAppContext();

  const {
    setloading,
    setprompt,
    setcurrentQuestion,
    setcurrScreen,
    setfeedback,
    setrole,
    setdomain,
    setcustomQ,
    setjobDescriptionQ,
    settype,
    setspecType,
    setAction,
    setshowFeedback,
    setshowHistoryModal,
    setintQuestion,
    setqType,
    setdisplayPastFeedback,
    setpastInfo,
    setretryPast,
    setrunSetQs,
    setshowPaymentModal
  } = reducers;

  useEffect(() => {
    const savedPaymentInfo = getPaymentInfo();
    if (savedPaymentInfo) {
      // Use the saved payment info
      setshowPaymentModal(true);
      removePaymentInfo();
    }
  }, []);

  const showPastFeedback = (
    question: string,
    i: number | null = null
  ): void => {
    if (state.profileUser && state.profileUser.answeredQuestions) {
      let q = state.profileUser.answeredQuestions[question];
      let ind = i === null ? q.length - 1 : i;
      let info = q[ind];
      setpastInfo({ ...info, index: ind });
      setfeedback(info["feedback"]);
      setAction(info["actionSteps"]);
      setcurrScreen(3);
      setshowFeedback(true);
      setprompt(info["transcript"]);
      setshowHistoryModal(false);
      setdisplayPastFeedback(question);
      setretryPast(false);
      setloading(false);
    }
  };

  return (
    <>
      {!state.initialLoad && (
        <>
          <Nav />
            <>
              {state.beginningLoad && (
                <div className="loading">
                  <Loading
                    type="bubbles"
                    color="#000000"
                    height={200}
                    width={100}
                  />
                </div>
              )}
                  <LandingPage
                    setRole={(r) => setrole(r)}
                    setDomain={(r) => setdomain(r)}
                    setType={(r) => settype(r)}
                    setSpecType={(r) => setspecType(r)}
                    setJobDescriptionQ={(r) => setjobDescriptionQ(r)}
                    jobDescriptions={
                      state.profileUser
                        ? state.profileUser.JobDescriptions !== undefined
                          ? state.profileUser.JobDescriptions
                          : {}
                        : null
                    }
                    setCustomQ={(e) => setcustomQ(e)}
                    setQs={() => setrunSetQs(true)}
                    setintQuestion={(val) => setintQuestion(val)}
                    setqType={(e) => setqType(e)}
                    QuestionSelected={(i) => setcurrentQuestion(i)}
                    next={() => setcurrScreen(2)}
                    startFunc={() => startFunc(true, false)}
                    user={state.profileUser}
                    showLastFeedback={(e) => {
                      navigateThenRun(
                        () => showPastFeedback(e),
                        "/pastfeedback"
                      );
                    }}
                  />
            </>
        </>
      )}
      {state.initialLoad && (
        <div className="loading">
          <Loading type="bubbles" color="#000000" height={200} width={100} />
        </div>
      )}
    </>
  );
}
