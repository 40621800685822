import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { LogoutButton } from './signin';
import PdfExtractor from './pdfExtractor';
import { GetPortalLink } from '../api';
import { User } from './types';


interface SettingsProps {
    showSettings: boolean;
    toggleSettings: (show: boolean) => void;
    user?: User | null;
    audioEnabled: boolean;
    updateResume: (text: string, file: string) => void;
    setAudio: (enabled: boolean) => void;
  }

function Settings(props: SettingsProps) {
  const [resume, setResume] = useState<{ text: string | undefined; file: string | undefined }>({
    text: props.user?.resumetext,
    file: props.user?.resumename,
  });
  const [audio, setAudio] = useState<boolean>(props.audioEnabled);

  const customStyles: ReactModal.Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      maxWidth: '82vw',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1.5rem',
      backgroundColor: '#f4f4f5',
    },
  };

  useEffect(() => {
    if (props.showSettings) {
      setResume({ text: props.user?.resumetext, file: props.user?.resumename });
      setAudio(props.audioEnabled);
    }
  }, [props]);

  return (
    <ReactModal
      isOpen={props.showSettings}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => {
        props.toggleSettings(false);
      }}
      ariaHideApp={false}
    >
      <div className="startsection scroll">
        <div className="title">
          <h2 style={{ color: 'black' }}> Profile Settings </h2>
        </div>
        <div style={{ textAlign: 'left' }}>
          <div className="settings">
            <div className="form-group">
              <p style={{ color: 'black' }}>
                <b>Name:</b> {props.user?.name}{' '}
              </p>
              <hr className="custom-line"></hr>
            </div>
            <div className="form-group">
              <p style={{ color: 'black' }}>
                <b>Email:</b> {props.user?.email}
              </p>
              <hr className="custom-line"></hr>
            </div>
            <PdfExtractor
              resumeFile={resume.file}
              updateResume={(t: string, f: string) => setResume({ file: f, text: t })}
            />
            <hr className="custom-line"></hr>
            <div className="form-group">
              <p style={{ color: 'black' }}>Enable Speaker Audio</p>
              <label className="toggle">
                <input
                  type="checkbox"
                  checked={audio}
                  onChange={(e) => setAudio(e.target.checked)}
                />
                <span className="slider"></span>
              </label>
            </div>
            <div className="settingsButtons">
              <LogoutButton />
              {props.user?.subscription !== 'FREE_TIER' && props.user?.hasActiveSubscription && (
                <a
                  className="signout"
                  onClick={() => GetPortalLink().then((url) => { if (url) {window.open(url, '_blank'); }})}
                >
                  Manage Subscription
                </a>
              )}
              {props.user?.subscription === 'FREE_TIER' && !props.user?.isLegacyUser && (
                <p className="remainingq">
                  {props.user.questionsLeft} question
                  {props.user.questionsLeft !== 1 ? 's' : ''} left{' '}
                </p>
              )}
              <button
                onClick={() => {
                  props.updateResume(resume.text || '', resume.file || '');
                  props.setAudio(audio);
                  props.toggleSettings(false);
                }}
                className="formexperiencebutton-settings"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default Settings;