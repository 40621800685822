import React, { useState, useEffect } from 'react';
import Loading from "react-loading";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { Option, JobDescription, AnsweredQuestions } from './types';
import { capitalizeFirstLetter, hasPremSub, hasNonTrialPremSub, GetFriendlyOrgNames} from '../util'; 
import { useAppContext } from '../appContext';
import Nav from './nav'
import { updateJobDescriptions } from '../api';
import PrepScreen from './prepScreen';

//5 question screens
const sampleJD = `e.g. 
Microsoft is seeking a talented and motivated Software Engineer to join our dynamic team. As a Software Engineer, you will be responsible for designing, developing, and maintaining high-quality software solutions that drive our innovative products and services. You will work in a collaborative environment with cross-functional teams to create cutting-edge technology that impacts millions of users worldwide.

Responsibilities:

Design and Development: Design, develop, and implement software solutions that meet the needs of our customers and align with our strategic goals.
Code Quality: Write clean, maintainable, and efficient code following best practices and coding standards.
Collaboration: Collaborate with product managers, designers, and other engineers to gather requirements, define project scope, and deliver high-quality software on time.
Testing and Debugging: Perform unit testing, integration testing, and debugging to ensure the reliability and performance of the software.
Continuous Improvement: Participate in code reviews, provide constructive feedback, and continuously improve software development processes.
Documentation: Create and maintain technical documentation to support software development and deployment processes.
Innovation: Stay up-to-date with the latest industry trends and technologies, and apply them to improve existing products and develop new solutions.
Qualifications:

Education: Bachelor's or Master's degree in Computer Science, Engineering, or a related field.
Experience: 2+ years of experience in software development, preferably in a high-tech environment.
Technical Skills:
Proficiency in one or more programming languages such as C#, Java, Python, or JavaScript.
Experience with software development frameworks and tools such as .NET, Angular, React, or similar.
Knowledge of cloud platforms such as Azure, AWS, or Google Cloud.
Strong understanding of data structures, algorithms, and software design principles.
Problem-Solving: Excellent problem-solving skills and the ability to think critically and creatively.
Communication: Strong written and verbal communication skills, with the ability to clearly articulate technical concepts to non-technical stakeholders.
Team Player: Ability to work effectively both independently and as part of a team.
Preferred Qualifications:

Experience with DevOps practices and tools such as Docker, Kubernetes, Jenkins, or Git.
Familiarity with Agile development methodologies.
Experience with AI and machine learning technologies.
Contributions to open-source projects or participation in hackathons and coding competitions.`;

export const roleOptions: Option[] = [
    { value: 'product management', label: 'Product Management' },
    { value: 'software engineering', label: 'Software Engineering'},
    { value: 'cybersecurity engineering', label: 'Cybersecurity Engineering' },
    { value: 'web development', label: 'Web Development' },
    { value: 'ux designer', label: 'UX Designer' },
    { value: 'business analyst', label: 'Business Analyst' },
    { value: 'data scientist', label: 'Data Scientist' }
];

export const minroleOptions: Option[] = [
    { value: 'product management', label: 'Product Management' },
    { value: 'software engineering', label: 'Software Engineering'},
    { value: 'web development', label: 'Web Development' },
    { value: 'business analyst', label: 'Business Analyst' }
];

const companyOptions: Option[] = [
    { value: "Apple Inc.", label: "Apple Inc." },
    { value: "Microsoft Corporation", label: "Microsoft Corporation" },
    { value: "Amazon.com Inc.", label: "Amazon.com Inc." },
    { value: "Alphabet Inc. (Google)", label: "Alphabet Inc. (Google)" },
    { value: "Meta Platforms, Inc. (formerly Facebook)", label: "Meta Platforms, Inc. (formerly Facebook)" },
    { value: "Samsung Electronics Co., Ltd.", label: "Samsung Electronics Co., Ltd." },
    { value: "Tencent Holdings Limited", label: "Tencent Holdings Limited" },
    { value: "Alibaba Group Holding Limited", label: "Alibaba Group Holding Limited" },
    { value: "Intel Corporation", label: "Intel Corporation" },
    { value: "Cisco Systems, Inc.", label: "Cisco Systems, Inc." },
    { value: "IBM (International Business Machines Corporation)", label: "IBM (International Business Machines Corporation)" },
    { value: "Oracle Corporation", label: "Oracle Corporation" },
    { value: "Dell Technologies Inc.", label: "Dell Technologies Inc." },
    { value: "HP Inc. (Hewlett-Packard)", label: "HP Inc. (Hewlett-Packard)" },
    { value: "Adobe Inc.", label: "Adobe Inc." },
    { value: "NVIDIA Corporation", label: "NVIDIA Corporation" },
    { value: "Netflix, Inc.", label: "Netflix, Inc." },
    { value: "PayPal Holdings, Inc.", label: "PayPal Holdings, Inc." },
    { value: "Salesforce.com, Inc.", label: "Salesforce.com, Inc." },
    { value: "SAP SE", label: "SAP SE" },
    { value: "Accenture plc", label: "Accenture plc" },
    { value: "VMware, Inc.", label: "VMware, Inc." },
    { value: "Uber Technologies, Inc.", label: "Uber Technologies, Inc." },
    { value: "Lyft, Inc.", label: "Lyft, Inc." },
    { value: "Zoom Video Communications, Inc.", label: "Zoom Video Communications, Inc." },
    { value: "Twitter, Inc.", label: "Twitter, Inc." },
    { value: "Snap Inc.", label: "Snap Inc." },
    { value: "Airbnb, Inc.", label: "Airbnb, Inc." },
    { value: "Dropbox, Inc.", label: "Dropbox, Inc." },
    { value: "Slack Technologies, Inc. (acquired by Salesforce)", label: "Slack Technologies, Inc. (acquired by Salesforce)" },
    { value: "Atlassian Corporation Plc", label: "Atlassian Corporation Plc" },
    { value: "Reddit, Inc.", label: "Reddit, Inc." },
    { value: "Pinterest, Inc.", label: "Pinterest, Inc." },
    { value: "Spotify Technology S.A.", label: "Spotify Technology S.A." },
    { value: "Square, Inc.", label: "Square, Inc." },
    { value: "Coinbase Global, Inc.", label: "Coinbase Global, Inc." },
    { value: "Twilio Inc.", label: "Twilio Inc." },
    { value: "Shopify Inc.", label: "Shopify Inc." },
    { value: "Zillow Group, Inc.", label: "Zillow Group, Inc." },
    { value: "Expedia Group, Inc.", label: "Expedia Group, Inc." },
    { value: "Booking Holdings Inc.", label: "Booking Holdings Inc." },
    { value: "eBay Inc.", label: "eBay Inc." },
    { value: "Tencent Music Entertainment Group", label: "Tencent Music Entertainment Group" },
    { value: "Electronic Arts Inc. (EA)", label: "Electronic Arts Inc. (EA)" },
    { value: "Activision Blizzard, Inc.", label: "Activision Blizzard, Inc." },
    { value: "Take-Two Interactive Software, Inc.", label: "Take-Two Interactive Software, Inc." },
    { value: "Unity Software Inc.", label: "Unity Software Inc." },
    { value: "Roblox Corporation", label: "Roblox Corporation" },
    { value: "WeWork Companies Inc.", label: "WeWork Companies Inc." }
];

function Track() {
    const { auth0, state, reducers, isAuth, startFunc } = useAppContext();

    const {setstart,
        setqPrompt,
        setloading,
        setrecognizedsp,
        setinterviewer,
        setmicOn,
        setrecognizedspTemp,
        setprompt,
        setfullprompt,
        setquestions,
        setquestionLevelMap,
        setcurrentQuestion,
        setcurrScreen,
        setfeedback,
        setfeedbackDuration,
        setrole,
        setlevel,
        setdomain,
        setcustomQ,
        setjobDescriptionQ,
        settype,
        setspecType,
        setprocessing,
        setcode,
        setqA,
        setAction,
        setshowAns,
        setshowFeedback,
        setshowAction,
        setcanStop,
        setcodingLang,
        setnext,
        setinitialLoad,
        setwholeState,
        setprevious,
        setprivateSession,
        setshowNextConfirmation,
        setshowHistoryModal,
        setshowsigninModal,
        setshowintroFormModal,
        setshowPaymentModal,
        setshowTranscript,
        setshowApiError,
        setErrorText,
        setshowSettings,
        setshowFeedbackBox,
        setsessionStopped,
        setresume,
        setprofileUser,
        setuserAudio,
        setisHelpful,
        setisHelpfulMessage,
        setintQuestion,
        setqType,
        setquestionQuery,
        setspeechLen,
        sethitMaxLen,
        setdisplayPastFeedback,
        setpastInfo,
        setretryPast,
        setcanPractice,
        setbeginningLoad,
        setNextFunc,
        setfeedbackNextFunc,
        setAbortController,
        setGPTCallCount,
        setcontextLength,
        pushqPrompt,
        pushprompt,
        pushfullprompt,
        pushquestions,
        pushquestionLevelMap,
        popprompt,
        setgetq,
        setorgQuestions,
        setuseWhiteboard,
        setcontinueFunc,
        setrunSetQs} = reducers;

    const attempted=state.profileUser && state.profileUser.answeredQuestions ? state.profileUser.answeredQuestions: {};
    const jobDescriptions=state.profileUser ? (state.profileUser.JobDescriptions !== undefined ? state.profileUser.JobDescriptions : {}) : null;
    const organization = GetFriendlyOrgNames(state.profileUser ? state.profileUser.organization : null);
    const JDOptions = jobDescriptions ? Object.keys(jobDescriptions).map((x) => ({ value: x, label: x })) : [];
    const selected = 'selectedButton';
    document.body.style.backgroundColor = 'white';
    
    const [readOnly, setReadOnly] = useState(false);

    const handleJDTitle = (e: Option | null) => {
        if (e !== null && e.value !== null) {
            if (jobDescriptions && jobDescriptions.hasOwnProperty(e.value)) {
                setjobDescriptionQ({ title: e.value, description: jobDescriptions[e.value] });
                setReadOnly(true);
            } else {
                setjobDescriptionQ({ ...state.jobDescriptionQ, title: e.value });
                setReadOnly(false);
            }
        } else {
            setjobDescriptionQ({ ...state.jobDescriptionQ, title: "" });
        }
    };

    useEffect(() => {
        if (state.intQuestion == 3) {
            if (jobDescriptions && jobDescriptions.hasOwnProperty(state.jobDescriptionQ.title)) {
                setReadOnly(true);
            }
        }
    }, [state.intQuestion]);

    const submitJD = async (jd: any): Promise<void> => {
        let user2 = await updateJobDescriptions(jd);
        setprofileUser(user2);
    }

    const hasPremiumSub = (): boolean => {
        return hasPremSub(state.profileUser);
    }

    const hasNonTrialPremiumSub = (): boolean => {
        return hasNonTrialPremSub(state.profileUser);
    }

    const signinModal = (): void => {
        setshowsigninModal(!state.showsigninModal);
    }
    
    const introFormModal = (): void => {
        setshowintroFormModal(!state.showintroFormModal);
    }
    
    const hasOrgQs = (category: string | null = null): boolean => {
        if(state.orgQuestions) {
            if(category) {
                return state.orgQuestions[category] !== undefined && state.orgQuestions[category].length > 0;
            } else {
                for (const key in state.orgQuestions) {
                    if (state.orgQuestions[key].length > 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    }


    const authModal = (): void => {
        if (isAuth()) {
            introFormModal();
        } else {
            signinModal();
        }
    }

    const qBank = (specType: string) => {
        return (
            <div className="question-subtext-2">
                {specType === "resume based" && state.resume.resumeName === '' && state.canPractice && <a onClick={() => setshowSettings(!state.showSettings)}>or select from question bank</a>}
                {((specType === "resume based" && state.resume.resumeName !== '' && hasNonTrialPremiumSub()) || specType !== "resume based") && state.canPractice && (hasPremiumSub()) && <a onClick={() => { setqType('choose question'); setspecType(specType); setrunSetQs(true); setintQuestion(state.intQuestion+1) }}>or select from question bank</a>}
                {!state.canPractice && <a onClick={() => authModal()}>or select from question bank</a>}
                {state.canPractice && specType === "resume based" && state.resume.resumeName !== '' && !hasNonTrialPremiumSub() && <a onClick={() => setshowPaymentModal(true)}>or select from question bank</a>}
                {state.canPractice && specType !== "resume based" && !hasPremiumSub() && <a onClick={() => setshowPaymentModal(true)}>or select from question bank</a>}
            </div>
        );
    };

    function getNavigation() {
        return (
            <div>
                <p>
                { state.intQuestion >= 2 && <><span onClick={()=>{setintQuestion(1);setcurrentQuestion(-1);}} className='navigationText'>{capitalizeFirstLetter(state.role)}</span> <span>{' > '}</span></>}

                { state.intQuestion >= 3 && <><span onClick={()=>{setintQuestion(2);setcurrentQuestion(-1);}}className='navigationText'>{state.type==="domain"? "Company Question" : state.type ==="custom" ? "Custom Question" : state.type==="org"? `${organization} Question` : state.type ==="jobDescriptionQ"? "Job Description": capitalizeFirstLetter(state.type)}</span><span>{' > '}</span></>
                }

                {state.intQuestion >= 4 && (state.type==="domain" || state.type ==="custom") && <><span onClick={()=>{setintQuestion(3);setcurrentQuestion(-1);}} className='navigationText'>{capitalizeFirstLetter(state.domain)}</span><span>{' > '}</span></>
                }

                {state.intQuestion === 5 && <><span onClick={()=>{setintQuestion(4);setcurrentQuestion(-1);}} className='navigationText'>Question Bank</span><span>{' > '}</span> </>
                }

                </p>
            </div>
        );
    }

    function getQuestionPacks(){
        return(
        <div className = "choices-new-full">
            {state.role === "software engineering" && state.type === "technical" &&
            <div className="packDiv">
                <div className="pack-border border-1">
                    <h2>Coding</h2>
                    <button className={`gradient-button grade-1 ${state.specType === 'coding' ? selected: ''}`} onClick={()=>{setspecType('coding');setqType('random question'); setcurrentQuestion(-1); setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank('coding')}
                </div>
                <div className="pack-border border-2">
                    <h2>Software Concepts</h2>
                    <button className={`gradient-button grade-2 ${state.specType === 'software concepts' ? selected: ''}`} onClick={()=>{setspecType('software concepts');setqType('random question'); setcurrentQuestion(-1); setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank('software concepts')}
                </div>
                <div className="pack-border border-3">
                    <h2>System Design</h2>
                    <button className={`gradient-button grade-3 ${state.specType === 'system design' ? selected: ''}`} onClick={()=>{setspecType('system design');setqType('random question'); setcurrentQuestion(-1); setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank('system design')}
                </div>
            </div>
            }
            {state.role === "product management" && state.type === "technical" &&
            <div className="packDiv">
                <div className="pack-border border-1">
                    <h2>Product Design</h2>
                    <button className={`gradient-button grade-1 ${state.specType === 'product design' ? selected: ''}`} onClick={()=>{setspecType('product design');setqType('random question'); setcurrentQuestion(-1); setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank('product design')}
                </div>
                <div className="pack-border border-2">
                    <h2>Estimation</h2>
                    <button className={`gradient-button grade-2 ${state.specType === 'estimation' ? selected: ''}`} onClick={()=>{setspecType('estimation');setqType('random question'); setcurrentQuestion(-1); setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank('estimation')}
                </div>
                <div className="pack-border border-3">
                    <h2>Strategy</h2>
                    <button className={`gradient-button grade-3 ${state.specType === 'strategy' ? selected: ''}`} onClick={()=>{setspecType('strategy'); setqType('random question'); setcurrentQuestion(-1); setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank('strategy')}
                </div>
            </div>
            }
            {state.role !== "product management" && state.role !== "software engineering" && state.type === "technical" &&
            <div className="packDiv">
                <div className="pack-border border-1">
                    <h2>{capitalizeFirstLetter(state.role)} Pack</h2>
                    <button className={`gradient-button grade-1 ${state.specType === 'role' ? selected: ''}`} onClick={()=>{setspecType('role');setqType('random question'); setcurrentQuestion(-1); setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank('role')}
                </div>
            </div>
            }
            {state.type === "behavioral" &&
            <>
            <div className="packDiv">
                <div className="pack-border border-1">
                    <h2>Essential 10 Pack</h2>
                    <button className={`gradient-button grade-1 ${state.specType === 'general' ? selected: ''}`} onClick={()=>{setspecType('general');setqType('random question'); setcurrentQuestion(-1); setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank('general')}
                </div>
                <div className="pack-border border-2">
                    <h2>{capitalizeFirstLetter(state.role)} Pack</h2>
                    <button className={`gradient-button grade-2 ${state.specType === 'role' ? selected: ''}`} onClick={()=>{setspecType('role');setqType('random question'); setcurrentQuestion(-1); setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank('role')}
                </div>
                <div className="pack-border border-3">
                    <h2>Resume Based Pack</h2>
                    {state.resume.resumeName !== '' && (hasNonTrialPremiumSub()) && <button className = {`gradient-button grade-3 ${state.specType === 'resume based' ? selected: ''}`} onClick={()=>{setspecType('resume based');setqType('random question'); setcurrentQuestion(-1); setcurrScreen(2);}}>Start Randomized Interview</button>}
                    {state.resume.resumeName === '' && state.canPractice && <button className = "gradient-button grade-3" onClick={()=>setshowSettings(!state.showSettings)}>Start Randomized Interview</button>}
                    {state.resume.resumeName !== '' && !hasNonTrialPremiumSub() &&  <button className = "gradient-button grade-3" onClick={()=>setshowPaymentModal(true)}>Start Randomized Interview</button>}
                    {state.resume.resumeName === '' && !state.canPractice &&  <button className = "gradient-button grade-3" onClick={()=>authModal()}>Start Randomized Interview</button>}
                    {qBank('resume based')}
                </div>
            </div>
            </>
            }
            {state.type === "domain" &&
            <div className="packDiv">
                <div className="pack-border border-1">
                    <h2>{capitalizeFirstLetter(state.domain)} Pack</h2>
                    <button className="gradient-button grade-1" onClick={()=>{setspecType("domain");setqType('random question'); setcurrentQuestion(-1);setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank("domain")}
                </div>
            </div>
            }
            {state.type === "org" &&
            <div className="packDiv">
                <div className="pack-border border-1">
                    <h2>{capitalizeFirstLetter(organization)} Pack</h2>
                    <button className="gradient-button grade-1" onClick={()=>{setspecType("org");setqType('random question'); setcurrentQuestion(-1);setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank("org")}
                </div>
            </div>
            }
            {state.type === "jobDescriptionQ" &&
            <div className="packDiv">
                <div className="pack-border border-1">
                    <h2>Job Description Pack</h2>
                    <button className="gradient-button grade-1" onClick={()=>{setspecType("jobDescriptionQ");setqType('random question'); setcurrentQuestion(-1);setcurrScreen(2);}}>Start Randomized Interview</button>
                    {qBank("jobDescriptionQ")}
                </div>
            </div>
            }
        </div>
        )
    }

    function getQuestionBank() {
        var cont: JSX.Element[] = [];
        if (state.questions.length === 0) {
            return <div className="loading">
                <Loading
                    type="bubbles" color="#000000"
                    height={200} width={100} />
            </div>
        }
        for (let i = 0; i < state.questions.length; i++) {
            cont.push(
                <React.Fragment key={i}>
                    <div onClick={() => setcurrentQuestion(i)} className={`wrapper displayquestionBank ${state.currentQuestion === i ? selected : ""}`}>
                        <span className={attempted[state.questions[i]] ? 'Attempted' : 'Attempted hidden'}>Attempted</span>
                        <p>{state.questions[i]}</p>
                        {state.questionLevelMap && Object.keys(state.questionLevelMap).length !== 0 && <span className={state.questionLevelMap[state.questions[i]]}>{state.questionLevelMap[state.questions[i]]}</span>}
                    </div>
                </React.Fragment>
            );
        }
        return cont;
    }

    return (        
        <>
        <Nav/>
        {state.currScreen === 1 &&
        <>
             <div className="qNav">
        {getNavigation()}
        </div>
        <div className = "formsection margined-new">
            {state.intQuestion == 1 &&
        <div className="question-1-new">
            <div className="question-title">
                <div className="question">
                    <h3>Specify Role</h3>
                </div>
                <div className="question-subtext">
                    <h4>Determine the role-type for your interview.</h4>
                </div>
            </div>
            {state.canPractice && hasNonTrialPremiumSub() && 
            <div className = "input-choices-new">
                <CreatableSelect 
                placeholder="select or type in custom role"
                isClearable 
                inputValue={state.role}
                options={roleOptions} 
                onInputChange={(e)=>{if(e !=="" || state.role.length === 1){setrole(e);}}} //fix bug on mobile
                onChange={(e)=>{setrole(e !== null ? e.value: '');if(state.role!==""||(e !== null && e.value !== "")){setintQuestion(state.intQuestion+1)}}}/>
            
            </div>}
            {(!state.canPractice || !hasNonTrialPremiumSub()) &&
                 <div className = "input-choices-new">
                 <Select 
                 placeholder="select or type in custom role"
                 isClearable 
                 inputValue={state.role}
                 options={minroleOptions} 
                 onInputChange={(e)=>{if(e !=="" || state.role.length === 1){setrole(e);}}} //fix bug on mobile
                 onChange={(e)=>{setrole(e !== null ? e.value: '');if(state.role!==""||(e !== null && e.value !== "")){setintQuestion(state.intQuestion+1)}}}/>
             
             </div>
            }
            {state.role !== "" && <div className="next">
                <i onClick={()=>setintQuestion(state.intQuestion+1)} className="fas fa-circle-arrow-right fa-2x"></i>
                <p>Next</p>
            </div>}
        </div>}

                {state.intQuestion == 2 &&
                    <div className="question-1-new">
                        <div className="question-title">
                            <div className="question">
                            <h3>Select Interview Track</h3>
                            </div>
                            <div className="question-subtext">
                                <h4>Determine the type of interview you want to practice for.</h4>
                            </div>
                        </div>
                        <div className="radio-button-group">
                            <label>
                                <input
                                    type="radio"
                                    value="behavioral"
                                    checked={state.type === "behavioral"}
                                    onChange={() => { settype("behavioral"); setintQuestion(state.intQuestion+2); }}
                                />
                                Behavioral
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="technical"
                                    checked={state.type === "technical"}
                                    onChange={() => { settype("technical"); setintQuestion(state.intQuestion+2); }}
                                />
                                Technical
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="custom"
                                    checked={state.type === "custom"}
                                    onChange={() => { settype("custom"); setspecType("custom"); setintQuestion(state.intQuestion+1) }}
                                />
                                Custom Question
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="domain"
                                    checked={state.type === "domain"}
                                    onChange={() => {
                                        if (state.canPractice && hasNonTrialPremiumSub()) {
                                            settype("domain");
                                            setintQuestion(state.intQuestion+1)
                                        }
                                        else {
                                            if (state.canPractice) {
                                                setshowPaymentModal(true);
                                            }
                                            else {
                                                authModal();
                                            }
                                        }
                                    }}
                                />
                                Company Question
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="jobDescriptionQ"
                                    checked={state.type === "jobDescriptionQ"}
                                    onChange={() => {
                                        if (state.canPractice && hasNonTrialPremiumSub()) {
                                            settype("jobDescriptionQ");
                                            setintQuestion(state.intQuestion+1)
                                        }
                                        else {
                                            if (state.canPractice) {
                                                setshowPaymentModal(true);
                                            }
                                            else {
                                                authModal();
                                            }
                                        }
                                    }}
                                />
                                Custom Job Description
                            </label>
                            {hasOrgQs(state.role) && <label>
                                <input
                                    type="radio"
                                    value="org"
                                    checked={state.type === "org"}
                                    onChange={() => { settype("org"); setspecType("org"); setintQuestion(state.intQuestion+2) }}
                                />
                                {organization} Questions
                            </label>}
                        </div>
                        {state.type !== "" && <div className="next">
                            <i onClick={() => setintQuestion(state.intQuestion+state.type === "custom" || state.type === "domain" || state.type === "jobDescriptionQ" ? 1 : 2)} className="fas fa-circle-arrow-right fa-2x"></i>
                            <p>Next</p>
                        </div>}
                    </div>}

                {state.intQuestion == 3 && state.type === 'domain' &&
                    <div className="question-1-new">
                        <div className="question-title">
                            <div className="question">
                                <h3>Select Company</h3>
                            </div>
                            <div className="question-subtext">
                                <h4>Choose a company from top tech companies. </h4>
                            </div>
                        </div>
                        <div className="input-choices-new">
                            <Select
                                isClearable
                                inputValue={state.domain}
                                onInputChange={(e) => { if (e !== "" || state.domain.length === 1) { setdomain(e) } }} //fix bug on mobile
                                options={companyOptions}
                                onChange={(e) => { setdomain(e !== null ? e.value : ''); if (state.domain !== "" || (e !== null && e.value !== "")) { setintQuestion(state.intQuestion+1); } }}
                            />
                        </div>
                        {state.domain !== "" && <div className="next">
                            <i onClick={() => setintQuestion(state.intQuestion+1)} className="fas fa-circle-arrow-right fa-2x"></i>
                            <p>Next</p>
                        </div>}
                    </div>}
                {state.intQuestion == 3 && state.type === 'custom' &&
                    <div className="question-1-new">
                        <div className="question-title">
                            <div className="question">
                                <h3>Custom Question</h3>
                            </div>
                            <div className="question-subtext">
                                <h4>Specify the custom question you want to practice.</h4>
                            </div>
                        </div>
                        <div className="choices-new">
                            <input type="text" onChange={(e) => setcustomQ(e.target.value)} id="userInput" name="userInput3" placeholder="Explain the product development lifecycle." />
                        </div>
                        {state.customQ !== "" && <div className="next">
                            <i onClick={() => { setrunSetQs(true); setcurrentQuestion(0); setcurrScreen(2); setqType("choose question"); }} className="fas fa-circle-arrow-right fa-2x"></i>
                            <p>Next</p>
                        </div>}
                    </div>}
                {state.intQuestion == 3 && state.type === 'jobDescriptionQ' &&
                    <div className="question-1-new">
                        <div className="question-title">
                            <div className="question">
                                <h3>Custom Job Description</h3>
                            </div>
                        </div>
                        <div className="question-subtext">
                            <h4>Enter friendly name for a new job description or choose from your existing ones</h4>
                        </div>
                        <div className="input-choices-new">
                            <CreatableSelect
                                placeholder="select or type in custom title"
                                isClearable
                                defaultInputValue={state.jobDescriptionQ.title}
                                options={JDOptions}
                                onChange={(e) => { handleJDTitle(e) }} />
                        </div>
                        <br />
                        <br />
                        <div className="question-subtext">
                            <h4>Job description</h4>
                        </div>
                        <div className="choices-new">
                            <textarea
                                className="adminQs largerTextArea"
                                value={state.jobDescriptionQ.description}
                                id="myFeedbackAreaJD"
                                placeholder={sampleJD}
                                onChange={(e) => setjobDescriptionQ({ ...state.jobDescriptionQ, description: e.target.value })}
                                name="name"
                                readOnly={readOnly}
                            ></textarea>
                        </div>
                        {state.jobDescriptionQ.title !== "" && state.jobDescriptionQ.description !== "" && <div className="next">
                            <i onClick={() => {
                                setintQuestion(state.intQuestion+1); if (!jobDescriptions?.hasOwnProperty(state.jobDescriptionQ.title)) {
                                    submitJD(state.jobDescriptionQ);
                                }
                            }} className="fas fa-circle-arrow-right fa-2x"></i>
                            <p>Next</p>
                        </div>}
                    </div>}
                {state.intQuestion == 4 &&
                    <div className="question-1-new">
                        <div className="question-title">
                            <div className="question center">
                                <h3>Select Interview Pack</h3>
                            </div>

                        </div>
                        {getQuestionPacks()}
                    </div>
                }
                {state.intQuestion == 5 &&
                    <div className="question-1-new">
                        <div className="question-title">
                            <div className="question">
                                <h3>Select from our Question Bank</h3>
                            </div>
                        </div>
                        <div className="choices-new">
                            <div className="choices-new-questionbank2">
                                <div className="questionbank-header"><b>Status</b> <b>Question</b><b className={state.questionLevelMap === undefined || Object.keys(state.questionLevelMap).length === 0 ? "hide" : ""}>Difficulty</b></div>
                                {getQuestionBank()}
                            </div>
                        </div>
                        {state.currentQuestion !== -1 && <div className="next2">
                            <i onClick={() => setcurrScreen(2)} className="fas fa-circle-arrow-right fa-2x"></i>
                            <p>Next</p>
                        </div>}
                    </div>}

            </div>
            </>
            }
             {state.currScreen !== 1 &&
             <PrepScreen buttonClick={()=>{
                if(state.profileUser === null || hasPremiumSub())
                {
                    startFunc(true, false);
                }
                else{
                    setshowPaymentModal(true);
                }
            }}
           />
             }
        </>
    );
}

export default Track;