import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { AppProvider } from './appContext';
import HistoryScreen from './components/HistoryScreen';
import Admin from './components/admin';
import InterviewScreen from './components/InterviewScreen';
import { Signup } from './components/signup';
import Track from './components/track';
import Feedback from "./components/Feedback";
import { Pay } from "./components/Pay";

const AppRouting = () => {
  return (
    <Router>
      <AppProvider>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/pay" element={<Pay />} />
          <Route path="/interview" element={<InterviewScreen />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/pastfeedback" element={<Feedback />} />
          <Route path="/history" element={<HistoryScreen />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/track" element={<Track />} />
        </Routes>
      </AppProvider>
    </Router>
  );
};

const root = createRoot(document.getElementById("root")!);
root.render(
  <Auth0Provider
    domain="signup.thedevdifference.com" //put keys in  an env file.
    clientId="ddCniLZzWqP9MLdYoPe7ec2vWcAIVPer"
    authorizationParams={{
      redirect_uri: `${window.location.origin}`,
      audience: "https://dev-34csn6n8frodqjoq.us.auth0.com/api/v2/",
    }}
  >
    <AppRouting />
  </Auth0Provider>
);
