import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

export function AppInsightsProvider(name: string, uri: string): ApplicationInsights {
  const clickPluginInstance = new ClickAnalyticsPlugin();
  const clickPluginConfig = {
    autoCapture: true,
    dataTags: { useDefaultContentNameOrId: true }
  };

  const configObj = {
    connectionString: "InstrumentationKey=05d614be-58a7-42af-8daf-e54c618a1937;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/",
    extensions: [clickPluginInstance],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig
    },
  };

  const appInsights = new ApplicationInsights({ config: configObj });
  appInsights.loadAppInsights();
  appInsights.trackPageView({ name: name, uri: uri });

  return appInsights;
}
