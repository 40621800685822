import React, { useState, useRef, useEffect } from 'react';
import { feedbackMap, generateGUID } from '../util';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange, RangeKeyDict } from 'react-date-range';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { InterviewObject } from './types';

interface HistoryTrendProps {
  questions: { [key: string]: InterviewObject[] };
}

interface FeedbackMapping {
  [key: string]: [string[], string[], string[]] | null;
}

interface ChartDataPoint {
  x: Date;
  y: number;
}

function HistoryTrend(props: HistoryTrendProps) {
  const [typeFilter, setTypeFilter] = useState<string>('all');
  const [dateRange, setDateRange] = useState<RangeKeyDict>({
    selection: {
      startDate: undefined,
      endDate: new Date(),
      key: 'selection',
    }
  });
  const [criteriaFilter, setCriteriaFilter] = useState<string>('all');
  const [feedbackMapping, setFeedbackMapping] = useState<[FeedbackMapping, string[]]>([{}, []]);
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [dateAdjust, setDateAdjust] = useState<boolean>(false);

  const interviewCriteriaDictionary: { [key: string]: string } = {
    "problem-solving skills": "analytical reasoning",
    "data-driven decision making and proactive analysis": "analytical reasoning",
    "data structure usage and analytical skills": "analytical reasoning",
    "efficiency and runtime complexity considerations": "analytical reasoning",
    "testing approach": "analytical reasoning",
    
    "communication, explanation, and clarity": "communication skills",
    "active listening and stakeholder communication": "communication skills",
    "articulating problem statement": "communication skills",
    "outcome highlighting with specific examples": "communication skills",
    "articulation of goals and aspirations": "communication skills",
    
    "technical expertise": "technical proficiency",
    "understanding of important concepts": "technical proficiency",
    "clear and understandable code/pseudocode": "technical proficiency",
    "effective handling of errors, exceptions, and edge cases": "technical proficiency",
    "scalability considerations": "technical proficiency",
    
    "alignment with strategic goals": "strategic thinking",
    "actions, strategies, and risk assessment": "strategic thinking",
    "opportunities for optimization and innovation": "strategic thinking",
    "success measurements": "strategic thinking",
    "knowledge of the position and company research": "strategic thinking",
    
    "growth mindset and learnability": "personal development",
    "adaptability and flexibility": "personal development",
    "work ethic": "personal development",
    "enthusiasm, passion, and motivation": "personal development",
    "financial considerations and commitment to learning": "personal development",
    
    "leadership skills": "team collaboration",
    "collaboration, teamwork, and contribution to team success": "team collaboration",
    "user-centric product development and inclusivity": "team collaboration",
    "organization, planning, and time management": "team collaboration",
    "assumptions and clarifying questions": "team collaboration",
    
    "creativity and innovation": "creative thinking",
    "creative thinking and effective prioritization": "creative thinking",
    "abilities and aptitude demonstration": "creative thinking",
    "white board usage": "creative thinking",
    
    "overall assessment and measurements of success": "evaluation and improvement",
    "modularity and complexity management": "evaluation and improvement",
    "articulation of coding approach and thought process": "evaluation and improvement",
    "trade-offs, risks, and rationale": "evaluation and improvement",
    "seeking clarification and scoping down the problem": "evaluation and improvement",
  };

  const scores: { [key: string]: number } = {
    "Poor": 10,
    "Satisfactory": 50,
    "Good": 85,
    "Exceptional": 100
  };
  
  const typeMap: { [key: string]: string } = {
    'resume based': 'behavioral',
    'behavioral': 'behavioral',
    'general': 'behavioral',
    'estimation': 'technical',
    'strategy': 'technical',
    'system design': 'technical',
    'software concepts': 'technical',
    'coding': 'technical',
    'product design': 'technical',
  };

  useEffect(() => {
    CreateInitMap();
  }, [props.questions]);

  const overlayRef = useRef<HTMLDivElement>(null);
  const data = getChartData();

  function getScore(timestamp: string): number {
    //console.log(feedbackMapping);
    const feed = feedbackMapping[0][timestamp];
    if (feed === null || feed === undefined) {
      return 0;
    }
    let total = 0;
    let copy = criteriaFilter !== 'all' ? feed[1].filter((x, i) => feed[0][i].toLowerCase() === criteriaFilter.toLowerCase() || interviewCriteriaDictionary[feed[0][i].toLowerCase()] === criteriaFilter.toLowerCase()) : feed[1];
    for (let i = 0; i < copy.length; i++) {
      total += scores[copy[i]];
    }
    return total / copy.length;
  }

  async function CreateInitMap() {
    let dict: FeedbackMapping = {};
    let allCriteria: string[] = [];
    const all: InterviewObject[] = [];
    for (let key in props.questions) {
      all.push(...props.questions[key]);
    }
    for (let i = 0; i < all.length; i++) {
      let x = all[i];
      if ((x["timestamp"] !== undefined)) {
        let f = await feedbackMap(x["feedback"]);
        dict[x["timestamp"]] = f;
        if (f !== null) {
          f[0].forEach(c => {
            if (interviewCriteriaDictionary.hasOwnProperty(c.toLowerCase())) {
              const c2 = interviewCriteriaDictionary[c.toLowerCase()];
              if (!allCriteria.includes(c2)) {
                allCriteria.push(c2);
              }
            }
            else if (!allCriteria.includes(c.toLowerCase())) {
              allCriteria.push(c.toLowerCase());
            }
          });
        }
      }
    }
    allCriteria.sort();
    setFeedbackMapping([dict, allCriteria]);
  }

  function getDate(date: string): Date | null {
    if (date !== undefined && date !== "" && date !== null) {
      const dateTimeArr = date.split(' ');
      const dateArr = dateTimeArr[0].split('-');
      const timeArr = dateTimeArr[1].split(':');
      return new Date(parseInt(dateArr[0]), parseInt(dateArr[1]) - 1, parseInt(dateArr[2]), parseInt(timeArr[0]), parseInt(timeArr[1]), parseInt(timeArr[2]));
    }
    return null;
  }

  function inDateRange(ans: InterviewObject): boolean {
    if (dateAdjust) {
      if (ans["timestamp"] === undefined) {
        return false;
      }
      const d = getDate(ans["timestamp"]);
      return d !== null && dateRange.selection.endDate !== undefined && dateRange.selection.startDate !== undefined && d <= dateRange.selection.endDate && d >= dateRange.selection.startDate;
    }
    return true;
  }

  function hasCriteria(ans: InterviewObject): boolean {
    if (criteriaFilter !== 'all') {
      const t = ans["timestamp"];
      if (t !== undefined) {
        let f = feedbackMapping[0][t];
        if (f !== null) {
          let list = f[0].filter(x => (x.toLowerCase() === criteriaFilter.toLowerCase() || interviewCriteriaDictionary[x.toLowerCase()] === criteriaFilter.toLowerCase()));
          return list.length > 0;
        }
      }
      return false;
    }
    return true;
  }

  function inTypeFilter(ans: InterviewObject): boolean {
    if (typeFilter !== 'all') {
      let val = typeMap[ans["specType"]];
      val = val === undefined ? 'other' : val;
      return val === typeFilter;
    }
    return true;
  }

  function applyFilters(all: InterviewObject[]): InterviewObject[] {
    return all.filter(ans => inDateRange(ans) && inTypeFilter(ans) && hasCriteria(ans));
  }

  function getChartData(): ChartDataPoint[] {
    const all: InterviewObject[] = [];
    for (let key in props.questions) {
      all.push(...props.questions[key]);
    }

    const allFiltered = applyFilters(all);
    const data = allFiltered.map(ans => {
      const date = getDate(ans["timestamp"]);
      return date ? { x: date, y: getScore(ans["timestamp"]) } : null;
    }).filter((x): x is ChartDataPoint => x !== null && x.y !== 0);
    data.sort((a, b) => a.x.getTime() - b.x.getTime());
    return data;
  }
  
  function getDateRangeDisplay(): string {
    const start = dateRange.selection.startDate === undefined ? 'Beginning' : formatDate(dateRange.selection.startDate);
    const end = dateRange.selection.endDate ? formatDate(dateRange.selection.endDate) : '';
    return `${start} - ${end}`;
  }

  function formatDate(currentDate: Date): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return currentDate.toLocaleDateString('en-US', options);
  }

  function formatTime(currentDate: Date): string {
    return `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
  }

  function isOverlayClicked(e: React.MouseEvent): void {
    if (overlayRef.current && !overlayRef.current.contains(e.target as Node)) {
      setPickerOpen(false);
    }
  }

  return (
    <div className="chartWrapper">
      <div className='filters'>
        <div className="dropdown2 topalign">
          <select id="dropdown" className="p-1" value={typeFilter} onChange={(e) => setTypeFilter(e.target.value)}>  
            <option value="all">All Interview Categories</option>
            <option value="behavioral">Behavioral</option>
            <option value="technical">Technical</option>
            <option value="other">Other</option>
          </select>
        </div> 
        <div>
          <div className="dateDisp p-1" onClick={() => setPickerOpen(true)}>
            {getDateRangeDisplay()}
          </div>
          {pickerOpen &&
            <div className="datePick" onClick={(e) => isOverlayClicked(e)}>
              <div ref={overlayRef}>
                <DateRange
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={[dateRange.selection]}
                  onChange={(item) => {
                    setDateRange({ selection: item.selection });
                    if (!dateAdjust) {
                      setDateAdjust(true);
                    }
                  }}
                />
              </div>
            </div>}
        </div>
        <div className="dropdown2 topalign">
          <select id="dropdown2" className="p-1" value={criteriaFilter} onChange={(e) => setCriteriaFilter(e.target.value)}>  
            <option value="all">All Criteria</option>
            {feedbackMapping[1].map(x => <option key={generateGUID()} value={x}>{x}</option>)}
          </select>
        </div> 
      </div>
      <div className='chartDiv'>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data.map(d => ({ x: `${formatDate(d.x)} (${formatTime(d.x)})`, y: d.y }))}
            margin={{
              top: 15,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="x" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top"/>
            <Line type="monotone" name="Average Interview Score Percentage over time" dataKey="y" strokeWidth={3} stroke="#ff914d" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default HistoryTrend;
