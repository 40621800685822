import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from '../appContext';

export const Signup: React.FC = () => {

  const { auth0,  } = useAppContext();

  useEffect(() => {
    auth0.loginWithRedirect();
  }, []);

  return <></>;
};

