import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  User,
  GPTResponse,
  JobDescription,
  FeedbackUpdate,
  OrgQuestion,
} from "./components/types";
import { AppInsightsProvider } from "./components/AppInsightsProvider";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Cookie from "universal-cookie";
import { useAuth0 } from "@auth0/auth0-react";
import { AudioConfig } from "microsoft-cognitiveservices-speech-sdk";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";
import {
  getTokenOrRefresh,
  getGPTResponseServer,
  getQuestionsDatabase,
  updateUserResumeQuestions,
  updateFeedbackAccessibility,
  updateUserAnsweredQuestions,
  getOrgQuestions,
  getUser,
  updateUserVerification,
} from "./api";
import { GetFriendlyOrgNames, hasPremSub, hasNonTrialPremSub } from "./util";
import { createSilentAudio } from "create-silent-audio";
import { useLocation, useNavigate } from "react-router-dom";
import { getPaymentInfo } from "./components/Pay";

interface AppState {
  getq: boolean[];
  orgQuestions: { [key: string]: OrgQuestion[] } | null;
  continueFunc: boolean;
  runSetQs: boolean;
  initialLoad: boolean;
  start: boolean;
  qPrompt: GPTResponse[];
  loading: boolean;
  recognizedsp: string;
  interviewer: string;
  micOn: boolean;
  recognizedspTemp: string;
  prompt: GPTResponse[];
  fullprompt: GPTResponse[];
  questions: string[];
  questionLevelMap: { [key: string]: string };
  currentQuestion: number;
  currScreen: number;
  feedback: string;
  feedbackDuration: number;
  role: string;
  level: string;
  domain: string;
  customQ: string;
  jobDescriptionQ: JobDescription;
  type: string;
  specType: string;
  processing: boolean;
  code: string;
  qA: string;
  action: string;
  showAns: boolean;
  showFeedback: boolean;
  showAction: boolean;
  canStop: boolean;
  codingLang: string;
  next: boolean;
  previous: boolean;
  login: boolean;
  privateSession: boolean;
  showNextConfirmation: boolean;
  showHistoryModal: boolean;
  showsigninModal: boolean;
  showPaymentModal: boolean;
  showintroFormModal: boolean;
  showTranscript: boolean;
  showApiError: boolean;
  errorText: string;
  showSettings: boolean;
  showFeedbackBox: boolean;
  sessionStopped: boolean;
  resume: { resumeText: string; resumeName: string };
  profileUser: User | null;
  userAudio: boolean;
  isHelpful: boolean | null;
  isHelpfulMessage: string | null;
  intQuestion: number;
  qType: string | null;
  questionQuery: string;
  speechLen: number;
  hitMaxLen: boolean;
  displayPastFeedback: string;
  pastInfo: any;
  retryPast: boolean;
  canPractice: boolean;
  beginningLoad: boolean;
  nextFunc: (() => void) | null;
  feedbackNextFunc: (() => void) | null;
  abortController: AbortController;
  memberFeedbackModal: ReactNode | null;
  questionModal: ReactNode | null;
  loadingOverlayComp: ReactNode | null;
  GPTCallCount: number;
  contextLength: number;
  promptArray: GPTResponse[];
  transcript: string;
  actionSteps: string;
  sampleSolution: string;
  attempted: { [key: string]: boolean };
  difficulty: { [key: string]: string };
  showMic: boolean;
  audioEnabled: boolean;
  useWhiteboard: boolean;
  jobDescriptions: { [key: string]: string };
}

type Action =
  | { type: "UPDATE_getq"; payload: boolean[] }
  | {
      type: "UPDATE_orgQuestions";
      payload: { [key: string]: OrgQuestion[] } | null;
    }
  | { type: "UPDATE_useWhiteboard"; payload: boolean }
  | { type: "UPDATE_continueFunc"; payload: boolean }
  | { type: "UPDATE_runSetQs"; payload: boolean }
  | { type: "RESET_STATE" }
  | { type: "SET_STATE"; payload: AppState }
  | { type: "UPDATE_initialLoad"; payload: boolean }
  | { type: "UPDATE_start"; payload: boolean }
  | { type: "UPDATE_qPrompt"; payload: GPTResponse[] }
  | { type: "UPDATE_loading"; payload: boolean }
  | { type: "UPDATE_recognizedsp"; payload: string }
  | { type: "UPDATE_interviewer"; payload: string }
  | { type: "UPDATE_micOn"; payload: boolean }
  | { type: "UPDATE_recognizedspTemp"; payload: string }
  | { type: "UPDATE_prompt"; payload: GPTResponse[] }
  | { type: "UPDATE_fullprompt"; payload: GPTResponse[] }
  | { type: "UPDATE_questions"; payload: string[] }
  | { type: "UPDATE_memberFeedbackModal"; payload: ReactNode | null }
  | { type: "UPDATE_questionModal"; payload: ReactNode | null }
  | { type: "UPDATE_loadingOverlayComp"; payload: ReactNode | null }
  | { type: "UPDATE_questionLevelMap"; payload: { [key: string]: string } }
  | { type: "UPDATE_currentQuestion"; payload: number }
  | { type: "UPDATE_currScreen"; payload: number }
  | { type: "UPDATE_feedback"; payload: string }
  | { type: "UPDATE_feedbackDuration"; payload: number }
  | { type: "UPDATE_role"; payload: string }
  | { type: "UPDATE_level"; payload: string }
  | { type: "UPDATE_domain"; payload: string }
  | { type: "UPDATE_customQ"; payload: string }
  | { type: "UPDATE_jobDescriptionQ"; payload: JobDescription }
  | { type: "UPDATE_type"; payload: string }
  | { type: "UPDATE_specType"; payload: string }
  | { type: "UPDATE_processing"; payload: boolean }
  | { type: "UPDATE_code"; payload: string }
  | { type: "UPDATE_qA"; payload: string }
  | { type: "UPDATE_action"; payload: string }
  | { type: "UPDATE_showAns"; payload: boolean }
  | { type: "UPDATE_showFeedback"; payload: boolean }
  | { type: "UPDATE_showAction"; payload: boolean }
  | { type: "UPDATE_canStop"; payload: boolean }
  | { type: "UPDATE_codingLang"; payload: string }
  | { type: "UPDATE_next"; payload: boolean }
  | { type: "UPDATE_previous"; payload: boolean }
  | { type: "UPDATE_login"; payload: boolean }
  | { type: "UPDATE_privateSession"; payload: boolean }
  | { type: "UPDATE_showNextConfirmation"; payload: boolean }
  | { type: "UPDATE_showHistoryModal"; payload: boolean }
  | { type: "UPDATE_showsigninModal"; payload: boolean }
  | { type: "UPDATE_showPaymentModal"; payload: boolean }
  | { type: "UPDATE_showintroFormModal"; payload: boolean }
  | { type: "UPDATE_showTranscript"; payload: boolean }
  | { type: "UPDATE_showApiError"; payload: boolean }
  | { type: "UPDATE_errorText"; payload: string }
  | { type: "UPDATE_showSettings"; payload: boolean }
  | { type: "UPDATE_showFeedbackBox"; payload: boolean }
  | { type: "UPDATE_sessionStopped"; payload: boolean }
  | {
      type: "UPDATE_resume";
      payload: { resumeText: string; resumeName: string };
    }
  | { type: "UPDATE_profileUser"; payload: User | null }
  | { type: "UPDATE_userAudio"; payload: boolean }
  | { type: "UPDATE_isHelpful"; payload: boolean | null }
  | { type: "UPDATE_isHelpfulMessage"; payload: string | null }
  | { type: "UPDATE_intQuestion"; payload: number }
  | { type: "UPDATE_qType"; payload: string | null }
  | { type: "UPDATE_questionQuery"; payload: string }
  | { type: "UPDATE_speechLen"; payload: number }
  | { type: "UPDATE_hitMaxLen"; payload: boolean }
  | { type: "UPDATE_displayPastFeedback"; payload: string }
  | { type: "UPDATE_pastInfo"; payload: any }
  | { type: "UPDATE_retryPast"; payload: boolean }
  | { type: "UPDATE_canPractice"; payload: boolean }
  | { type: "UPDATE_beginningLoad"; payload: boolean }
  | { type: "UPDATE_nextFunc"; payload: (() => void) | null }
  | { type: "UPDATE_feedbackNextFunc"; payload: (() => void) | null }
  | { type: "UPDATE_abortController"; payload: AbortController }
  | { type: "UPDATE_GPTCallCount"; payload: number }
  | { type: "UPDATE_contextLength"; payload: number }
  | { type: "PUSH_prompt"; payload: any }
  | { type: "PUSH_fullprompt"; payload: any }
  | { type: "PUSH_qPrompt"; payload: any }
  | { type: "PUSH_questionLevelMap"; key: string; val: string }
  | { type: "PUSH_questions"; payload: string }
  | { type: "POP_prompt" };

type AppContextType = {
  auth0: any;
  state: AppState;
  dispatch: React.Dispatch<Action>;
  reducers: any;
  navigateThenRun: (func: (() => void) | null, path: string) => void;
  showError: (val: boolean) => void;
  getFeedback: () => void;
  showFeedbackPopUp: () => boolean;
  stopAudio: () => void;
  refreshUser: () => Promise<void>;
  synthesizeSpeech: (text: string) => Promise<void>;
  pickQuestion: (endScreen?: boolean) => void;
  startFunc: (newQ: boolean, prevQ: boolean) => void;
  getAnswer: () => void;
  getAction: () => void;
  isAuth: ()=> boolean;
  setQs: (
    abortControllerP: AbortController | null,
    q: string | null
  ) => Promise<void> ;
};

interface AppProviderProps {
  children: ReactNode;
}

let appInsights: ApplicationInsights | undefined;
const AppContext = createContext<AppContextType | undefined>(undefined);

const initialState: AppState = {
  getq: [],
  orgQuestions: null,
  continueFunc: false,
  runSetQs: false,
  initialLoad: true,
  start: false,
  qPrompt: [],
  loading: true,
  recognizedsp: "",
  interviewer: "",
  micOn: false,
  recognizedspTemp: "",
  prompt: [],
  fullprompt: [],
  questions: [],
  questionLevelMap: {},
  currentQuestion: -1,
  currScreen: 1,
  feedback: "",
  feedbackDuration: -1,
  role: "",
  level: "",
  domain: "",
  customQ: "",
  jobDescriptionQ: { title: "", description: "" },
  type: "",
  specType: "",
  processing: false,
  code: "",
  qA: "",
  action: "",
  showAns: false,
  showFeedback: false,
  showAction: false,
  canStop: false,
  codingLang: "python",
  next: true,
  previous: false,
  login: false,
  privateSession: false,
  showNextConfirmation: false,
  showHistoryModal: false,
  showsigninModal: false,
  showPaymentModal: false,
  showintroFormModal: false,
  showTranscript: false,
  showApiError: false,
  errorText: "Encountered a problem reaching the api, retrying in 3 seconds...",
  showSettings: false,
  showFeedbackBox: false,
  sessionStopped: true,
  resume: { resumeText: "", resumeName: "" },
  profileUser: null,
  userAudio: true,
  isHelpful: null,
  isHelpfulMessage: null,
  intQuestion: 1,
  qType: null,
  questionQuery: "",
  speechLen: 0,
  hitMaxLen: false,
  displayPastFeedback: "",
  pastInfo: {},
  retryPast: false,
  canPractice: false,
  beginningLoad: false,
  nextFunc: null,
  feedbackNextFunc: null,
  abortController: new AbortController(),
  memberFeedbackModal: null,
  questionModal: null,
  loadingOverlayComp: null,
  GPTCallCount: 0,
  contextLength: 500, //length of context to send to gpt
  promptArray: [],
  transcript: "",
  actionSteps: "",
  sampleSolution: "",
  attempted: {},
  difficulty: {},
  showMic: true,
  audioEnabled: false,
  useWhiteboard: false,
  jobDescriptions: {},
};

function appReducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case "UPDATE_getq":
      return { ...state, getq: action.payload };
    case "UPDATE_orgQuestions":
      return { ...state, orgQuestions: action.payload };
    case "UPDATE_useWhiteboard":
      return { ...state, useWhiteboard: action.payload };
    case "UPDATE_continueFunc":
      return { ...state, continueFunc: action.payload };
    case "UPDATE_runSetQs":
      return { ...state, runSetQs: action.payload };
    case "RESET_STATE":
      return initialState;
    case "SET_STATE":
      return { ...action.payload, abortController: new AbortController() };
    case "UPDATE_initialLoad":
      return { ...state, initialLoad: action.payload };
    case "UPDATE_start":
      return { ...state, start: action.payload };
    case "UPDATE_qPrompt":
      return { ...state, qPrompt: action.payload };
    case "UPDATE_loading":
      return { ...state, loading: action.payload };
    case "UPDATE_recognizedsp":
      return { ...state, recognizedsp: action.payload };
    case "UPDATE_interviewer":
      return { ...state, interviewer: action.payload };
    case "UPDATE_micOn":
      return { ...state, micOn: action.payload };
    case "UPDATE_recognizedspTemp":
      return { ...state, recognizedspTemp: action.payload };
    case "UPDATE_prompt":
      return { ...state, prompt: action.payload };
    case "UPDATE_fullprompt":
      return { ...state, fullprompt: action.payload };
    case "UPDATE_questions":
      return { ...state, questions: action.payload };
    case "UPDATE_memberFeedbackModal":
      return { ...state, memberFeedbackModal: action.payload };
    case "UPDATE_questionModal":
      return { ...state, questionModal: action.payload };
    case "UPDATE_loadingOverlayComp":
      return { ...state, loadingOverlayComp: action.payload };
    case "UPDATE_questionLevelMap":
      return { ...state, questionLevelMap: action.payload };
    case "UPDATE_currentQuestion":
      return { ...state, currentQuestion: action.payload };
    case "UPDATE_currScreen":
      return { ...state, currScreen: action.payload };
    case "UPDATE_feedback":
      return { ...state, feedback: action.payload };
    case "UPDATE_feedbackDuration":
      return { ...state, feedbackDuration: action.payload };
    case "UPDATE_role":
      return { ...state, role: action.payload };
    case "UPDATE_level":
      return { ...state, level: action.payload };
    case "UPDATE_domain":
      return { ...state, domain: action.payload };
    case "UPDATE_customQ":
      return { ...state, customQ: action.payload };
    case "UPDATE_jobDescriptionQ":
      return { ...state, jobDescriptionQ: action.payload };
    case "UPDATE_type":
      return { ...state, type: action.payload };
    case "UPDATE_specType":
      return { ...state, specType: action.payload };
    case "UPDATE_processing":
      return { ...state, processing: action.payload };
    case "UPDATE_code":
      return { ...state, code: action.payload };
    case "UPDATE_qA":
      return { ...state, qA: action.payload };
    case "UPDATE_action":
      return { ...state, action: action.payload };
    case "UPDATE_showAns":
      return { ...state, showAns: action.payload };
    case "UPDATE_showFeedback":
      return { ...state, showFeedback: action.payload };
    case "UPDATE_showAction":
      return { ...state, showAction: action.payload };
    case "UPDATE_canStop":
      return { ...state, canStop: action.payload };
    case "UPDATE_codingLang":
      return { ...state, codingLang: action.payload };
    case "UPDATE_next":
      return { ...state, next: action.payload };
    case "UPDATE_previous":
      return { ...state, previous: action.payload };
    case "UPDATE_login":
      return { ...state, login: action.payload };
    case "UPDATE_privateSession":
      return { ...state, privateSession: action.payload };
    case "UPDATE_showNextConfirmation":
      return { ...state, showNextConfirmation: action.payload };
    case "UPDATE_showHistoryModal":
      return { ...state, showHistoryModal: action.payload };
    case "UPDATE_showsigninModal":
      return { ...state, showsigninModal: action.payload };
    case "UPDATE_showPaymentModal":
      return { ...state, showPaymentModal: action.payload };
    case "UPDATE_showintroFormModal":
      return { ...state, showintroFormModal: action.payload };
    case "UPDATE_showTranscript":
      return { ...state, showTranscript: action.payload };
    case "UPDATE_showApiError":
      return { ...state, showApiError: action.payload };
    case "UPDATE_errorText":
      return { ...state, errorText: action.payload };
    case "UPDATE_showSettings":
      return { ...state, showSettings: action.payload };
    case "UPDATE_showFeedbackBox":
      return { ...state, showFeedbackBox: action.payload };
    case "UPDATE_sessionStopped":
      return { ...state, sessionStopped: action.payload };
    case "UPDATE_resume":
      return { ...state, resume: action.payload };
    case "UPDATE_profileUser":
      return { ...state, profileUser: action.payload };
    case "UPDATE_userAudio":
      return { ...state, userAudio: action.payload };
    case "UPDATE_isHelpful":
      return { ...state, isHelpful: action.payload };
    case "UPDATE_isHelpfulMessage":
      return { ...state, isHelpfulMessage: action.payload };
    case "UPDATE_intQuestion":
      return { ...state, intQuestion: action.payload };
    case "UPDATE_qType":
      return { ...state, qType: action.payload };
    case "UPDATE_questionQuery":
      return { ...state, questionQuery: action.payload };
    case "UPDATE_speechLen":
      return { ...state, speechLen: action.payload };
    case "UPDATE_hitMaxLen":
      return { ...state, hitMaxLen: action.payload };
    case "UPDATE_displayPastFeedback":
      return { ...state, displayPastFeedback: action.payload };
    case "UPDATE_pastInfo":
      return { ...state, pastInfo: action.payload };
    case "UPDATE_retryPast":
      return { ...state, retryPast: action.payload };
    case "UPDATE_canPractice":
      return { ...state, canPractice: action.payload };
    case "UPDATE_beginningLoad":
      return { ...state, beginningLoad: action.payload };
    case "UPDATE_nextFunc":
      return { ...state, nextFunc: action.payload };
    case "UPDATE_feedbackNextFunc":
      return { ...state, feedbackNextFunc: action.payload };
    case "UPDATE_abortController":
      return { ...state, abortController: action.payload };
    case "UPDATE_GPTCallCount":
      return { ...state, GPTCallCount: action.payload };
    case "UPDATE_contextLength":
      return { ...state, contextLength: action.payload };
    case "PUSH_prompt":
      return { ...state, prompt: [...state.prompt, action.payload] };
    case "PUSH_fullprompt":
      return { ...state, fullprompt: [...state.fullprompt, action.payload] };
    case "PUSH_qPrompt":
      return { ...state, qPrompt: [...state.qPrompt, action.payload] };
    case "PUSH_questionLevelMap":
      return {
        ...state,
        questionLevelMap: {
          ...state.questionLevelMap,
          [action.key]: action.val,
        },
      };
    case "PUSH_questions":
      return { ...state, questions: [...state.questions, action.payload] };
    case "POP_prompt":
      return { ...state, prompt: state.prompt.slice(0, -1) };
    default:
      return state;
  }
}

export function AppProvider({ children }: AppProviderProps) {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const location = useLocation();
  const navigate = useNavigate();

  const reducers = {
    setgetq: (getq: boolean[]) =>
      dispatch({ type: "UPDATE_getq", payload: getq }),
    setorgQuestions: (orgQuestions: { [key: string]: OrgQuestion[] } | null) =>
      dispatch({ type: "UPDATE_orgQuestions", payload: orgQuestions }),
    setuseWhiteboard: (useWhiteboard: boolean) =>
      dispatch({ type: "UPDATE_useWhiteboard", payload: useWhiteboard }),
    setcontinueFunc: (continueFunc: boolean) =>
      dispatch({ type: "UPDATE_continueFunc", payload: continueFunc }),
    setrunSetQs: (runSetQs: boolean) =>
      dispatch({ type: "UPDATE_runSetQs", payload: runSetQs }),
    setWholeState: (newState: AppState) =>
      dispatch({ type: "SET_STATE", payload: newState }),
    setbeginningLoad: (beginningLoad: boolean) =>
      dispatch({ type: "UPDATE_beginningLoad", payload: beginningLoad }),
    setcanPractice: (canPractice: boolean) =>
      dispatch({ type: "UPDATE_canPractice", payload: canPractice }),
    setcanStop: (canStop: boolean) =>
      dispatch({ type: "UPDATE_canStop", payload: canStop }),
    setcode: (code: string) => dispatch({ type: "UPDATE_code", payload: code }),
    setcodingLang: (codingLang: string) =>
      dispatch({ type: "UPDATE_codingLang", payload: codingLang }),
    setcontextLength: (contextLength: number) =>
      dispatch({ type: "UPDATE_contextLength", payload: contextLength }),
    setcurrScreen: (currScreen: number) =>
      dispatch({ type: "UPDATE_currScreen", payload: currScreen }),
    setcurrentQuestion: (currentQuestion: number) =>
      dispatch({ type: "UPDATE_currentQuestion", payload: currentQuestion }),
    setcustomQ: (customQ: string) =>
      dispatch({ type: "UPDATE_customQ", payload: customQ }),
    setdisplayPastFeedback: (displayPastFeedback: string) =>
      dispatch({
        type: "UPDATE_displayPastFeedback",
        payload: displayPastFeedback,
      }),
    setdomain: (domain: string) =>
      dispatch({ type: "UPDATE_domain", payload: domain }),
    setErrorText: (errorText: string) =>
      dispatch({ type: "UPDATE_errorText", payload: errorText }),
    setfeedback: (feedback: string) =>
      dispatch({ type: "UPDATE_feedback", payload: feedback }),
    setfeedbackDuration: (feedbackDuration: number) =>
      dispatch({ type: "UPDATE_feedbackDuration", payload: feedbackDuration }),
    setfeedbackNextFunc: (feedbackNextFunc: (() => void) | null) =>
      dispatch({ type: "UPDATE_feedbackNextFunc", payload: feedbackNextFunc }),
    setfullprompt: (fullprompt: GPTResponse[]) =>
      dispatch({ type: "UPDATE_fullprompt", payload: fullprompt }),
    setGPTCallCount: (GPTCallCount: number) =>
      dispatch({ type: "UPDATE_GPTCallCount", payload: GPTCallCount }),
    sethitMaxLen: (hitMaxLen: boolean) =>
      dispatch({ type: "UPDATE_hitMaxLen", payload: hitMaxLen }),
    setinitialLoad: (initialLoad: boolean) =>
      dispatch({ type: "UPDATE_initialLoad", payload: initialLoad }),
    setintQuestion: (intQuestion: number) =>
      dispatch({ type: "UPDATE_intQuestion", payload: intQuestion }),
    setinterviewer: (interviewer: string) =>
      dispatch({ type: "UPDATE_interviewer", payload: interviewer }),
    setisHelpful: (isHelpful: boolean | null) =>
      dispatch({ type: "UPDATE_isHelpful", payload: isHelpful }),
    setisHelpfulMessage: (isHelpfulMessage: string | null) =>
      dispatch({ type: "UPDATE_isHelpfulMessage", payload: isHelpfulMessage }),
    setjobDescriptionQ: (jobDescriptionQ: JobDescription) =>
      dispatch({ type: "UPDATE_jobDescriptionQ", payload: jobDescriptionQ }),
    setlevel: (level: string) =>
      dispatch({ type: "UPDATE_level", payload: level }),
    setloading: (loading: boolean) =>
      dispatch({ type: "UPDATE_loading", payload: loading }),
    setloadingOverlayComp: (loadingOverlayComp: ReactNode | null) =>
      dispatch({
        type: "UPDATE_loadingOverlayComp",
        payload: loadingOverlayComp,
      }),
    setlogin: (login: boolean) =>
      dispatch({ type: "UPDATE_login", payload: login }),
    setmemberFeedbackModal: (memberFeedbackModal: ReactNode | null) =>
      dispatch({
        type: "UPDATE_memberFeedbackModal",
        payload: memberFeedbackModal,
      }),
    setmicOn: (micOn: boolean) =>
      dispatch({ type: "UPDATE_micOn", payload: micOn }),
    setnext: (next: boolean) =>
      dispatch({ type: "UPDATE_next", payload: next }),
    setNextFunc: (nextFunc: (() => void) | null) =>
      dispatch({ type: "UPDATE_nextFunc", payload: nextFunc }),
    setpastInfo: (pastInfo: any) =>
      dispatch({ type: "UPDATE_pastInfo", payload: pastInfo }),
    setprevious: (previous: boolean) =>
      dispatch({ type: "UPDATE_previous", payload: previous }),
    setprivateSession: (privateSession: boolean) =>
      dispatch({ type: "UPDATE_privateSession", payload: privateSession }),
    setprocessing: (processing: boolean) =>
      dispatch({ type: "UPDATE_processing", payload: processing }),
    setprofileUser: (profileUser: User | null) =>
      dispatch({ type: "UPDATE_profileUser", payload: profileUser }),
    setprompt: (prompt: GPTResponse[]) =>
      dispatch({ type: "UPDATE_prompt", payload: prompt }),
    setqA: (qA: string) => dispatch({ type: "UPDATE_qA", payload: qA }),
    setqPrompt: (qPrompt: GPTResponse[]) =>
      dispatch({ type: "UPDATE_qPrompt", payload: qPrompt }),
    setqType: (qType: string | null) =>
      dispatch({ type: "UPDATE_qType", payload: qType }),
    setquestionLevelMap: (questionLevelMap: { [key: string]: string }) =>
      dispatch({ type: "UPDATE_questionLevelMap", payload: questionLevelMap }),
    setquestionModal: (questionModal: ReactNode | null) =>
      dispatch({ type: "UPDATE_questionModal", payload: questionModal }),
    setquestionQuery: (questionQuery: string) =>
      dispatch({ type: "UPDATE_questionQuery", payload: questionQuery }),
    setquestions: (questions: string[]) =>
      dispatch({ type: "UPDATE_questions", payload: questions }),
    setrecognizedsp: (recognizedsp: string) =>
      dispatch({ type: "UPDATE_recognizedsp", payload: recognizedsp }),
    setrecognizedspTemp: (recognizedspTemp: string) =>
      dispatch({ type: "UPDATE_recognizedspTemp", payload: recognizedspTemp }),
    setresume: (resume: { resumeText: string; resumeName: string }) =>
      dispatch({ type: "UPDATE_resume", payload: resume }),
    setretryPast: (retryPast: boolean) =>
      dispatch({ type: "UPDATE_retryPast", payload: retryPast }),
    setrole: (role: string) => dispatch({ type: "UPDATE_role", payload: role }),
    setsessionStopped: (sessionStopped: boolean) =>
      dispatch({ type: "UPDATE_sessionStopped", payload: sessionStopped }),
    setshowAction: (showAction: boolean) =>
      dispatch({ type: "UPDATE_showAction", payload: showAction }),
    setAction: (action: string) =>
      dispatch({ type: "UPDATE_action", payload: action }),
    setshowAns: (showAns: boolean) =>
      dispatch({ type: "UPDATE_showAns", payload: showAns }),
    setshowApiError: (showApiError: boolean) =>
      dispatch({ type: "UPDATE_showApiError", payload: showApiError }),
    setshowFeedback: (showFeedback: boolean) =>
      dispatch({ type: "UPDATE_showFeedback", payload: showFeedback }),
    setshowFeedbackBox: (showFeedbackBox: boolean) =>
      dispatch({ type: "UPDATE_showFeedbackBox", payload: showFeedbackBox }),
    setshowHistoryModal: (showHistoryModal: boolean) =>
      dispatch({ type: "UPDATE_showHistoryModal", payload: showHistoryModal }),
    setshowintroFormModal: (showintroFormModal: boolean) =>
      dispatch({
        type: "UPDATE_showintroFormModal",
        payload: showintroFormModal,
      }),
    setshowNextConfirmation: (showNextConfirmation: boolean) =>
      dispatch({
        type: "UPDATE_showNextConfirmation",
        payload: showNextConfirmation,
      }),
    setshowPaymentModal: (showPaymentModal: boolean) =>
      dispatch({ type: "UPDATE_showPaymentModal", payload: showPaymentModal }),
    setshowSettings: (showSettings: boolean) =>
      dispatch({ type: "UPDATE_showSettings", payload: showSettings }),
    setshowsigninModal: (showsigninModal: boolean) =>
      dispatch({ type: "UPDATE_showsigninModal", payload: showsigninModal }),
    setshowTranscript: (showTranscript: boolean) =>
      dispatch({ type: "UPDATE_showTranscript", payload: showTranscript }),
    setspecType: (specType: string) =>
      dispatch({ type: "UPDATE_specType", payload: specType }),
    setspeechLen: (speechLen: number) =>
      dispatch({ type: "UPDATE_speechLen", payload: speechLen }),
    setstart: (start: boolean) =>
      dispatch({ type: "UPDATE_start", payload: start }),
    setAbortController: (abortController: AbortController) =>
      dispatch({ type: "UPDATE_abortController", payload: abortController }),
    settype: (type: string) => dispatch({ type: "UPDATE_type", payload: type }),
    setuserAudio: (userAudio: boolean) =>
      dispatch({ type: "UPDATE_userAudio", payload: userAudio }),
    pushqPrompt: (payload: any) => dispatch({ type: "PUSH_qPrompt", payload }),
    pushprompt: (payload: any) => dispatch({ type: "PUSH_prompt", payload }),
    pushfullprompt: (payload: any) =>
      dispatch({ type: "PUSH_fullprompt", payload }),
    pushquestions: (payload: string) =>
      dispatch({ type: "PUSH_questions", payload }),
    pushquestionLevelMap: (key: string, val: string) =>
      dispatch({ type: "PUSH_questionLevelMap", key, val }),
    popprompt: () => dispatch({ type: "POP_prompt" }),
  };

  const setgetq = (getq: boolean[]) =>
    dispatch({ type: "UPDATE_getq", payload: getq });
  const setorgQuestions = (
    orgQuestions: { [key: string]: OrgQuestion[] } | null
  ) => dispatch({ type: "UPDATE_orgQuestions", payload: orgQuestions });
  const setuseWhiteboard = (useWhiteboard: boolean) =>
    dispatch({ type: "UPDATE_useWhiteboard", payload: useWhiteboard });
  const setcontinueFunc = (continueFunc: boolean) =>
    dispatch({ type: "UPDATE_continueFunc", payload: continueFunc });
  const setrunSetQs = (runSetQs: boolean) =>
    dispatch({ type: "UPDATE_runSetQs", payload: runSetQs });
  const setWholeState = (newState: AppState) =>
    dispatch({ type: "SET_STATE", payload: newState });
  const setbeginningLoad = (beginningLoad: boolean) =>
    dispatch({ type: "UPDATE_beginningLoad", payload: beginningLoad });
  const setcanPractice = (canPractice: boolean) =>
    dispatch({ type: "UPDATE_canPractice", payload: canPractice });
  const setcanStop = (canStop: boolean) =>
    dispatch({ type: "UPDATE_canStop", payload: canStop });
  const setcode = (code: string) =>
    dispatch({ type: "UPDATE_code", payload: code });
  const setcodingLang = (codingLang: string) =>
    dispatch({ type: "UPDATE_codingLang", payload: codingLang });
  const setcontextLength = (contextLength: number) =>
    dispatch({ type: "UPDATE_contextLength", payload: contextLength });
  const setcurrScreen = (currScreen: number) =>
    dispatch({ type: "UPDATE_currScreen", payload: currScreen });
  const setcurrentQuestion = (currentQuestion: number) =>
    dispatch({ type: "UPDATE_currentQuestion", payload: currentQuestion });
  const setcustomQ = (customQ: string) =>
    dispatch({ type: "UPDATE_customQ", payload: customQ });
  const setdisplayPastFeedback = (displayPastFeedback: string) =>
    dispatch({
      type: "UPDATE_displayPastFeedback",
      payload: displayPastFeedback,
    });
  const setdomain = (domain: string) =>
    dispatch({ type: "UPDATE_domain", payload: domain });
  const setErrorText = (errorText: string) =>
    dispatch({ type: "UPDATE_errorText", payload: errorText });
  const setfeedback = (feedback: string) =>
    dispatch({ type: "UPDATE_feedback", payload: feedback });
  const setfeedbackDuration = (feedbackDuration: number) =>
    dispatch({ type: "UPDATE_feedbackDuration", payload: feedbackDuration });
  const setfeedbackNextFunc = (feedbackNextFunc: (() => void) | null) =>
    dispatch({ type: "UPDATE_feedbackNextFunc", payload: feedbackNextFunc });
  const setfullprompt = (fullprompt: GPTResponse[]) =>
    dispatch({ type: "UPDATE_fullprompt", payload: fullprompt });
  const setGPTCallCount = (GPTCallCount: number) =>
    dispatch({ type: "UPDATE_GPTCallCount", payload: GPTCallCount });
  const sethitMaxLen = (hitMaxLen: boolean) =>
    dispatch({ type: "UPDATE_hitMaxLen", payload: hitMaxLen });
  const setinitialLoad = (initialLoad: boolean) =>
    dispatch({ type: "UPDATE_initialLoad", payload: initialLoad });
  const setintQuestion = (intQuestion: number) =>
    dispatch({ type: "UPDATE_intQuestion", payload: intQuestion });
  const setinterviewer = (interviewer: string) =>
    dispatch({ type: "UPDATE_interviewer", payload: interviewer });
  const setisHelpful = (isHelpful: boolean | null) =>
    dispatch({ type: "UPDATE_isHelpful", payload: isHelpful });
  const setisHelpfulMessage = (isHelpfulMessage: string | null) =>
    dispatch({ type: "UPDATE_isHelpfulMessage", payload: isHelpfulMessage });
  const setjobDescriptionQ = (jobDescriptionQ: JobDescription) =>
    dispatch({ type: "UPDATE_jobDescriptionQ", payload: jobDescriptionQ });
  const setlevel = (level: string) =>
    dispatch({ type: "UPDATE_level", payload: level });
  const setloading = (loading: boolean) =>
    dispatch({ type: "UPDATE_loading", payload: loading });
  const setloadingOverlayComp = (loadingOverlayComp: ReactNode | null) =>
    dispatch({
      type: "UPDATE_loadingOverlayComp",
      payload: loadingOverlayComp,
    });
  const setlogin = (login: boolean) =>
    dispatch({ type: "UPDATE_login", payload: login });
  const setmemberFeedbackModal = (memberFeedbackModal: ReactNode | null) =>
    dispatch({
      type: "UPDATE_memberFeedbackModal",
      payload: memberFeedbackModal,
    });
  const setmicOn = (micOn: boolean) =>
    dispatch({ type: "UPDATE_micOn", payload: micOn });
  const setnext = (next: boolean) =>
    dispatch({ type: "UPDATE_next", payload: next });
  const setNextFunc = (nextFunc: (() => void) | null) =>
    dispatch({ type: "UPDATE_nextFunc", payload: nextFunc });
  const setpastInfo = (pastInfo: any) =>
    dispatch({ type: "UPDATE_pastInfo", payload: pastInfo });
  const setprevious = (previous: boolean) =>
    dispatch({ type: "UPDATE_previous", payload: previous });
  const setprivateSession = (privateSession: boolean) =>
    dispatch({ type: "UPDATE_privateSession", payload: privateSession });
  const setprocessing = (processing: boolean) =>
    dispatch({ type: "UPDATE_processing", payload: processing });
  const setprofileUser = (profileUser: User | null) =>
    dispatch({ type: "UPDATE_profileUser", payload: profileUser });
  const setprompt = (prompt: GPTResponse[]) =>
    dispatch({ type: "UPDATE_prompt", payload: prompt });
  const setqA = (qA: string) => dispatch({ type: "UPDATE_qA", payload: qA });
  const setqPrompt = (qPrompt: GPTResponse[]) =>
    dispatch({ type: "UPDATE_qPrompt", payload: qPrompt });
  const setqType = (qType: string | null) =>
    dispatch({ type: "UPDATE_qType", payload: qType });
  const setquestionLevelMap = (questionLevelMap: { [key: string]: string }) =>
    dispatch({ type: "UPDATE_questionLevelMap", payload: questionLevelMap });
  const setquestionModal = (questionModal: ReactNode | null) =>
    dispatch({ type: "UPDATE_questionModal", payload: questionModal });
  const setquestionQuery = (questionQuery: string) =>
    dispatch({ type: "UPDATE_questionQuery", payload: questionQuery });
  const setquestions = (questions: string[]) =>
    dispatch({ type: "UPDATE_questions", payload: questions });
  const setrecognizedsp = (recognizedsp: string) =>
    dispatch({ type: "UPDATE_recognizedsp", payload: recognizedsp });
  const setrecognizedspTemp = (recognizedspTemp: string) =>
    dispatch({ type: "UPDATE_recognizedspTemp", payload: recognizedspTemp });
  const setresume = (resume: { resumeText: string; resumeName: string }) =>
    dispatch({ type: "UPDATE_resume", payload: resume });
  const setretryPast = (retryPast: boolean) =>
    dispatch({ type: "UPDATE_retryPast", payload: retryPast });
  const setrole = (role: string) =>
    dispatch({ type: "UPDATE_role", payload: role });
  const setsessionStopped = (sessionStopped: boolean) =>
    dispatch({ type: "UPDATE_sessionStopped", payload: sessionStopped });
  const setshowAction = (showAction: boolean) =>
    dispatch({ type: "UPDATE_showAction", payload: showAction });
  const setAction = (action: string) =>
    dispatch({ type: "UPDATE_action", payload: action });
  const setshowAns = (showAns: boolean) =>
    dispatch({ type: "UPDATE_showAns", payload: showAns });
  const setshowApiError = (showApiError: boolean) =>
    dispatch({ type: "UPDATE_showApiError", payload: showApiError });
  const setshowFeedback = (showFeedback: boolean) =>
    dispatch({ type: "UPDATE_showFeedback", payload: showFeedback });
  const setshowFeedbackBox = (showFeedbackBox: boolean) =>
    dispatch({ type: "UPDATE_showFeedbackBox", payload: showFeedbackBox });
  const setshowHistoryModal = (showHistoryModal: boolean) =>
    dispatch({ type: "UPDATE_showHistoryModal", payload: showHistoryModal });
  const setshowintroFormModal = (showintroFormModal: boolean) =>
    dispatch({
      type: "UPDATE_showintroFormModal",
      payload: showintroFormModal,
    });
  const setshowNextConfirmation = (showNextConfirmation: boolean) =>
    dispatch({
      type: "UPDATE_showNextConfirmation",
      payload: showNextConfirmation,
    });
  const setshowPaymentModal = (showPaymentModal: boolean) =>
    dispatch({ type: "UPDATE_showPaymentModal", payload: showPaymentModal });
  const setshowSettings = (showSettings: boolean) =>
    dispatch({ type: "UPDATE_showSettings", payload: showSettings });
  const setshowsigninModal = (showsigninModal: boolean) =>
    dispatch({ type: "UPDATE_showsigninModal", payload: showsigninModal });
  const setshowTranscript = (showTranscript: boolean) =>
    dispatch({ type: "UPDATE_showTranscript", payload: showTranscript });
  const setspecType = (specType: string) =>
    dispatch({ type: "UPDATE_specType", payload: specType });
  const setspeechLen = (speechLen: number) =>
    dispatch({ type: "UPDATE_speechLen", payload: speechLen });
  const setstart = (start: boolean) =>
    dispatch({ type: "UPDATE_start", payload: start });
  const setAbortController = (abortController: AbortController) =>
    dispatch({ type: "UPDATE_abortController", payload: abortController });
  const settype = (type: string) =>
    dispatch({ type: "UPDATE_type", payload: type });
  const setuserAudio = (userAudio: boolean) =>
    dispatch({ type: "UPDATE_userAudio", payload: userAudio });
  const pushqPrompt = (payload: any) =>
    dispatch({ type: "PUSH_qPrompt", payload });
  const pushprompt = (payload: any) =>
    dispatch({ type: "PUSH_prompt", payload });
  const pushfullprompt = (payload: any) =>
    dispatch({ type: "PUSH_fullprompt", payload });
  const pushquestions = (payload: string) =>
    dispatch({ type: "PUSH_questions", payload });
  const pushquestionLevelMap = (key: string, val: string) =>
    dispatch({ type: "PUSH_questionLevelMap", key, val });
  const popprompt = () => dispatch({ type: "POP_prompt" });

let audioElement: HTMLAudioElement | null = document.getElementById("audioElement") as HTMLAudioElement | null;

  const supportedRoles = ["software engineering", "product management"];

  const typeDict: { [key: string]: string } = {
    "software engineeringbehavioral": "swehypothetical",
    "product managementbehavioral": "pmhypothetical",
  };

const hasPremiumSub = (): boolean => {
  return hasPremSub(state.profileUser);
}
  const asyncGetAnswer = async (): Promise<void> => {
    let promptComp = {
      specType: state.specType,
      type: state.type,
      role: state.role,
      codingLang: state.codingLang,
      question: state.questions[state.currentQuestion],
      gptCallType: "solution",
    };

    const ans = await getGPTResponseServer(promptComp, showError);

    if (!state.abortController.signal.aborted) {
      setqA(extractAnswer(ans));
      setloading(false);
    }
  };

  const showError = (val: boolean): void => {
    setshowApiError(val);
  };

  const asyncGetAction = async (): Promise<void> => {
    if (state.displayPastFeedback !== "") {
      reducers.setshowAction(true);
    } else {
      let promptComp = {
        feedback: state.feedback,
        gptCallType: "action",
      };
      const ans = await getGPTResponseServer(promptComp, showError);

      if (!state.abortController.signal.aborted) {
        reducers.setAction(ans);
        reducers.setloading(false);
      }
    }
  };

  const extractAnswer = (resp: string): string => {
    return resp;
  };

  const getAction = (): void => {
    stopAudio();
    if (state.action === "") {
      reducers.setloading(true);
      asyncGetAction();
    }
    reducers.setshowAction(true);
    reducers.setshowAns(false);
    reducers.setshowFeedback(false);
  };

  const getAnswer = (): void => {
    stopAudio();

    if (state.qA === "") {
      reducers.setloading(true);
      asyncGetAnswer();
    }
    reducers.setshowAns(true);
    reducers.setshowAction(false);
    reducers.setshowFeedback(false);
  };

  const getFeedback = (): void => {
    if (state.canStop) {
      stopAudio();
    }
    if (state.feedback === "") {
      reducers.setshowTranscript(false);
      reducers.setloading(true);
      getFeedbackAsync();
    }
    reducers.setshowAction(false);
    reducers.setshowAns(false);
    reducers.setshowFeedback(true);
    reducers.setrecognizedsp("");
  };

const getFeedbackAsync = async (): Promise<void> => {
  let prompt1 = [...state.prompt];
  let fullprompt1 = [...state.fullprompt];
  if (state.code !== '') {
      const element = { role: "user", content: `Here is final ${state.codingLang} content I have on the whiteboard:\n \`\`\`${state.code}\`\`\`` };
      prompt1.push(element);
      fullprompt1.push(element);
      reducers.setprompt(prompt1);
      reducers.setfullprompt(fullprompt1);
    }

    let feedbackL = "";
    if (state.prompt.length === 0) {
      feedbackL = `I'm sorry, but you haven't provided a response to the interview question. Therefore, I cannot provide feedback on your answer. Please make sure to address the question and provide your thoughts.`;
    } else {
      let arrToUse =
        state.specType !== "coding" &&
        state.specType !== "system design" &&
        state.specType !== "product design" &&
        state.specType !== "estimation"
          ? prompt1
          : fullprompt1;
      const now = new Date();

      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      let promptComp: { [key: string]: any } = {
        promptArr: arrToUse,
        specType: state.specType,
        role: state.role,
        type: state.type,
        domain: state.domain,
        question: state.questions[state.currentQuestion],
        orgAccessible: !state.privateSession,
        gptCallType: "feedback",
        whiteboard: state.code !== "" ? state.code : null,
        codingLang: state.code !== "" ? state.codingLang : null,
        timestamp: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
        transcript: prompt1.length === 0 ? [] : prompt1,
      };
      if (
        state.type === "org" &&
        state.orgQuestions &&
        state.orgQuestions[state.role]
      ) {
        let rub = state.orgQuestions[state.role][state.currentQuestion].rubric;
        if (rub !== null && rub !== "") {
          promptComp["rubric"] = rub;
        }
      }

      let t = await getGPTResponseServer(promptComp, showError);
      feedbackL = t?.message;
      if (t && t.user) {
        reducers.setprofileUser(t?.user);
      }
    }
    if (!state.abortController.signal.aborted) {
      reducers.setinterviewer(feedbackL);
      reducers.setfeedback(feedbackL);
      reducers.setshowFeedback(true);
    }
    reducers.setloading(false);
  };

  const GetQ = (newQ: boolean, prevQ: boolean): void => {
    const newI = newQ
      ? state.currentQuestion + 1
      : prevQ
      ? state.currentQuestion - 1
      : state.currentQuestion;
    if (newQ || prevQ) {
      setcurrentQuestion(newI);
    }
    let q = state.questions[newI].replace(/\\n/, "");
    setprompt([]);
    setfullprompt([]);
    setinterviewer(q);
    setloading(false);
    if (state.specType === "afrotech-behavioral") {
      synthesizeSpeech("Tell me about yourself");
    } else if (state.specType === "afrotech-technical") {
      synthesizeSpeech(
        "The animal shelter just got a new litter of puppies! We can't wait for them to be adopted. What is the best way for the shelter to store the data on the puppies?"
      );
    } else {
      synthesizeSpeech(q);
    }
  };

  const showFeedbackPopUp = (): boolean => {
    return (
      state.feedback !== "" &&
      state.prompt.length !== 0 &&
      state.displayPastFeedback === ""
    );
  };

  const gpt = async (): Promise<string | null> => {
    if (
      state.specType !== "coding" &&
      state.specType !== "system design" &&
      state.specType !== "product design" &&
      state.specType !== "estimation" &&
      !state.useWhiteboard
    ) {
      if (state.recognizedsp !== "") {
        setGPTCallCount(state.GPTCallCount + 1);
        let prompt1 = [...state.prompt];
        prompt1.push({ role: "user", content: state.recognizedsp });

        let obj = {
          transcript: prompt1,
          specType: state.specType,
          type: state.type,
          role: state.role,
          query: state.questionQuery,
          question: state.questions[state.currentQuestion],
          gptCallType: "conversation",
        };
        let response = await getGPTResponseServer(obj, showError);
        if (!state.abortController.signal.aborted) {
          setinterviewer(response);
          prompt1.push({ role: "assistant", content: response });
        }
        setprompt(prompt1);
        setcontextLength(
          state.contextLength +
            response.split(" ").length +
            state.recognizedsp.split(" ").length
        );
        return response;
      }
    } else {
      let codeCont = `${
        state.code !== ""
          ? `Firstly, here is what i have on the whiteboard:\n \`\`\`${state.code}\`\`\``
          : ""
      }`;
      if (state.recognizedsp !== "" || codeCont !== "") {
        let fullprompt1 = [...state.fullprompt];
        fullprompt1.push({
          role: "user",
          content: `${codeCont} ${state.recognizedsp}`,
        });
        setGPTCallCount(state.GPTCallCount + 1);
        let obj = {
          transcript: fullprompt1,
          specType: state.specType,
          type: state.type,
          role: state.role,
          query: state.questionQuery,
          question: state.questions[state.currentQuestion],
          gptCallType: "conversation",
        };
        let response = await getGPTResponseServer(obj, showError);
        if (!state.abortController.signal.aborted) {
          setinterviewer(response);
          let prompt1 = [
            ...state.prompt,
            { role: "user", content: state.recognizedsp },
            { role: "assistant", content: response },
          ];
          setprompt(prompt1);
          fullprompt1.push({ role: "assistant", content: response });
          setfullprompt(fullprompt1);
        }
        setcontextLength(
          state.contextLength +
            response.split(" ").length +
            state.recognizedsp.split(" ").length +
            state.code.split(" ").length
        );
        return response;
      }
    }
    return null;
  };

  const isOtherRole = (): boolean => {
    return !supportedRoles.includes(state.role);
  };

  const deriveQuestionQuery = (q: string | null = null): string => {
    return `type=${state.type}+role=${state.role}+spectype=${
      state.specType
    }+specifics=${
      state.type === "domain"
        ? state.domain
        : state.type === "jobDescriptionQ"
        ? state.jobDescriptionQ.title
        : state.type === "custom"
        ? q == null
          ? state.customQ
          : q
        : ""
    }`;
  };

  const loadQs = async (
    newQ: boolean,
    addOn: boolean,
    abortControllerP: AbortController | null = null
  ): Promise<void> => {
    let abortControllerL = abortControllerP
      ? abortControllerP
      : state.abortController;
    if (addOn) {
      await updateQuestions(abortControllerL);
      if (!abortControllerL.signal.aborted) {
        setgetq([newQ, false]);
      }
    } else if (state.type === "org" && state.orgQuestions) {
      setqPrompt([]);
      setquestions(state.orgQuestions[state.role].map((x) => x.question));
      setquestionLevelMap({});
      if (!abortControllerL.signal.aborted) {
        if (state.qType === "choose question") {
          setgetq([false, false]);
        } else {
          setgetq([newQ, false]);
        }
      }
    } else {
      if (
        (state.type === "jobDescriptionQ" ||
          state.specType === "resume based" ||
          state.type === "domain" ||
          (isOtherRole() && state.specType === "role")) &&
        state.questionQuery !== deriveQuestionQuery()
      ) {
        setqPrompt([]);
        setquestions([]);
        setquestionLevelMap({});

        await updateQuestions(abortControllerL);
        if (!abortControllerL.signal.aborted) {
          if (
            state.type === "domain" ||
            (isOtherRole() && state.specType === "role") ||
            state.specType === "resume based" ||
            state.type === "jobDescriptionQ"
          ) {
            setquestionQuery(deriveQuestionQuery());
          }
          if (state.qType === "choose question") {
            setgetq([false, false]);
          } else {
            setgetq([newQ, false]);
          }
        }
      } else {
        await setQs(abortControllerL);
        if (!abortControllerL.signal.aborted) {
          if (state.qType === "choose question") {
            setgetq([false, false]);
          } else {
            setgetq([newQ, false]);
          }
        }
      }
    }
  };

  const parseQs = (resp: string): string[] => {
    const result = resp.split(/\d+\)?\./);
    if (result[0] === "") {
      return result.splice(1);
    }
    return result;
  };

  const pickQuestion = (endScreen: boolean = false): void => {
    if (endScreen && showFeedbackPopUp()) {
      setfeedbackNextFunc(() => {
        navigateThenRun(() => {
          pickQuestionCont();
        }, "/track");
      });
    } else {
      navigateThenRun(() => {
        pickQuestionCont();
      }, "/track");
    }
  };

  const pickQuestionCont = (): void => {
    setintQuestion(5);
    setqType("choose question");
    setcurrScreen(1);
    setcurrentQuestion(-1);
    setstart(false);
    setloading(true);
    setprevious(false);
    resetState(null, true);
  };

  const refreshUser = async (): Promise<void> => {
    auth0.loginWithPopup();
  };

  const resetState = (
    newAbort: AbortController | null = null,
    noPopUp: boolean = false
  ): void => {
    if (!noPopUp && showFeedbackPopUp()) {
      setfeedbackNextFunc(() => {
        resetStateCont();
      });
    } else {
      resetStateCont(newAbort);
    }
  };

  const resetStateCont = (newAbort: AbortController | null = null): void => {
    state.abortController.abort();
    setshowTranscript(false);
    setfeedback("");
    setfeedbackDuration(-1);
    setqA("");
    setprivateSession(false);
    setcode("");
    setshowAns(false);
    setshowAction(false);
    setshowFeedback(false);
    setAction("");
    setisHelpful(null);
    setisHelpfulMessage(null);
    setrecognizedspTemp("");
    setrecognizedsp("");
    setAbortController(newAbort ? newAbort : new AbortController());
    setspeechLen(0);
    setcontextLength(500);
    sethitMaxLen(false);
    setdisplayPastFeedback("");
    setpastInfo({});
    setGPTCallCount(0);
    setuseWhiteboard(false);
    setcontinueFunc(false);
  };

  const SendUserFeedbackInfo = async (): Promise<void> => {
    //console.log(state.isHelpful);
    const qI: FeedbackUpdate = {
      isHelpful: state.isHelpful,
      isHelpfulMessage: state.isHelpfulMessage,
      actionSteps: state.action,
      orgAccessible: !state.privateSession,
    };
    const user2 = await updateUserAnsweredQuestions(
      state.questions[state.currentQuestion],
      qI
    );
    setprofileUser(user2);
    setcontinueFunc(true);
  };

  const setQs = async (
    abortControllerP: AbortController | null = null,
    q: string | null = null
  ): Promise<void> => {
    let abortControllerL = abortControllerP
      ? abortControllerP
      : state.abortController;
    let query = deriveQuestionQuery(q);
    if (state.type === "custom" || q !== null) {
      setquestionQuery(query);
      if (q !== null) {
        setcustomQ(q);
      }
      setquestions([]);
      pushquestions(q !== null ? q : state.customQ);
    } else if (state.type === "org" && state.orgQuestions) {
      setquestionQuery(query);
      setquestions(
        state.orgQuestions[state.role] === undefined
          ? []
          : state.orgQuestions[state.role].map((x) => x.question)
      );
    } else if (
      (isOtherRole() && state.specType === "role") ||
      state.type === "domain"
    ) {
      if (
        state.questionQuery !==
        `${state.role}${state.type === "domain" ? state.domain : state.type}`
      ) {
        setquestions([]);
        updateQuestions(abortControllerL);
        setquestionQuery(query);
      }
    } else if (state.specType === "resume based") {
      if (state.questionQuery !== query) {
        setquestions([]);
        updateQuestions(abortControllerL);
        setquestionQuery(query);
      }
    } else if (state.type === "jobDescriptionQ") {
      if (state.questionQuery !== query) {
        setquestions([]);
        updateQuestions(abortControllerL);
        setquestionQuery(query);
      }
    } else {
      if (state.questionQuery !== query) {
        let dBaseQuery =
          state.type === "behavioral" && state.specType !== "general"
            ? typeDict[`${state.role}${state.type}`]
            : state.specType;
        setquestions([]);
        const resp = await getQuestionsDatabase(dBaseQuery);
        if (!abortControllerL.signal.aborted) {
          const arr = shuffleArray(resp);
          arr.forEach((x) => {
            if (state.type !== "behavioral") {
              let indPar = x.lastIndexOf("(");
              if (indPar !== -1) {
                let q = x.slice(0, indPar - 1);
                let level = x.slice(indPar + 1, -1);
                pushquestionLevelMap(q, level);
                pushquestions(q);
              } else {
                pushquestions(x);
              }
            } else {
              pushquestions(x);
            }
          });
          if (state.type === "behavioral") {
            setquestionLevelMap({});
          }
          setquestionQuery(query);
        }
      }
    }
  };

  const shuffleArray = (array: any[]): any[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

const isAuth = (): boolean => {
    return !auth0.isLoading && auth0.isAuthenticated;
  };

  const startFunc = (newQ: boolean, prevQ: boolean): void => {
    if (audioElement) {
      audioElement.src = createSilentAudio(10, 44100);
      audioElement.play();
    }
    if (showFeedbackPopUp()) {
      setfeedbackNextFunc(() => {
        navigateThenRun(() => {
          startFuncCont(newQ, prevQ);
        }, "/interview");
      });
    } else {
      navigateThenRun(() => {
        startFuncCont(newQ, prevQ);
      }, "/interview");
    }
  };

  const startFuncCont = (newQVar: boolean, prevQVar: boolean): void => {
    setstart(true);
    let a = new AbortController();
    resetState(a, true);
    if (state.currentQuestion === 1 && prevQVar) {
      setprevious(false);
    } else if (
      state.currentQuestion > 0 ||
      (state.currentQuestion == 0 && newQVar)
    ) {
      setprevious(true);
    }
    if (prevQVar) {
      setnext(true);
    }
    if (state.loading) {
      loadQs(newQVar, false, a);
    } else if (prevQVar) {
      setgetq([false, true]);
    } else if (
      newQVar &&
      state.currentQuestion === state.questions.length - 2
    ) {
      setnext(false);
      setgetq([newQVar, false]);
    } else if (
      newQVar &&
      state.currentQuestion === state.questions.length - 1
    ) {
      setloading(true);
      loadQs(newQVar, true, a);
    } else {
      setgetq([newQVar, false]);
    }
  };

  const stopAudio = (): void => {
    if (audioElement) {
      audioElement.src = "";
      setcanStop(false); // in case bug on iPhone
    }
  };
  
  const updateQuestions = async (
    abortControllerP: AbortController | null = null
  ): Promise<void> => {
    //console.log("updateQuestions");
    let abortControllerL = abortControllerP
      ? abortControllerP
      : state.abortController;
    if (
      state.specType === "resume based" &&
      state.profileUser &&
      state.profileUser.resumeQuestions &&
      state.profileUser.resumeQuestions.length !== 0
    ) {
      state.profileUser.resumeQuestions.forEach((x: string) =>
        pushquestions(x)
      );
      let res = "";
      state.profileUser.resumeQuestions.forEach(
        (x: string, ind: number) => (res += `${ind}. ${x}\n`)
      );
      pushqPrompt({ role: "assistant", content: res });
    } else {
      let obj = {
        current: state.qPrompt,
        specType: state.specType,
        role: state.role,
        type: state.type,
        domain: state.domain,
        query: state.questionQuery,
        jobDescriptionQ: state.jobDescriptionQ.description,
        jobDescriptionQTitle: state.jobDescriptionQ.title,
        resumeText: state.resume.resumeText,
        gptCallType: "questions",
      };

      let resA: string[] = [];
      let resp = "";
      while (resA.length === 0) {
        resp = await getGPTResponseServer(obj, showError);
        if (!abortControllerL.signal.aborted) {
          let resD: string[];
          try {
            resD = JSON.parse(resp);
          } catch {
            resD = [resp];
          }
          resD.forEach((xp) => {
            const res = parseQs(xp);
            let a2: string[] = [];
            res.forEach((x: string) => {
              if (x !== "" && x !== "<br/>") a2.push(x.replace(/<br\/>/, ""));
            });
            const regex = /^[0-9]/;

            if (!regex.test(xp)) {
              a2.shift();
            }
            resA.push(...a2);
            pushqPrompt({ role: "assistant", content: xp });
          });
        }
      }
      if (!abortControllerL.signal.aborted) {
        resA.forEach((x: string) => pushquestions(x));
        if (state.type === "domain") {
          pushqPrompt({ role: "assistant", content: resp });
        }
        if (state.specType === "resume based" && state.profileUser) {
          let user2 = await updateUserResumeQuestions(resA);
          setprofileUser(user2);
        }
      }
    }
  };

const UpdateUserAQ = async (): Promise<void> => {
    //console.log(state.isHelpful);
    if (state.isHelpful === null && state.prompt.length === 0) {
      setshowFeedbackBox(true);
    } else {
      await SendUserFeedbackInfo();
    }
  };

  const navigateThenRun = (func: (() => void) | null, path: string): void => {
    if (location.pathname !== path) {
      const { abortController, ...state2 } = state;
      window.history.replaceState({ key: location.key, state: state2 }, "");
    }
    if (func !== null) {
      func();
    }
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const synthesizeSpeech = async (text: string): Promise<void> => {
    // avoid overlay of audio
    if (state.canStop) {
      stopAudio();
    }
    if (state.userAudio) {
      const tokenObj = await getTokenOrRefresh();
      if (
        !state.abortController.signal.aborted &&
        tokenObj.authToken &&
        tokenObj.region
      ) {
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
          tokenObj.authToken,
          tokenObj.region
        );

        speechConfig.speechSynthesisLanguage = "en-US";
        speechConfig.speechSynthesisVoiceName = "en-US-MichelleNeural";

        const speechSynthesizer = new speechsdk.SpeechSynthesizer(
          speechConfig,
          AudioConfig.fromStreamOutput(
            speechsdk.AudioOutputStream.createPullStream()
          )
        );
        setcanStop(true);
        return speechSynthesizer.speakTextAsync(
          text === ""
            ? state.interviewer.replace(/<br\/>/g, "")
            : text.replace(/<br\/>/g, "").replace(/\\/g, ""),
          (result: any) => {
            if (result) {
              speechSynthesizer.close();
              const blob = new Blob([result.audioData], { type: "audio/wav" });
              const blobUrl = URL.createObjectURL(blob);
              if (audioElement) {
                audioElement.src = blobUrl;
                audioElement.play();
              }
              return result.audioData;
            }
          },
          (error: string) => {
            setcanStop(false);
            console.log(error);
            speechSynthesizer.close();
          }
        );
      }
    }
  };

  const auth0 = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      if (isAuth()) {
        setlogin(false);
        setshowsigninModal(false);
        let jwtToken = await auth0.getAccessTokenSilently();
        const cookie = new Cookie();
        cookie.set("oidToken", jwtToken, { path: "/", secure: true });
        //call our server
        let user = auth0.user;
        if (user) {
          let user1 = await getUser(
            user.email ?? "",
            user.name ?? "",
            user.email_verified ?? false
          );
          if (user1 !== null && appInsights) {
            appInsights.setAuthenticatedUserContext(user1.oid);
            //re-enable verification
            if (user.email_verified && !user1.email_verified) {
              user1 = await updateUserVerification();
            }
            setprofileUser(user1);
            if (window.location.pathname === "/interview") {
              if (!hasPremSub(user1)) {
                setshowPaymentModal(true);
              }
            }
          }
        }
      }
    };
    fetchData();
  }, [auth0]); // Add dependencies if needed

  useEffect(() => {
    setresume(
      state.profileUser
        ? {
            resumeName: state.profileUser.resumename,
            resumeText: state.profileUser.resumetext,
          }
        : { resumeName: "", resumeText: "" }
    );
    setuserAudio(state.profileUser ? state.profileUser.audioEnabled : true);
  }, [state.profileUser]);

  useEffect(() => {
    if (state.beginningLoad && showFeedbackPopUp()) {
      setfeedbackNextFunc(() => {
        window.location.href = "https://www.thedevdifference.com";
      });
    }
  }, [state.beginningLoad]);

  useEffect(() => {
    const fetchToken = async () => {
      const tokenRes = await getTokenOrRefresh();
      if (tokenRes.authToken === null) {
        console.log(tokenRes.error);
      }
      if (audioElement) {
          audioElement.addEventListener("ended", () => {
              setcanStop(false);
          });
      }
  };
  fetchToken();
}, []);

  useEffect(() => {
    const handleInitialLoad = async () => {
      if (state.initialLoad) {
        if (location.pathname !== "/" && location.pathname !== "/signup" && location.pathname !== "/pay") {
          window.location.href = "/";
        } else {
          setinitialLoad(false);
        }
      } else {
        if (
          window.history.state?.key === location.key &&
          window.history.state?.state !== undefined
        ) {
          setWholeState(window.history.state.state);
        }
      }
      if (location.pathname === "/interview") {
        if (!isAuth()) {
          setshowsigninModal(true);
        } else if (!state.canPractice) {
          setshowintroFormModal(true);
        } else if (!hasPremiumSub()) {
          setshowPaymentModal(true);
        }
      }
    };
    handleInitialLoad();
  }, [location]);

  useEffect(() => {
    if (state.canPractice) {
      setshowintroFormModal(false);
      getOrgQuestions().then((qs) => setorgQuestions(qs));
    }
  }, [state.canPractice]);

  useEffect(() => {
    const updateUserAnsweredQuestions = async () => {
      if (state.feedbackNextFunc != null) {
        await UpdateUserAQ();
      }
    };
    updateUserAnsweredQuestions();
  }, [state.feedbackNextFunc]);

  useEffect(() => {
    if (state.login) {
      auth0.loginWithPopup();
    }
  }, [state.login]);

  useEffect(() => {
    const scrollingDiv = document.getElementById("scrollingDiv");
    if (scrollingDiv !== null) {
      scrollingDiv.scrollTop = scrollingDiv.scrollHeight;
    }
  }, [state.processing]);

  useEffect(() => {
    if (state.type !== "domain") {
      setdomain("");
    }
    if (state.type !== "jobDescriptionQ") {
      setjobDescriptionQ({ description: "", title: "" });
    }
  }, [state.type]);

  useEffect(() => {
    if (state.profileUser && !state.canPractice) {
      if (
        Object.keys(state.profileUser.intakeForm).length === 0 ||
        !state.profileUser.email_verified
      ) {
        setshowintroFormModal(state.showPaymentModal !== true && !getPaymentInfo());
      } else {
        setcanPractice(true);
      }
    }
  }, [state.profileUser]);

  useEffect(() => {
    if (state.recognizedspTemp !== "") {
      setrecognizedsp(`${state.recognizedsp} ${state.recognizedspTemp}`);
      let l = state.speechLen + state.recognizedspTemp.split(" ").length + 1;
      //console.log(l);
      if (l + state.contextLength > 5000) {
        // 5000 is true max, giving room for gpt 68 words response and also any final words user has.
        sethitMaxLen(true);
      }
      setspeechLen(l);
    }
  }, [state.recognizedspTemp]);

  useEffect(() => {
    if (state.retryPast === true) {
      navigateThenRun(() => {
        startFunc(false, false);
      }, "/interview");
    }
  }, [state.retryPast]);

  useEffect(() => {
    const handleSessionStopped = async () => {
      if (state.sessionStopped && !state.micOn) {
        const resp = await gpt();
        if (!state.abortController.signal.aborted && resp !== null) {
          if (state.recognizedsp !== "") {
            setshowTranscript(true);
            synthesizeSpeech(resp);
          }
          setprocessing(false);
        }
      }
    };
    handleSessionStopped();
  }, [state.sessionStopped, state.micOn]);

  useEffect(() => {
    if (state.getq.length === 2) {
      GetQ(state.getq[0], state.getq[1]);
    }
  }, [state.getq]);

  useEffect(() => {
    if (state.runSetQs) {
      setQs();
    }
    setrunSetQs(false);
  }, [state.runSetQs]);

  useEffect(() => {
    const sendUserFeedbackInfo = async () => {
      if (state.showFeedbackBox === false && state.feedbackNextFunc !== null) {
        await SendUserFeedbackInfo();
      }
    };
    sendUserFeedbackInfo();
  }, [state.showFeedbackBox]);

  useEffect(() => {
    const handleContinueFunc = async () => {
      if (state.continueFunc && state.feedbackNextFunc !== null) {
        state.feedbackNextFunc();
        setfeedbackNextFunc(null);
      }
    };
    handleContinueFunc();
  }, [state.continueFunc]);

  useEffect(() => {
    if (state.useWhiteboard === false) {
      setcode("");
    }
  }, [state.useWhiteboard]);

  useEffect(() => {
    const initAppInsights = async () => {
      appInsights = await AppInsightsProvider("Start", "/");
    };
    initAppInsights();
  }, []);

  return (
    <AppContext.Provider
      value={{
        auth0,
        state,
        dispatch,
        reducers,
        navigateThenRun,
        showError,
        getFeedback,
        showFeedbackPopUp,
        stopAudio,
        refreshUser,
        synthesizeSpeech,
        pickQuestion,
        startFunc,
        getAnswer,
        getAction,
        isAuth,
        setQs
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
}
