import React from 'react';
import ReactModal from 'react-modal';
import EndScreen from './endScreen';
import { GPTResponse } from './types';

interface EndScreenModalProps {
  showFeedback: boolean;
  toggleFeedbackModal: () => void;
  question: string;
  content: string;
  showTranscript: boolean;
  promptArray: GPTResponse[];
  qLevel: string | null;
  displayPastFeedback: boolean;
  action: string;
  adminView: boolean;
  memberEmail: string;
}

const EndScreenModal: React.FC<EndScreenModalProps> = (props) => {
  const customStyles: ReactModal.Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '97vw',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1.5rem',
      backgroundColor: '#f4f4f5',
      padding: '0px'
    },
  };

  return (
    <ReactModal
      isOpen={props.showFeedback}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => props.toggleFeedbackModal()}
      ariaHideApp={false}
    >
      <>
        <h3 className='close-modal-x' onClick={() => props.toggleFeedbackModal()}>x</h3>
        <EndScreen
          question={props.question}
          content={props.content}
          showFeedback={props.showFeedback}
          showTranscript={props.showTranscript}
          promptArray={props.promptArray}
          qLevel={props.qLevel}
          displayPastFeedback={props.displayPastFeedback}
          action={props.action}
          adminView={true}
          memberEmail={props.memberEmail}
        />
      </>
    </ReactModal>
  );
}

export default EndScreenModal;
