import React, { useState, useEffect } from 'react';
import littleGuy from "../../TDD-Images/little-guy-alone_2.png";
import { capitalizeFirstLetter, getPackName, hasMultipleQuestionPacks, parseQuestionQuery } from '../util';
import { useNavigate } from 'react-router-dom';
import { User } from './types';

interface LandingPageProps {
  user?: User | null;
  setRole: (role: string) => void;
  setType: (type: string) => void;
  setSpecType: (specType: string) => void;
  setqType: (qType: string) => void;
  QuestionSelected: (question: number) => void;
  setintQuestion: (intQuestion: number) => void;
  setQs: () => void;
  setCustomQ: (customQ: string) => void;
  setDomain: (domain: string) => void;
  setJobDescriptionQ: (jobDescriptionQ: { title: string; description: string }) => void;
  next: () => void;
  startFunc: () => void;
  showLastFeedback: (lastInterviewCompleteQ: string) => void;
  jobDescriptions: { [key: string]: string } | null;
}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  document.body.style.backgroundColor = 'white';
  const navigate = useNavigate();

  const [userTrack, setUserTrack] = useState<string | null>(null);
  const [parsedQuery, setParsedQuery] = useState<{ [key: string]: string }>({});
  const [callNext, setcallNext] = useState<boolean>(false);


  const afrotechB = `tell me about yourself but first, select the right framework to get started. the options are:
  Framework: Present - Past - Future
  Present: Your current role and main responsibilities.
  Past: Relevant experience that led you here.
  Future: Why you're interested in a particular role.

  Framework : Passion - Journey - Purpose
  Passion: What drives you in your work.
  Journey: Key experiences that shaped your path.
  Purpose: Why this role aligns with your goals.
  
  Framework: Personal Details - Hobbies - Casual Experience
  Personal Details: Where you're from, birthday.
  Hobbies: Non-work interests.
  Casual Experience: Unrelated past jobs.`;

  const afrotechT = `The animal shelter just got a new litter of puppies! We can't wait for them to be adopted. What is the best way for the shelter to store the data on the puppies out of the three options below:
  Stack; List; Hashtable`; 

  useEffect(() => {
    setUserTrack(getTrack());
  }, [props.user]);

  useEffect(()=>{
    if(callNext)
    {
      props.startFunc();
      setcallNext(false);
    }
  }, [callNext]);

  const setAfrotechInterviewTrack = (beh:boolean) => {
    props.setRole(props.user!.intakeForm['3']!=="undecided" ? props.user!.intakeForm['3'] as string: "professional in tech industry");
    props.setType("custom");
    props.setSpecType(beh? "afrotech-behavioral" : "afrotech-technical");
    props.setQs();
    props.setqType('choose question');
    props.setCustomQ(beh ? afrotechB : afrotechT);
    props.QuestionSelected(0);
    setcallNext(true);
  }

  const setContinueInterviewTrack = () => {
    props.setRole(parsedQuery['role']);
    props.setType(parsedQuery['type']);
    props.setSpecType(parsedQuery['spectype']);
    if (parsedQuery['type'] !== 'custom') {
      props.setqType('random question');
      props.QuestionSelected(-1);
      props.setintQuestion(4);
    } else {
      props.setQs();
      props.setqType('choose question');
      props.setCustomQ(parsedQuery['specifics']);
      props.QuestionSelected(0);
      props.setintQuestion(3);
    }
    if (parsedQuery['type'] === 'domain') {
      props.setDomain(parsedQuery['specifics'])
    } else if (parsedQuery['type'] === 'jobDescriptionQ') {
        if(props.jobDescriptions) {
            props.setJobDescriptionQ({ title: parsedQuery['specifics'], description: props.jobDescriptions[parsedQuery['specifics']] })
        }
        else{
            console.log("No job descriptions found");
        }
    }

    props.next();
    navigate('/track');
  }

  const getTrack = (): string | null => {
    if (props.user && props.user.lastPath) {
      //console.log(props.user.lastPath);
      const parsedQuery1 = parseQuestionQuery(props.user.lastPath);
      setParsedQuery(parsedQuery1!);
      return parsedQuery1['role'] !== undefined && parsedQuery1['role'] !== '' ? parsedQuery1['role'] : null
    } else if (props.user && Object.keys(props.user.intakeForm).length > 8 && props.user.intakeForm['3'] !== 'undecided') {
      setParsedQuery({});
      return props.user.intakeForm['3'].toString();
    }
    return null;
  }
  //console.log(props.user);

  return (
    <div className="landing-container">
      <div className="landingPage">
                <div>
                <img src = {littleGuy} className="buddy" alt="buddy"/>
                </div>
                <div className={`speech-bubble ${!props.user? "welcome-noUser" : ""}`}>
                    <div>
                        <h4>Welcome{props.user ? `, ${props.user.name.split(' ')[0]}` : ''}</h4>
                    </div>
                    {props.user && <div className="landingIcons">
                        <i className="fas fa-fire" style={{color: "#e58124", fontSize:"x-large"}} />
                        <p className="ml-3">{props.user.streak !== undefined ? props.user.streak == 1 ? "1 day" : `${props.user.streak} days`  : '0 days'}</p>
                    </div>
                    }
                </div>
            </div>
            <div className="landingPage second-landing">
                
                <div className="landing-track">
                    {userTrack !== null && <h4>You are on the {capitalizeFirstLetter(userTrack)} Track!</h4>}
                    {userTrack === null && <h4>You have not started any track yet!</h4>}
                </div>
                <div className="landing-options">
                    {userTrack === null &&
                    <div className="landing-options-button" onClick={()=>navigate("/track")}>
                        <h4>Explore Roles and Interview Tracks </h4>
                    </div>
                    }
                    {userTrack !== null && Object.keys(parsedQuery).length == 0 &&
                    <>
                    <div className="landing-options-button" onClick={()=>{props.setRole(userTrack);props.setintQuestion(2);navigate("/track")}}>
                        <h4>Explore {capitalizeFirstLetter(userTrack)} Interview Tracks</h4>
                    </div>
                    <div className="landing-options-button mt-3" onClick={()=>navigate("/track")}>
                        <h4>Explore Other Roles and Interview Tracks</h4>
                    </div>
                    </>
                    }

                    {userTrack !== null && Object.keys(parsedQuery).length != 0 &&
                        <>
                            <div className="landing-options-button" onClick={()=>setContinueInterviewTrack()}>
                                <h4>Continue Practice in {capitalizeFirstLetter(getPackName(parsedQuery['role'], parsedQuery['type'], parsedQuery['spectype'], parsedQuery['specifics'], props.user!))} Pack</h4>
                            </div>
                            { props.user && props.user.lastInterviewCompleteQ && 
                            <div className="landing-options-button mt-3" onClick={()=>props.showLastFeedback(props.user && props.user.lastInterviewCompleteQ ? props.user.lastInterviewCompleteQ : "")}>
                                <h4>View Feedback from Last Interview</h4>
                            </div>
                            }
                            { hasMultipleQuestionPacks(parsedQuery['role'], parsedQuery['type']) && <div className="landing-options-button mt-3" onClick={()=>{props.setRole(parsedQuery['role']);props.setType( parsedQuery['type']);props.setintQuestion(4);navigate("/track")}}>
                                <h4>See More {capitalizeFirstLetter(parsedQuery['type'])} Question Packs</h4>
                            </div>}
                            <div className="landing-options-button mt-3" onClick={()=>navigate("/track")}>
                                <h4>Explore Other Roles and Interview Tracks</h4>
                            </div>
                        </>
                    }
                    {/* && props.user.intakeForm['10'] !== undefined &&  props.user.intakeForm['10'] ==="afrotech24" */}
                    {/* {props.user &&
                    <>
                      <div className="landing-options-button afrotech mt-3" onClick={()=>setAfrotechInterviewTrack(true)}>
                        <h4>Afrotech: Elevator Pitch</h4>
                      </div>
                      <div className="landing-options-button afrotech mt-3" onClick={()=>setAfrotechInterviewTrack(false)}>
                        <h4>Afrotech: Tech Byte</h4>
                      </div>
                    </>
                    } */}
                </div>     
            </div>
    </div>
  )
}

export default LandingPage;
