import React from 'react';
import ReactModal from 'react-modal';
import { LoginButton } from './signin';

interface SignInModalProps {
  showSignIn: boolean;
  togglesigninModal: () => void;
  canClosePopup: boolean;
}

const customStyles: ReactModal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: '60vw',
    transform: 'translate(-50%, -50%)',
    borderRadius: '1.5rem',
    backgroundColor: '#f4f4f5'
  },
};

const SignInModal: React.FC<SignInModalProps> = (props) => {
  return (
    <ReactModal
      isOpen={props.showSignIn}
      style={customStyles}
      shouldCloseOnOverlayClick={props.canClosePopup}
      shouldCloseOnEsc={props.canClosePopup}
      onRequestClose={props.togglesigninModal}
      ariaHideApp={false}
    >
      <div className="startsection scroll instructions">
        <LoginButton />
      </div>
    </ReactModal>
  );
}

export default SignInModal;
