import React, { useState, useEffect } from 'react';
import '../custom.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { useAppContext } from '../appContext';
import { getOrgQuestions, adminModifyOrgQuestions,adminGetMembers, adminGetMemberInfo} from '../api';
import {generateGUID, GetFriendlyOrgNames} from '../util'
import AdminCustomQuestionScreen from './adminCustomQs';
import AdminQuestionModal from './AdminQuestionModal';
import LoadingOverlay from './loadingOverlay';
import HistoryScreen from './HistoryScreen';
import EndScreenModal from './endScreenModal';
import AdminOrgTrend from './AdminOrgTrend';
import { Member, OrgQuestion, InterviewObject, AnsweredQuestions, OrgQuestions } from './types';
import Nav from './nav';

export default function Admin() {
    const { state, reducers } = useAppContext();
    const { setmemberFeedbackModal, setquestionModal, setloadingOverlayComp } = reducers;
    const [members, setMembers] = useState<Member[]>([]);
    const [membersInfo, setMembersInfo] = useState<{ [key: string]: AnsweredQuestions }>({});
    const [showMembers, setShowMembers] = useState(false);
    const [showQOps, setShowQOps] = useState(false);
    const [selectedMember, setSelectedMember] = useState(-1);
    const [selectedMemberInfo, setSelectedMemberInfo] = useState<{ [key: string]: InterviewObject[] }>({});
    const [currScreen, setCurrScreen] = useState("WELCOME");
    const [questions, setQuestions] = useState<{[key: string]: OrgQuestion[]}>({});
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [category, setCategory] = useState("");

    const EditQuestion = async (index: number, newq: string, rubric: string | null = null) => {
        setloadingOverlayComp(<LoadingOverlay loadingOverlay={true} />);
        EditQuestionAsync(index, newq, rubric);
    }

    const EditQuestionAsync = async (index: number, newq: string, rubric: string | null = null) => {
        if (questions[category]) {
            questions[category][index] = { question: newq, rubric: rubric };
            const newqs: {[key: string]: OrgQuestion[]} = await adminModifyOrgQuestions(category, questions[category]) || {};
            setQuestions(newqs);
            setloadingOverlayComp(null);
            setquestionModal(null);
        }
    }

    const AddQuestion = async (newq: string, rubric: string | null = null) => {
        setloadingOverlayComp(<LoadingOverlay loadingOverlay={true} />);
        AddQuestionAsync(newq, rubric);
    }

    const AddQuestionAsync = async (newq: string, rubric: string | null = null) => {
        if (questions[category] === undefined) {
            questions[category] = [];
        }
        questions[category].push({ question: newq, rubric: rubric });
        const newqs: {[key: string]: OrgQuestion[]} = await adminModifyOrgQuestions(category, questions[category]) || {};
        setQuestions(newqs);
        setloadingOverlayComp(null);
        setquestionModal(null);
    }

    const AddQuestions = async (array: [string, string | null][]) => {
        setloadingOverlayComp(<LoadingOverlay loadingOverlay={true} />);
        AddQuestionsAsync(array);
    }

    const AddQuestionsAsync = async (array: [string, string | null][]) => {
        if (questions[category] === undefined) {
            questions[category] = [];
        }
        for (let i = 0; i < array.length; i++) {
            let item = array[i];
            questions[category].push({ question: item[0], rubric: item[1] });
        }
        const newqs: {[key: string]: OrgQuestion[]} = await adminModifyOrgQuestions(category, questions[category]) || {};
        setQuestions(newqs);
        setloadingOverlayComp(null);
        setquestionModal(null);
    }

    const DeleteQuestion = async (index: number) => {
        setloadingOverlayComp(<LoadingOverlay loadingOverlay={true} />);
        DeleteQuestionAsync(index);
    }

    const DeleteQuestionAsync = async (index: number) => {
        if (questions[category]) {
            questions[category].splice(index, 1);
            const newqs: {[key: string]: OrgQuestion[]} = await adminModifyOrgQuestions(category, questions[category]) || {};
            setQuestions(newqs);
            setloadingOverlayComp(null);
        }
    }

    const setqMod = () => {
        setquestionModal(
            <AdminQuestionModal
                showQuestionMod={true}
                toggleQuestionModal={() => setquestionModal(null)}
                question={''}
                rubric={''}
                selectedInd={-1}
                EditQuestion={(v, e, r) => EditQuestion(v, e, r)}
                AddQuestion={(v, e) => AddQuestion(v, e)}
                AddQuestions={(array) => AddQuestions(array)}
                setcategory={(e) => setCategory(e)}
                category={category}
            />
        );
    }

    const AttemptedQuestionSelected = (question: string, i: number | null = null) => {
        let q = selectedMemberInfo[question];
        if (q) {
            let ind = i === null ? q.length - 1 : i;
            let info = q[ind];
            setmemberFeedbackModal(
                <EndScreenModal
                    question={question}
                    content={info["feedback"]}
                    showFeedback={true}
                    showTranscript={true}
                    promptArray={info["transcript"]}
                    qLevel={null}
                    displayPastFeedback={true}
                    action={""}
                    adminView={true}
                    memberEmail={selectedMember > -1 ? members[selectedMember].name : ''}
                    toggleFeedbackModal={() => setmemberFeedbackModal(null)}
                />
            );
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const mem = await adminGetMembers() || [];
            const q = await getOrgQuestions() || {};
            setQuestions(q);
            setMembers(mem);
            const promises = mem?.map(m => adminGetMemberInfo(m.email));
            const responses = await Promise.all(promises);
            let obj: { [key: string]: AnsweredQuestions } = {};
            for (let i = 0; i < mem.length; i++) {
                obj[mem[i].email] = responses[i]??{};
            }
            setMembersInfo(obj);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedMember > -1) {
            const memInfo = membersInfo[members[selectedMember].email];
            setSelectedMemberInfo(memInfo !== null ? memInfo : {});
            setCurrScreen("MEMBER");
            setCategory("");
        }
    }, [selectedMember]);

    useEffect(() => {
        if (category !== "") {
            setCurrScreen("CUSTOMQUESTIONS");
            setSelectedMember(-1);
        }
        if (state.questionModal !== null) {
            setqMod();
        }
    }, [category]);

    useEffect(() => {
        if (currScreen === "WELCOME") {
            setSelectedMember(-1);
            setCategory("");
        }
    }, [currScreen]);

    return (
        <>
         <Nav/>
            {!state.profileUser &&
                <p className='mt-5'>Sign in with admin account to get access</p>
            }
            {state.profileUser && !state.profileUser.isAdmin && <p className='mt-5'>You do not have admin access</p>}

            {state.profileUser && state.profileUser.isAdmin &&
                <div className='adminScreen'>
                    <div id="scrollingDiv" className={`adminSidebar`}>
                        <h4 className='text-left'>Admin Actions</h4>
                        <div className='adminMenu'>
                            <div onClick={() => setCurrScreen("WELCOME")} className='menuitem'>
                                <p>Overview</p>
                            </div>
                            <div onClick={() => setShowMembers(!showMembers)} className='menuitem'>
                                <p>Members</p>
                            </div>
                            <div className={`adminMenu ${showMembers ? '' : 'noDisp'}`}>
                                {members.map((x, i) => {
                                    return <div key={generateGUID()} onClick={() => setSelectedMember(i)} className={`menuitem ${selectedMember === i ? 'selectedmenuitem' : ''}`}><p>{x.name}</p></div>
                                })}
                            </div>
                            <div onClick={() => setShowQOps(!showQOps)} className='menuitem'>
                                <p>Questions</p>
                            </div>
                            <div className={`adminMenu ${showQOps ? '' : 'noDisp'}`}>
                                {Object.keys(questions).map((x) => {
                                    return <div key={generateGUID()} onClick={() => setCategory(x)} className={`menuitem ${category === x ? 'selectedmenuitem' : ''}`}><p>{x}</p></div>
                                })}
                                <div onClick={() => { setCategory(""); setqMod() }} className='menuitem add-button'><p>Add Question</p></div>
                            </div>
                        </div>
                    </div>
                    <div className='adminContent'>
                        {currScreen === "WELCOME" &&
                            <AdminOrgTrend
                                organization={GetFriendlyOrgNames(state.profileUser.organization)}
                                membersInfo={membersInfo}
                            />
                        }
                        {currScreen === "MEMBER" &&
                            <HistoryScreen
                                adminView={true}
                                questions={selectedMemberInfo}
                                AttemptedQuestionSelected={(e, i) => AttemptedQuestionSelected(e, i)}
                            />
                        }

                        {currScreen === "CUSTOMQUESTIONS" &&
                            <AdminCustomQuestionScreen
                                setquestionModal={(e) => setquestionModal(e)}
                                setloadingOverlay={(v) => setloadingOverlayComp(v)}
                                questions={category !== "" && questions.hasOwnProperty(category) ? questions[category] : []}
                                EditQuestion={(index, newq, rubric) => EditQuestion(index, newq, rubric)}
                                DeleteQuestion={(newq) => DeleteQuestion(newq)}
                                AddQuestion={(newq, rubric) => AddQuestion(newq, rubric)}
                                AddQuestions={(arr) => AddQuestions(arr)}
                                category={category}
                                setcategory={(e) => setCategory(e)}
                            />
                        }
                    </div>
                </div>}
        </>
    );
}
