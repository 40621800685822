import React from 'react';
import ReactModal from 'react-modal';

interface NextConfirmationProps {
  showNextConfirmation: boolean;
  toggleNextConfirmation: (show: boolean) => void;
  GetFeedback: () => void;
  Next: () => void;
}

const NextConfirmation: React.FC<NextConfirmationProps> = (props) => {
  const customStyles: ReactModal.Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      maxWidth: '60vw',
      transform: 'translate(-50%, -50%)',
      borderRadius: '0.2rem',
      backgroundColor: '#f4f4f5'
    },
  };

  return (
    <ReactModal
      isOpen={props.showNextConfirmation}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => props.toggleNextConfirmation(false)}
      ariaHideApp={false}
    >
      <div className="startsection conf instructions">
        <h2>Are you sure you want to leave this page and lose your progress?</h2>
        <div className="flex-button flex-button-conf">
          <button className="formexperiencebuttonbeige" onClick={props.GetFeedback}><b>Get Feedback on This Question</b></button>
          <button className="formexperiencebuttonbeige" onClick={props.Next}><b>Continue to Leave Page</b></button>
        </div>
      </div>
    </ReactModal>
  );
}

export default NextConfirmation;
