import React, { useRef, useState } from 'react';
import { read, utils } from 'xlsx';

interface ExcelReaderProps {
  updateExcel: (data: (string | number)[][]) => void;
}

const ExcelReader: React.FC<ExcelReaderProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      handleFileUpload(event.target.files[0]);
    }
  };

  function handleFileUpload(file: File) {
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e: ProgressEvent<FileReader>) {
        const data = e.target?.result;
        if (data instanceof ArrayBuffer) {
          const workbook = read(data, { type: 'array' });

          // Assuming there is only one sheet in the workbook
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          // Convert the sheet to JSON
          const jsonData = utils.sheet_to_json(sheet, { header: 1 }) as (string | number)[][];
          props.updateExcel(jsonData);
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  return (
    <div className="form-group mt-10">
      <div className="question">
        <p><b>Upload your excel sheet</b></p>
      </div>
      <div className="choices mt-1 pdfUpload">
        <button className="upload-button mr-3" onClick={handleUploadClick}>
          Select File
        </button>
        <p>{selectedFile ? `${selectedFile.name}` : 'No file selected'}</p>
        <input type="file" ref={inputRef} onChange={handleFileChange} accept=".xlsx" />
      </div>
    </div>
  );
};

export default ExcelReader;
