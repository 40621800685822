import { updateFeedbackAccessibility } from "../api";
import { useAppContext } from "../appContext";
import { hasPremSub } from "../util";
import EndScreen from "./endScreen";
import Loading from "react-loading";
import Nav from "./nav";

const Feedback = () => {
  const {
    state,
    reducers,
    startFunc,
    pickQuestion,
    navigateThenRun,
    getFeedback,
    getAnswer,
    getAction,
    setQs,
  } = useAppContext();

  const {
    setrole,
    settype,
    setqType,
    setcurrentQuestion,
    setretryPast,
    setprofileUser,
    setpastInfo,
    setshowPaymentModal,
    setisHelpful,
    setisHelpfulMessage,
    setprivateSession,
  } = reducers;

  const updateAccessibleToOrg = async (e: boolean): Promise<void> => {
    const access = state.pastInfo["orgAccessible"];
    if (access === undefined || access !== e) {
      const user2 = await updateFeedbackAccessibility(
        e,
        state.displayPastFeedback,
        state.pastInfo["index"]
      );
      setprofileUser(user2);
      setpastInfo({ ...state.pastInfo, orgAccessible: e });
    }
  };

  if (state.loading && !state.showAns) {
    return (
      <>
        <div className="loading2">
          <h3
            className={
              document.body.style.backgroundColor !== "black" ? "blackText" : ""
            }
          >
            {state.showFeedback
              ? "Gathering your feedback"
              : "Fetching the questions to begin your interview"}
          </h3>
          <Loading
            type="bubbles"
            color={
              document.body.style.backgroundColor !== "black"
                ? "#000000"
                : "#FFFFFF"
            }
            width={100}
            height={"auto"}
          />
        </div>
      </>
    );
  }

  return (
    <>
    <Nav/>
    <EndScreen
      nextClick={() => startFunc(true, false)}
      retryClick={() => startFunc(false, false)}
      question={
        state.displayPastFeedback !== ""
          ? state.displayPastFeedback
          : state.specType === "afrotech-behavioral"
          ? "Tell me about yourself."
          : state.specType === "afrotech-technical"
          ? "The animal shelter just got a new litter of puppies! We can't wait for them to be adopted. What is the best way for the shelter to store the data on the puppies?"
          : state.questions[state.currentQuestion]
      }
      content={
        state.showAns
          ? state.qA
          : state.showAction
          ? state.action
          : state.feedback
      }
      hasPremiumSub={hasPremSub(state.profileUser)}
      showPaymentModal={() => setshowPaymentModal(true)}
      getAns={() => getAnswer()}
      getAction={() => getAction()}
      getFeed={() => getFeedback()}
      showAns={state.showAns}
      showFeedback={state.showFeedback}
      showAction={state.showAction}
      loading={state.loading}
      canNext={state.type !== "custom" && state.next}
      updateIsHelpful={(e) => setisHelpful(e)}
      updateIsHelpfulMessage={(e) => setisHelpfulMessage(e)}
      // previousClick={() => startFunc(false, true)}
      showTranscript={state.showTranscript}
      // canPrevious={state.previous}
      promptArray={state.prompt}
      isHelpful={state.isHelpful}
      isHelpfulMessage={state.isHelpfulMessage}
      pickNext={() => pickQuestion(true)}
      privateSession={state.privateSession}
      setprivateSession={(e) => setprivateSession(e)}
      inOrg={
        state.profileUser &&
        state.profileUser.organization !== undefined &&
        state.profileUser.organization !== ""
          ? true
          : false
      }
      canPickNext={state.type !== "custom"}
      qLevel={
        Object.keys(state.questionLevelMap).length !== 0
          ? state.questionLevelMap[state.questions[state.currentQuestion]]
          : null
      }
      displayPastFeedback={state.displayPastFeedback !== ""}
      action={state.action}
      isRetryPast={state.retryPast}
      retryPast={() => {
        navigateThenRun(() => {
          setrole(
            state.profileUser!.answeredQuestions![state.displayPastFeedback][0]
              .role
          );
          settype("custom");
          setQs(null, state.displayPastFeedback);
          setqType("choose question");
          setcurrentQuestion(0);
          setretryPast(true);
        }, "/interview");
      }}
      accessibleToOrg={state.pastInfo["orgAccessible"]}
      updateAccessibleToOrg={(e) => updateAccessibleToOrg(e)}
      hasFeedback={state.prompt.length !== 0}
    />
    </>
  );
};

export default Feedback;
