import axios from 'axios';
import Cookie from 'universal-cookie';
import {getCookie, getTimestamp} from './util'
import { User, GPTResponse, Member, AnsweredQuestions, OrgQuestions, FeedbackUpdate, JobDescription, CheckoutSession, OrgQuestion } from './components/types';

let host = 'https://devdifferencefuncs.azurewebsites.net/api';
//let host = 'http://localhost:7071/api';




export async function getTokenOrRefresh(): Promise<{ authToken: string | null; region?: string; error?: any }> {
  const speechToken = getCookie('speech-token');

  if (speechToken === null) {
    try {
      const res = await axios.get(`${host}/get-speech-token`);
      const token = res.data.token;
      const region = res.data.region;
      const cookie = new Cookie();
      cookie.set('speech-token', region + ':' + token, { maxAge: 540, path: '/' });

      return { authToken: token, region: region };
    } catch (err: any) {
      console.log(err.response.data);
      return { authToken: null, error: err.response.data };
    }
  } else {
    const idx = speechToken.indexOf(':');
    return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
  }
}

function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function getGPTResponse(promptArr: GPTResponse[], showError: (show: boolean) => void): Promise<string> {
  const bearerToken = getCookie('oidToken');
  const data = JSON.stringify({ prompt: promptArr });
  const config = {
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}` }
  };
  let res = await axios.post(`${host}/get-gpt-response`, data, config);
  while (res.status !== 200) {
    showError(true);
    await sleep(3000);
    showError(false);
    res = await axios.post(`${host}/get-gpt-response`, data, config);
  }

  return res.data.message;
}

export async function tt(): Promise<void> {
  axios.get(`http://localhost:7071/api/update-criteria`);
}

export async function getGPTResponseServer(promptComp: any, showError: (show: boolean) => void): Promise<any> {
  const bearerToken = getCookie('oidToken');
  const data = JSON.stringify({ promptComponents: promptComp });
  const config = {
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}` }
  };
  let res = await axios.post(`${host}/get-gpt-response-server`, data, config);
  while (res.status !== 200) {
    showError(true);
    await sleep(3000);
    showError(false);
    res = await axios.post(`${host}/get-gpt-response-server`, data, config);
  }

  return promptComp["gptCallType"] === "feedback" ? res.data : res.data.message;
}

export async function getQuestionsDatabase(type: string): Promise<string[]> {
  const res = await axios.get(`${host}/get-questions?type=${type}`);
  return res.data.message;
}

export async function getUser(email: string, name: string, email_verified: boolean): Promise<User | null> {
  const bearerToken = getCookie('oidToken');
  const data = JSON.stringify({ email: email, name: name, email_verified: email_verified, timestamp: getTimestamp() });
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearerToken}`
    }
  };
  const res = await axios.post(`${host}/get-user`, data, config);
  return res.data.user && Object.keys(res.data.user).length > 0 ? res.data.user : null;
}

export async function adminGetMembers(): Promise<Member[] | null> {
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({});
    const config = {
        headers: {'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/get-members-admin`, data, config);
    return res.data.members;
}

export async function adminGetMemberInfo(email:string): Promise<AnsweredQuestions | null> {
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({memberEmail:email});
    const config = {
        headers: {'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/get-member-info-admin`, data, config);
    return res.data.memberInfo;
}

export async function getOrgQuestions(): Promise<OrgQuestions | null> {
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({});
    const config = {
        headers: {'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/get-org-questions`, data, config);
    return res.data.orgQuestions;
}


export async function adminModifyOrgQuestions(type: string, qArr: any) : Promise<{[key: string]: OrgQuestion[]} | null> {
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({type, qInfoArray:qArr});
    const config = {
        headers: {'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/modify-questions-admin`, data, config);
    return res.data.orgQuestions;
}

export async function uploadUserResume(text: string, file: string) : Promise<User | null>{
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({resumetext: text, resumename:file, type:"resume"});
    const config = {
        headers: {'Content-Type': 'application/json','Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/update-user`, data, config);
    return res.data.user && Object.keys(res.data.user).length > 0 ? res.data.user : null;;
}

export async function checkoutWithStripe(productId: string): Promise<CheckoutSession | null>{
    const data = JSON.stringify({PRICE_ID:productId});
    const bearerToken = getCookie('oidToken');
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/stripe-checkout-session`, data, config);
    return res.data;
}

export async function registerPaidUser(id: string) : Promise<User | null> {
    const data = JSON.stringify({sessionId:id});
    const bearerToken = getCookie('oidToken');
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/register-paid-user`, data, config);
    return res.data.user && Object.keys(res.data.user).length > 0 ? res.data.user : null;;
}

export async function GetPortalLink() : Promise<string | null> {
    const data = JSON.stringify({});
    const bearerToken = getCookie('oidToken');
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/create-portal-session`, data, config);
    return res.data.redirectUrl;
}

export async function updateUserVerification(): Promise<User | null>{
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({type:"email_verification"});
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/update-user`, data, config);
    return res.data.user && Object.keys(res.data.user).length > 0 ? res.data.user : null;;
}

export async function updateAudio(audio: boolean): Promise<User | null>{
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({audioEnabled:audio, type:"audio"});
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/update-user`, data, config);
    return res.data.user && Object.keys(res.data.user).length > 0 ? res.data.user : null;;
}

export async function updateFeedbackAccessibility(accessibility: boolean, question: string, index: number): Promise<User | null>{
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({accessibility, question, index, type:"feedbackAccessibility"});
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/update-user`, data, config);
    return res.data.user && Object.keys(res.data.user).length > 0 ? res.data.user : null;;
}

export async function updateUserResumeQuestions(questions: string[]): Promise<User | null>{
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({resumeQuestions:questions, type:"resumeQuestions"});
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/update-user`, data, config);
    return res.data.user && Object.keys(res.data.user).length > 0 ? res.data.user : null;;
}

export async function resendVerificationEmail(email: string): Promise<number>{
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({email:email});
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/resend-verification`, data, config);
    return res.status;
}

export async function updateIntakeForm(info: FormData): Promise<User | null>{
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({ info:info, type:"intakeForm"});
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/update-user`, data, config);
    return res.data.user && Object.keys(res.data.user).length > 0 ? res.data.user : null;;
}

export async function updateJobDescriptions(info: JobDescription): Promise<User | null>{
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({ JobDescriptions:info, type:"JobDescriptions"});
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/update-user`, data, config);
    return res.data.user && Object.keys(res.data.user).length > 0 ? res.data.user : null;;
}

export async function updateUserAnsweredQuestions(question: string, qInfo: FeedbackUpdate): Promise<User | null>{
    const bearerToken = getCookie('oidToken');
    const data = JSON.stringify({type:"answeredQuestionUpdate", qInfo: qInfo, question: question});
    const config = {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${bearerToken}`}
    };
    const res = await axios.post(`${host}/update-user`, data, config);
    return res.data.user && Object.keys(res.data.user).length > 0 ? res.data.user : null;;
}
