import React, { useEffect, useState } from 'react';
import Loading from "react-loading";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { cpp } from '@codemirror/lang-cpp';
import { java } from '@codemirror/lang-java';
import { python } from '@codemirror/lang-python';
// import { csharp } from '@replit/codemirror-lang-csharp'; //causing issues when used
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk';
import { sql } from '@codemirror/lang-sql';
import { markdown } from '@codemirror/lang-markdown';
import Transcript from './transcript';
import { GetQuestionDisplay } from '../util';
import { Extension } from '@codemirror/state';
import { useAppContext } from '../appContext';
import { getTokenOrRefresh } from '../api';
import Nav from './nav';

const langdict: { [key: string]: Extension } = {
  'javascript': javascript(),
  'cpp': cpp(),
  'java': java(),
  'python': python(),
  // 'csharp': csharp(),
  // 'sql': sql(),
  'markdown': markdown()
}

function InterviewScreen() {
  const { state, reducers, navigateThenRun, stopAudio, getFeedback, synthesizeSpeech, pickQuestion, startFunc } = useAppContext();
  const {setrecognizedsp,
    setinterviewer,
    setmicOn,
    setrecognizedspTemp,
    setprompt,
    setprocessing,
    setcode,
    setcodingLang,
    setshowNextConfirmation,
    setshowTranscript,
    setshowApiError,
    setErrorText,
    setsessionStopped,
    setspeechLen,
    setNextFunc,
    setcontextLength,
    setuseWhiteboard} = reducers;

  const [showInteractScreen, setshowInteractScreen] = useState<boolean>(state.specType.startsWith("afrotech")); 
  const [recognizer, setrecognizer] = useState<any>(null); 
  let micTimeout: NodeJS.Timeout | null = null; 
  const MAXCALLS = 12;

  const stopMic = (): void => {
    if (state.micOn === true) {
        if (micTimeout) {
            clearTimeout(micTimeout);
            micTimeout = null;
        }
        setmicOn(false);
        if(recognizer){
          recognizer.stopContinuousRecognitionAsync();
        }
        setprocessing(true);
    }
}

const sttFromMic = (): void => {
    setrecognizedsp("");
    setmicOn(true);
    setsessionStopped(false);
}

const handleOptionClicked = async (userresp: string, resp:string) => {
  if(!state.abortController.signal.aborted ) {
      let prompt1 = [...state.prompt];
      prompt1.push({"role": "user", "content": userresp});
      setinterviewer(resp);
      prompt1.push({ role: 'assistant',  "content": resp});
      setprompt(prompt1);
      setcontextLength(state.contextLength + resp.split(' ').length + userresp.split(' ').length);
      setshowTranscript(true);
      synthesizeSpeech(resp);
  }
};

const  previousClick = () => {
  if (state.recognizedsp === ""){
      startFunc(false,true)
  } 
  else{
      setNextFunc(()=>{
          startFunc(false,true)
      });
      setshowNextConfirmation(true);
  } 
}

const showTranscriptBar = (): void => {
  setshowTranscript(!state.showTranscript);
};


const canShowTransButton = (): boolean => {
  return ( state.start && !state.loading);
};

const nextClick= () => {
  if (state.recognizedsp === ""){
      if (state.qType !== "choose question"){
          startFunc(true, false);
      }
      else{
          pickQuestion();
      }
   }
   else{ 
      setNextFunc(()=>{
          if (state.qType !== "choose question"){
              startFunc(true, false);
          }
          else{
              pickQuestion();
          }
      });
      setshowNextConfirmation(true)
  }
}

useEffect(() => {
  if(state.hitMaxLen && state.micOn){
      setErrorText("You are nearing the maximum word limit for the interview. Auto muting your mic in 20 seconds.");
      setshowApiError(true);
      micTimeout = setTimeout(() => stopMic(), 20000);
      return () => {
          if (micTimeout) {
              clearTimeout(micTimeout);
          }
      };
  }
}, [state.hitMaxLen]);

useEffect(() => {
  const fetchData = async () => {
  if(state.initialLoad){
    window.location.href = "/";
  }
  else{
    const tokenObj = await getTokenOrRefresh();
    if (tokenObj.authToken && tokenObj.region) {   
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        speechConfig.speechRecognitionLanguage = 'en-US';

        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        setrecognizer(new speechsdk.SpeechRecognizer(speechConfig, audioConfig));
    }
  }
}
fetchData();
}, []);

useEffect(() => {
  if(state.GPTCallCount === MAXCALLS - 1) {
      setErrorText("You have 1 more correspondence left.");
      setshowApiError(true);
  }
}, [state.GPTCallCount]);

useEffect(() => {
  const setupSpeechRecognition = async () => {
      if(state.micOn) {
          if (!state.abortController.signal.aborted) {  
              recognizer.startContinuousRecognitionAsync();
              recognizer.recognized = (s: any, e: any) => {
                  if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
                      setrecognizedspTemp(e.result.text);
                  }
              };
              recognizer.sessionStopped = async (s: any, e: any) => {
                  setsessionStopped(true);
              };
          }
      }
      else{
          setspeechLen(state.contextLength);
          //sethitMaxLen(false);
          setshowApiError(false);
          setErrorText("Encountered a problem reaching the api, retrying in 3 seconds...");
      }
  };
  setupSpeechRecognition();
}, [state.micOn]);

  useEffect(() => {
    const scrollingDiv = document.getElementById("scrollingDiv");
    if (scrollingDiv !== null) {
      scrollingDiv.scrollTop = scrollingDiv.scrollHeight;
    }
  }, []);

  const classNameT = state.showTranscript ? "startsection2 main" : "startsection2 noTranscript";
  document.body.style.backgroundColor = 'black';
  let questionFrags;
  if(state.specType.startsWith("afrotech")){
    if(state.specType === "afrotech-behavioral")
    {
      questionFrags = GetQuestionDisplay("Tell me about yourself.");
    }
    else{
      questionFrags = GetQuestionDisplay("The animal shelter just got a new litter of puppies! We can't wait for them to be adopted. What is the best way for the shelter to store the data on the puppies?");
    }
  } else {
    questionFrags = GetQuestionDisplay(state.questions.length >0  &&state.currentQuestion >=0 ? state.questions[state.currentQuestion].replace(/\\n/, '') : "",  Object.keys(state.questionLevelMap).length !== 0 ? state.questionLevelMap[state.questions[state.currentQuestion]] : null);
  }

  return(
    <>
    <Nav/>
    <div
            className={`rotated-box ${
              state.feedback !== "" ? "black" : "beige"
            } ${!state.showTranscript && canShowTransButton() ? "" : "noDisp"}`}
            onClick={() => showTranscriptBar()}
          >
            Transcript
          </div>

          <div
            className={`box-arrow ${
              state.showTranscript && canShowTransButton() ? "" : "noDisp"
            }`}
            onClick={() => showTranscriptBar()}
          >
            <i className="fas fa-chevron-right"></i>
          </div>
    {(state.loading || state.initialLoad) &&
    <div className="loading2">
        <h3 className={ document.body.style.backgroundColor !== "black" ?"blackText":""}>{state.initialLoad? '' : 'Fetching the questions to begin your interview'}</h3>
        <Loading
        type="bubbles" color={ document.body.style.backgroundColor !== "black" ?"#000000":"#FFFFFF"}
        width={100}
        height={'auto'} />
       
    </div>}
  {!state.loading && !state.initialLoad && showInteractScreen &&
      <div className="wrapper blackcolor">
        {state.showTranscript &&
          <Transcript promptArray={ state.prompt}
            noBottom={state.qType === "choose question"} />}

        <div className={classNameT}>
          <div className="title p-2">
            <h2>{questionFrags}</h2>
            <br/>
            <br/>
            <h4 className='lessWhite'>Select a framework to get started</h4>
          </div>
          {state.specType === "afrotech-behavioral" && <div className="interview-row">
            <div className="interview-options-button-col" onClick={()=>{handleOptionClicked("I choose this framework: ```Framework: Present - Past - Future\nPresent: Your current role and main responsibilities.\nPast: Relevant experience that led you here.\nFuture: Why you're interested in a particular role.```", "Great, now using this framework, give me your 30 second elevator pitch!");setshowInteractScreen(false);}}>
                <h4>Framework: Present - Past - Future</h4>
                <p>Present: Your current role and main responsibilities.</p>
                <p>Past: Relevant experience that led you here.</p>
                <p>Future: Why you're interested in a particular role.</p>
            </div>
            <div className="interview-options-button-col" onClick={()=>{handleOptionClicked("I choose this framework: ```Framework: Personal Details - Hobbies - Casual Experience\nPersonal Details: Where you're from, birthday.\nHobbies: Non-work interests.\nCasual Experience: Unrelated past jobs.```", "This framework is less effective because it shifts the focus away from your qualifications and lacks relevance to the role, leaving the interviewer without a clear picture of your professional capabilities or how you'd add value to the company. Stick to frameworks that highlight your skills, experience, and motivation for the role instead. Now select another framework.")}}>
                <h4>Framework: Personal Details - Hobbies - Casual Experience</h4>
                <p>Personal Details: Where you're from, birthday.</p>
                <p>Hobbies: Non-work interests.</p>
                <p>Casual Experience: Unrelated past jobs.</p>
            </div>
            <div className="interview-options-button-col" onClick={()=>{handleOptionClicked("I choose this framework: ```Framework : Passion - Journey - Purpose\nPassion: What drives you in your work.\nJourney: Key experiences that shaped your path.\nPurpose: Why this role aligns with your goals.```", "Great, now using this framework, give me your 30 second elevator pitch!"); setshowInteractScreen(false);}}>
              <h4>Framework : Passion - Journey - Purpose</h4>
                <p>Passion: What drives you in your work.</p>
                <p>Journey: Key experiences that shaped your path.</p>
                <p>Purpose: Why this role aligns with your goals.</p>
            </div>
          </div>}
          {state.specType === "afrotech-technical" && <div className="interview-row">
            <div className="interview-options-button" onClick={()=>{handleOptionClicked("I'll choose a list", "Okay! Can you explain why you think a list will be the best way to store data on the puppies?"); setshowInteractScreen(false);}}>
                <h4>List</h4>
            </div>
            <div className="interview-options-button" onClick={()=>{handleOptionClicked("I'll choose a stack", "Okay! Can you explain why you think a stack will be the best way to store data on the puppies?");setshowInteractScreen(false);}}>
                <h4>Stack</h4>
            </div>
            <div className="interview-options-button" onClick={()=>{handleOptionClicked("I'll choose a hashtable", "Okay! Can you explain why you think a hashtable will be the best way to store data on the puppies?");setshowInteractScreen(false);}}>
                <h4>Hashtable</h4>
            </div>
          </div>}
          <div className="flex navBut">
            <div className="flex-button">
              {state.canStop ? <button className="formexperiencebuttonbeige" onClick={()=>stopAudio()}>Stop Audio</button> : <></>}
            </div>
          </div>
        </div>
      </div>
          }
          
  {!state.loading && !showInteractScreen && (state.specType === 'coding' || state.specType === 'system design' || state.specType === 'product design' || state.specType=='estimation' || state.useWhiteboard) &&
      <div className="wrapper blackcolor">
        {state.showTranscript &&
          <Transcript promptArray={ state.prompt}
            noBottom={state.qType === "choose question"} />}
        <div className={classNameT}>
          <div className="title p-2">
            <h2>{questionFrags}</h2>
            {state.useWhiteboard && <div className="thumbsCol">
              <div className="radio-choice3">
                <input className="privateInput" onChange={(e) => setuseWhiteboard(false)} checked={true} type="checkbox" id="useWhiteboard" />
                <p>Use Whiteboard</p>
              </div>
            </div>}
            {state.specType === 'coding'  &&
              <div className="dropdown">
                <select id="dropdown" value={state.codingLang} onChange={(e) => setcodingLang(e.target.value)}>
                  <option value="cpp">C++</option>
                  <option value="c#">C#</option>
                  <option value="java">Java</option>
                  <option value="javascript">Javascript</option>
                  <option value="markdown">Markdown</option>
                  <option value="python">Python</option>
                  <option value="sql">SQL</option>
                </select>
              </div>}
            <CodeMirror
              value={state.code}
              height='55vh'
              width='60vw'
              extensions={[langdict[state.codingLang]]}
              onChange={(value, viewUpdate) => setcode(value)}
            />
          </div>
          <div className="flex navBut">
            {state.processing || state.loading ? <div className="loading">
              <Loading
                type="bubbles" color="#FFFFFF"
                height={70} width={35} />
            </div> : <></>}
            <div className="flex-button">
              {state.canStop ? <button className="formexperiencebuttonbeige" onClick={()=>stopAudio()}>Stop Audio</button> : <></>}
              {state.processing || state.loading || state.canStop || !(state.GPTCallCount !== MAXCALLS && state.hitMaxLen === false) ? <></> : <div className="micButton" onClick={()=>state.micOn ? stopMic(): sttFromMic()}><i className={state.micOn ? "fas fa-microphone blackText" : "fas fa-microphone-slash"} /><span className={"whiteText"}>{!state.micOn ? 'Unmute to start' : 'Mute to finish'}</span> </div>}
              {!state.processing && !state.loading && !state.micOn && !state.canStop ? <button className="formexperiencebuttonbeige" onClick={()=>{navigateThenRun(()=>getFeedback(), '/feedback')}} > Done! </button> : <></>}
            </div>
          </div>
          {!state.retryPast && !state.specType.startsWith("afrotech") && <div className="sticky-bar">
            {!state.micOn && (state.qType !== "choose question" && state.previous) && !state.processing && !state.loading && !state.micOn ? <div className="flex-col"><i onClick={() => { previousClick() }} title="previous question" className="fas fa-circle-arrow-left"></i>Previous Question</div> : <i className="fa-arrow-left hidden" />}
            {!state.micOn && (state.next || state.qType === "choose question") && !state.processing && !state.loading && !state.micOn ? <div className="flex-col"><i onClick={() => { nextClick() }} title="next question" className="fas fa-circle-arrow-right"></i> Next Question </div> : <i className="fa-arrow-right hidden" />}
          </div>}
        </div>
      </div>
  }
  {!state.loading && !showInteractScreen && !(state.specType === 'coding' || state.specType === 'system design' || state.specType === 'product design' || state.specType=='estimation' || state.useWhiteboard) &&
      <div className="wrapper blackcolor">
        {state.showTranscript &&
          <Transcript promptArray={state.prompt}
            noBottom={state.qType === "choose question"} />}

        <div className={classNameT}>
          <div className="title p-2">
            <h2>{questionFrags}</h2>
            {(state.type === "custom" || state.type==="org" || state.type ==='domain' || state.type==='technical') && <div className="thumbsCol">
              <div className="radio-choice3">
                <input className="privateInput" checked={false} onChange={(e) => setuseWhiteboard(true)} type="checkbox" id="useWhiteboard" />
                <p>Use Whiteboard</p>
              </div>
            </div>}

          </div>
          <div className="flex navBut">
            {state.processing || state.loading ? <div className="loading">
              <Loading
                type="bubbles" color="#FFFFFF"
                height={70} width={35} />
            </div> : <></>}
            <div className="flex-button">
              {state.canStop ? <button className="formexperiencebuttonbeige" onClick={()=>stopAudio()}>Stop Audio</button> : <></>}
              {state.processing || state.loading || state.canStop || (!(state.GPTCallCount !== MAXCALLS && state.hitMaxLen === false) && !state.micOn) ? <></> : <div className="micButton" onClick={()=>state.micOn ? stopMic(): sttFromMic()}><i className={state.micOn ? "fas fa-microphone blackText" : "fas fa-microphone-slash"} /><span className={"whiteText"}>{!state.micOn ? 'Unmute to start' : 'Mute to finish'}</span> </div>}
              {!state.processing && !state.loading && !state.micOn && !state.canStop ? <button className="formexperiencebuttonbeige" onClick={()=>{navigateThenRun(()=>getFeedback(), '/feedback')}}>Done! </button> : <></>}
            </div>
          </div>
          {!state.retryPast && !state.specType.startsWith("afrotech") && <div className="sticky-bar">
            {!state.micOn && (state.qType !== "choose question" && state.previous) && !state.processing && !state.loading && !state.micOn ? <div className="flex-col"><i onClick={()=>previousClick()} title="previous question" className="fas fa-circle-arrow-left"></i>Previous Question</div> : <i className="fa-arrow-left hidden" />}
            {!state.micOn && (state.next || state.qType === "choose question") && !state.processing && !state.loading && !state.micOn ? <div className="flex-col"><i onClick={()=>nextClick()} title="next question" className="fas fa-circle-arrow-right"></i>Next Question </div> : <i className="fa-arrow-right hidden" />}
          </div>}
        </div>
      </div>
      }
      
      </>
    )
}

export default InterviewScreen;
