import React, { useState, useEffect } from 'react';
import '../custom.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { getOrgQuestions, adminModifyOrgQuestions } from '../api';
import AdminQuestionModal from './AdminQuestionModal';
import { OrgQuestion } from './types';

interface AdminCustomQuestionScreenProps {
  setquestionModal: (modal: React.ReactNode) => void;
  questions: OrgQuestion[];
  EditQuestion: (v: number, e: string, r: string | null) => void;
  AddQuestion: (v: string, e: string | null) => void;
  AddQuestions: (array: [string, string | null][]) => void;
  DeleteQuestion: (index: number) => void;
  category: string;
  setcategory: (category: string) => void;
  setloadingOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AdminCustomQuestionScreen(props: AdminCustomQuestionScreenProps) {
  const [selectedQuestion, setSelectedQuestion] = useState(-1);

  const setqMod = () => {
    props.setquestionModal(
      <AdminQuestionModal
        showQuestionMod={true}
        toggleQuestionModal={() => props.setquestionModal(null)}
        question={selectedQuestion !== -1 ? props.questions[selectedQuestion].question : ''}
        rubric={selectedQuestion !== -1 && props.questions[selectedQuestion].rubric ? props.questions[selectedQuestion].rubric : ''}
        selectedInd={selectedQuestion}
        EditQuestion={(v, e, r) => props.EditQuestion(v, e, r)}
        AddQuestion={(v, e) => props.AddQuestion(v, e)}
        AddQuestions={(array) => props.AddQuestions(array)}
        setcategory={(e) => props.setcategory(e)}
        category={props.category}
      />
    );
  }

  useEffect(() => {
    if (selectedQuestion !== -1) {
      setqMod();
    }
  }, [selectedQuestion])

  const selected = 'selectedButton';

  function getQuestionsDisplay() {
    let cont: JSX.Element[] = [];
    const qs = props.questions;

    if (qs !== undefined && qs.length > 0) {
      for (let i = 0; i < qs.length; i++) {
        cont[i] = (
          <React.Fragment key={i}>
            <div className={`wrapper displayquestionBank displayBetween`}>
              <p>{qs[i].question}</p>
              <div className="responsive-container">
                <div className="menuoption"><i title="Edit" onClick={() => setSelectedQuestion(i)} className="fas fa-pen-to-square" /></div>
                <div className="menuoption no-margin-right"><i title="Remove" onClick={() => props.DeleteQuestion(i)} className="fas fa-minus" /></div>
              </div>
            </div>
          </React.Fragment>
        )
      }
    } else {
      cont = [<div className={`wrapper displayquestionBank2`} key="no-questions">
        <h4>No Questions to Display</h4>
      </div>]
    }
    return cont;
  }

  return (
    <div className="startsection instructions">
      <div className="title2">
        <h2>List of {props.category} Questions </h2>
      </div>
      <div className="choices-new-questionbank2">
        {props.questions.length !== 0 && getQuestionsDisplay()}
      </div>
    </div>
  );
}
