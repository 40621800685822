import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from '../appContext';
import { setStorageItem, getStorageItem, removeStorageItem } from '../utils/storage';

  // Save payment info
  export const savePaymentInfo = (paymentData: any) => {
    // Save with 24 hour expiry
    setStorageItem('paymentInfo', paymentData, 24 * 60 * 60 * 1000);
  };

  // Retrieve payment info
  export const getPaymentInfo = () => {
    return getStorageItem('paymentInfo');
  };

  export const removePaymentInfo = ()=>{
    return removeStorageItem('paymentInfo');
  }

export const Pay: React.FC = () => {

  const { auth0  } = useAppContext();


  useEffect(() => {
    savePaymentInfo("payment");
    auth0.loginWithRedirect();
  }, []);

  return <></>;
};

