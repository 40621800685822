import React from 'react';
import Settings from './Settings';
import FeedbackBox from './FeedbackBox'
import SignInModal from './signinModal';
import IntroFormModal from './introFormModal';
//import { useAuth0 } from "@auth0/auth0-react";
import { LogoutButton } from './signin';
import EndScreenModal from './endScreenModal';
import SubscribeToProduct from "./subscribeToProduct";
import { AppWrapper } from "./styles";
import { useNavigate } from 'react-router-dom';
import { User } from './types';
import { useAppContext } from '../appContext';
import ErrorModal from './errorModal';
import NextConfirmation from './NextConfirmation';
import { updateIntakeForm, updateAudio, uploadUserResume } from '../api';
import { hasPremSub } from '../util';

function Nav() {
  const { auth0, state, reducers, navigateThenRun, showError, getFeedback, showFeedbackPopUp, stopAudio, refreshUser } = useAppContext();
  const {setstart,
    setqPrompt,
    setloading,
    setrecognizedsp,
    setinterviewer,
    setmicOn,
    setrecognizedspTemp,
    setprompt,
    setfullprompt,
    setquestions,
    setquestionLevelMap,
    setcurrentQuestion,
    setcurrScreen,
    setfeedback,
    setfeedbackDuration,
    setrole,
    setlevel,
    setdomain,
    setcustomQ,
    setjobDescriptionQ,
    settype,
    setspecType,
    setprocessing,
    setcode,
    setqA,
    setAction,
    setshowAns,
    setshowFeedback,
    setshowAction,
    setcanStop,
    setcodingLang,
    setnext,
    setprevious,
    setshowNextConfirmation,
    setshowHistoryModal,
    setshowsigninModal,
    setshowintroFormModal,
    setshowPaymentModal,
    setshowTranscript,
    setshowApiError,
    setErrorText,
    setshowSettings,
    setshowFeedbackBox,
    setsessionStopped,
    setprofileUser,
    setisHelpful,
    setisHelpfulMessage,
    setintQuestion,
    setqType,
    setquestionQuery,
    setspeechLen,
    sethitMaxLen,
    setdisplayPastFeedback,
    setpastInfo,
    setretryPast,
    setbeginningLoad,
    setNextFunc,
    setfeedbackNextFunc,
    setAbortController,
    setGPTCallCount,
    setcontextLength} = reducers;

  const NextConfirmationModal = (): void => {
      setshowNextConfirmation(!state.showNextConfirmation);
  }

  const showNext= state.recognizedsp === "" ? null : () => setshowNextConfirmation(true);

  const goToStart = async (startPath: boolean = false): Promise<void> => {
    if(state.start) {
        stopAudio();
    }
    if(!startPath) {
        if(state.feedback !== "") {
            setcurrScreen(1);
            setbeginningLoad(true);
        } else {
            window.location.href = 'https://www.thedevdifference.com';
        }
    } else { 
        if(showFeedbackPopUp()) {
            setfeedbackNextFunc(() => {navigateThenRun(() => {goToStartCont()}, "/")});
        } else {
            goToStartCont();
        }
    }
  }

  const goToStartCont = (): void => {
    state.abortController.abort();
    setstart(false);
    setqPrompt([]);
    setloading(true);
    setrecognizedsp('');
    setinterviewer('');
    setmicOn(false);
    setrecognizedspTemp('');
    setprompt([]);
    setfullprompt([]);
    setcurrScreen(1);
    setfeedback('');
    setquestions([]);
    setfeedbackDuration(-1);
    setAction('');
    setrole('');
    setlevel('');
    setdomain('');
    setcustomQ('');
    setjobDescriptionQ({description:'',title:''});
    settype('');
    setspecType('');
    setprocessing(false);
    setcode('');
    setqA('');
    setretryPast(false);
    setshowAns(false);
    setshowAction(false);
    setshowFeedback(false);
    setcanStop(false);
    setcodingLang('python');
    setnext(true);
    setprevious(false);
    setshowNextConfirmation(false);
    setshowHistoryModal(false);
    setshowsigninModal(false);
    setshowPaymentModal(false);
    setshowintroFormModal(false);
    setshowTranscript(false);
    setshowSettings(false);
    setshowFeedbackBox(false);
    setshowApiError(false);
    setErrorText("Encountered a problem reaching the api, retrying in 3 seconds...");
    setsessionStopped(true);
    setintQuestion(1);
    setqType(null);
    setquestionQuery("");
    setisHelpful(null);
    setisHelpfulMessage(null);
    setspeechLen(0);
    sethitMaxLen(false);
    setdisplayPastFeedback("");
    setpastInfo({});
    setAbortController(new AbortController());
    setGPTCallCount(0);
    setcontextLength(500);
    setquestionLevelMap({});
    setcurrentQuestion(-1);    
  }

  const canClosePopup= location.pathname !== "/interview" || (state.profileUser !== null && state.canPractice && hasPremSub(state.profileUser));

  const submitForm = async (form: any): Promise<void> => {
    let user2 = await updateIntakeForm(form);
    setprofileUser(user2);
  }

  const settingModal = (): void => {
    setshowSettings(!state.showSettings);
  }

  const signinModal = (): void => {
    setshowsigninModal(!state.showsigninModal);
  }

  const feedbackBoxModal = (): void => {
    setshowFeedbackBox(!state.showFeedbackBox);
  };

  const UpdateAudio = async (e: boolean): Promise<void> => {
    if (state.profileUser && state.profileUser.audioEnabled !== e) {
        const user2 = await updateAudio(e);
        if (user2 && user2.audioEnabled === false) {
            stopAudio();
        }
        setprofileUser(user2);
    }
  }

  const UpdateResume = async (text: string, file: string): Promise<void> => {
    if (text !== state.resume.resumeText) {
        const user2 = await uploadUserResume(text, file);
        setprofileUser(user2);
    }
  }

  return (
    <>
        <ErrorModal showApiError={state.showApiError}
                        toggleApiError={(val)=>showError(val)}
                        errorText={state.errorText}/>
        <NextConfirmation showNextConfirmation={state.showNextConfirmation}
                        toggleNextConfirmation={()=>NextConfirmationModal()}
                        Next={() => {
                            setshowNextConfirmation(false);
                            if(state.nextFunc !== null)
                            {
                                state.nextFunc();
                            }
                        }}
                        GetFeedback={()=>{setshowNextConfirmation(false);getFeedback();}}/>
      <nav className="banner-nav">
        <div className="titleicon">
          <a
            data-custom-id="TitleClick"
            onClick={() => {
              if (showNext !== null) {
                setNextFunc(() => { goToStart(false); });
                showNext();
              } else {
                goToStart(false);
              }
            }}
          >
            The <span className="orange">&#123;</span>Dev<span className="orange">&#125;</span> Difference
          </a>
        </div>
        <div className="responsive-container">
          <div className="menuoption">
            <a className='responsive-container' data-custom-id="Home">
              <p
                onClick={() => {
                  if (showNext !== null) {
                    setNextFunc(() => { navigateThenRun(() => { goToStart(true) }, "/") });
                    showNext();
                  } else {
                    navigateThenRun(() => { goToStart(true) }, "/")
                  }
                }}
                className="menuIcon"
              >
                Home
              </p>
            </a>
          </div>
          {state.canPractice && (
            <div className="menuoption">
              <a className='responsive-container' data-custom-id="PastFeedback">
                <p
                  onClick={() => hasPremSub(state.profileUser) ? navigateThenRun(null, '/history') : setshowPaymentModal(true)}
                  className="menuIcon"
                >
                  History
                </p>
              </a>
            </div>
          )}
          {state.canPractice && state.profileUser?.isAdmin && (
            <div className="menuoption">
              <a className='responsive-container' data-custom-id="admin">
                <p onClick={() => navigateThenRun(null, "/admin")} className="menuIcon">
                  Admin
                </p>
              </a>
            </div>
          )}
          <div className="menuoption">
            <a
              className='responsive-container'
              data-custom-id="bug"
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSf_CISqn0rFNWO24lrVPjriP6BZ_y3Aw1aTLKVvpEgI4_LtlQ/viewform?usp=sf_link"
            >
              <p className="menuIcon">Bug</p>
            </a>
          </div>
          {state.canPractice && (
            <div className="menuoption">
              <a className='responsive-container' data-custom-id="profile">
                <p onClick={()=>settingModal()} className="menuIcon">
                  Profile
                </p>
              </a>
            </div>
          )}
          {!(state.profileUser !== null || auth0.isLoading) && (
            <div className="menuoptionLogin" onClick={() => auth0.loginWithPopup()}>
              Sign in
            </div>
          )}
          {state.profileUser !== null && !hasPremSub(state.profileUser) && (
            <div className="menuoptionLogin" onClick={() => setshowPaymentModal(true)}>
              Premium
            </div>
          )}
        </div>
        {state.memberFeedbackModal !== null && state.memberFeedbackModal}
        {state.questionModal !== null && state.questionModal}
        {state.loadingOverlayComp !== null && state.loadingOverlayComp}
        <SignInModal
          showSignIn={state.showsigninModal}
          canClosePopup={canClosePopup}
          togglesigninModal={()=>signinModal()}
        />
        {state.showPaymentModal && (
          <SubscribeToProduct
            showPaymentModal={state.showPaymentModal}
            togglePaymentModal={(val)=>setshowPaymentModal(val)}
            setProfileUser={setprofileUser}
            canClosePopup={canClosePopup}
          />
        )}
        <IntroFormModal
          email={state.profileUser?.email ?? ""}
          verified={state.profileUser?.email_verified?? false}
          submitForm={(form) => submitForm(form)}
          showIntroForm={state.showintroFormModal}
          canClosePopup={canClosePopup}
          toggleintroFormModal={()=>setshowintroFormModal(!state.showintroFormModal)}
          refreshUser={()=>refreshUser()}
        />
        <Settings
          showSettings={state.showSettings}
          toggleSettings={()=>settingModal()}
          updateResume={(t,f)=>UpdateResume(t,f)}
          setAudio={(e)=>UpdateAudio(e)}
          audioEnabled={state.userAudio}
          user={state.profileUser}
        />
        <FeedbackBox
          updateIsHelpful={(e)=>setisHelpful(e)}
          updateIsHelpfulMessage={(e)=>{setisHelpfulMessage(e);}}
          showFeedbackBox={state.showFeedbackBox}
          toggleFeedbackBox={()=>feedbackBoxModal()}
          isHelpful={state.isHelpful}
          isHelpfulMessage={state.isHelpfulMessage}
        />
      </nav>
      <div className="pseudo-nav" />
     
    </>
  );
}

export default Nav;
