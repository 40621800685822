import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import ReactModal from 'react-modal';
import { checkoutWithStripe, registerPaidUser } from "../api";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { AppWrapper } from "./styles";
/** Styling */
import {
  CurrencySymbol,
  SubscriptionPlansWrapper,
  SubscriptionPlanCard,
  SubscriptionPlanCardHeading,
  SubscriptionPlanCardPrice,
  SubscriptionPlanCardSubHeading
} from "./styles";

/** Utils */
import {
  PRODUCT_PLANS,
  STRIPE_PUBLISHABLE_KEY
} from "./consts";

import { Product } from "./types";

const customStyles: ReactModal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '85vw',
    transform: 'translate(-50%, -50%)',
    borderRadius: '1.5rem',
    backgroundColor: 'white'
  },
};

interface SubscribeToProductProps {
  showPaymentModal: boolean;
  togglePaymentModal: (show: boolean) => void;
  setProfileUser: (user: any) => void;
  canClosePopup: boolean;
}

const SubscribeToProduct: React.FC<SubscribeToProductProps> = (props) => {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

  const displayProductPlans = (productPlans: Product[]) => {
    const [productId, setProductId] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [isComplete, setIsComplete] = useState(false);
    const [clientSecret, setClientSecret] = useState('');

    const handleComplete = async () => {
      setIsComplete(true);
      const user = await registerPaidUser(sessionId);
      setTimeout(() => { props.togglePaymentModal(false); props.setProfileUser(user) }, 2000);
    }

    const CheckoutForm = (id: string) => {
      checkoutWithStripe(id)
        .then((data) => {
            if(data){   
          setSessionId(data.sessionid);
          setClientSecret(data.clientSecret);
            }
        });
    }

    if (productId === '' && !isComplete) {
      return (
        <div className="subFlex">
          <h2>Upgrade</h2>
          <p>Use code <span className="promo">BLACKFRIDAY30</span> for 30% off.</p>
          <div className="subFlexRow">
            {productPlans.map((product, i) => (
              <Col key={i}>
                <SubscriptionPlanCard>
                  <SubscriptionPlanCardHeading>
                    {product.name}
                  </SubscriptionPlanCardHeading>
                  <SubscriptionPlanCardPrice>
                    <CurrencySymbol>$</CurrencySymbol>&nbsp;{product.price}<CurrencySymbol>/{product.cadence}</CurrencySymbol>
                  </SubscriptionPlanCardPrice>
                  <SubscriptionPlanCardSubHeading>
                    Recurring Billing. Cancel anytime.
                  </SubscriptionPlanCardSubHeading>
                  <SubscriptionPlanCardSubHeading style={{ borderBottom: "none" }}>
                    {product.description}
                  </SubscriptionPlanCardSubHeading>
                  <br />
                  <br />
                  <Button className="orangeBack bottom" onClick={() => { setProductId(product.id); CheckoutForm(product.id) }} block={true}>Select This Plan</Button>
                </SubscriptionPlanCard>
              </Col>
            ))}
          </div>
        </div>
      )
    }
    else if (productId !== '' && !isComplete) {
      if (clientSecret !== '') {
        return (
          <div id="checkout" className="instructions">
            {clientSecret && (
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{ clientSecret, onComplete: handleComplete }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            )}
          </div>
        )
      }
      else {
        return (
          <div id="checkout" className="instructions">
            <p>Loading...</p>
          </div>
        )
      }
    }
    else if (isComplete) {
      return (
        <div id="checkout" className="instructions">
          <p>
            Success! You are one step closer to securing the bag!
          </p>
        </div>
      )
    }
  };

  return (
    <ReactModal
      isOpen={props.showPaymentModal}
      style={customStyles}
      shouldCloseOnOverlayClick={props.canClosePopup}
      shouldCloseOnEsc={props.canClosePopup}
      onRequestClose={() => props.togglePaymentModal(false)}
      ariaHideApp={false}
    >
      <AppWrapper>
        <SubscriptionPlansWrapper>
          <Row className="justifyCenter">{displayProductPlans(PRODUCT_PLANS)}</Row>
        </SubscriptionPlansWrapper>
      </AppWrapper>
    </ReactModal>
  );
};

export default SubscribeToProduct;
