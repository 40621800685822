import React, { useState, useRef, useEffect } from 'react';
import { feedbackMap } from '../util';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange, RangeKeyDict } from 'react-date-range';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ConsoleLoggingListener } from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.browser/ConsoleLoggingListener';
import { InterviewObject } from './types';

interface AdminOrgTrendProps {
  membersInfo: { [key: string]: { [key: string]: InterviewObject[] } };
  organization: string;
}

interface FeedbackMapping {
  [key: string]: [string[], string[], string[]] | null;
}

interface ChartDataPoint {
  x: Date;
  y: number;
  obj: InterviewObject;
}

function AdminOrgTrend(props: AdminOrgTrendProps) {
  const [typeFilter, setTypeFilter] = useState<string>('all');
  const [dateRange, setDateRange] = useState<RangeKeyDict>({
    selection: {
      startDate: undefined,
      endDate: new Date(),
      key: 'selection',
    }
  });
  const [criteriaFilter, setCriteriaFilter] = useState<string>('all');
  const [feedbackMapping, setFeedbackMapping] = useState<[FeedbackMapping, string[]]>([{}, []]);
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [dateAdjust, setDateAdjust] = useState<boolean>(false);

  const scores: { [key: string]: number } = {
    "Poor": 10,
    "Satisfactory": 50,
    "Good": 85,
    "Exceptional": 100
  };
  
  const typeMap: { [key: string]: string } = {
    'resume based': 'behavioral',
    'behavioral': 'behavioral',
    'estimation': 'technical',
    'strategy': 'technical',
    'system design': 'technical',
    'software concepts': 'technical',
    'coding': 'technical',
    'product design': 'technical',
  };

  useEffect(() => {
    CreateInitMap(props.membersInfo);
  }, [props.membersInfo]);

  const overlayRef = useRef<HTMLDivElement>(null);
  const data = getChartData();
  const stats = getStats();

  function getScore(timestamp: string): number {
    const feed = feedbackMapping[0][timestamp];
    if (feed === null || feed === undefined) {
      return 0;
    }
    let total = 0;
    let copy = criteriaFilter !== 'all' ? feed[1].filter((x, i) => feed[0][i] === criteriaFilter) : feed[1];
    for (let i = 0; i < copy.length; i++) {
      total += scores[copy[i]];
    }
    return total / copy.length;
  }

  async function CreateInitMap(infos: { [key: string]: { [key: string]: InterviewObject[] } }) {
    let dict: FeedbackMapping = {};
    let allCriteria: string[] = [];
    const all: InterviewObject[] = [];
    for (let memkey in infos) {
      for (let key in infos[memkey]) {
        all.push(...infos[memkey][key]);
      }
    }
    for (let i = 0; i < all.length; i++) {
      let x = all[i];
      if ((x["timestamp"] !== undefined)) {
        let f = await feedbackMap(x["feedback"]);
        dict[x["timestamp"]] = f;
        if (f !== null) {
          f[0].forEach(c => {
            if (!allCriteria.includes(c.toLowerCase())) {
              allCriteria.push(c.toLowerCase());
            }
          });
        }
      }
    }
    allCriteria.sort();
    setFeedbackMapping([dict, allCriteria]);
  }

  function getDate(date: string): Date | null {
    if (date !== undefined && date !== "" && date !== null) {
      const dateTimeArr = date.split(' ');
      const dateArr = dateTimeArr[0].split('-');
      const timeArr = dateTimeArr[1].split(':');
      return new Date(parseInt(dateArr[0]), parseInt(dateArr[1]) - 1, parseInt(dateArr[2]), parseInt(timeArr[0]), parseInt(timeArr[1]), parseInt(timeArr[2]));
    }
    return null;
  }

  function inDateRange(ans: InterviewObject): boolean {
    if (dateAdjust) {
      if (ans["timestamp"] === undefined) {
        return false;
      }
      const d = getDate(ans["timestamp"]);
      return d !== null && dateRange.selection.endDate !== undefined && dateRange.selection.startDate !== undefined && d <= dateRange.selection.endDate && d >= dateRange.selection.startDate;
    }
    return true;
  }

  function hasCriteria(ans: InterviewObject): boolean {
    if (criteriaFilter !== 'all') {
      const t = ans["timestamp"];
      if (t !== undefined) {
        let f = feedbackMapping[0][t];
        if (f !== null) {
          let list = f[0].filter(x => (x.toLowerCase() === criteriaFilter.toLowerCase()));
          return list.length > 0;
        }
      }
      return false;
    }
    return true;
  }

  function inTypeFilter(ans: InterviewObject): boolean {
    if (typeFilter !== 'all') {
      let val = typeMap[ans["specType"]];
      val = val === undefined ? 'other' : val;
      return val === typeFilter;
    }
    return true;
  }

  function applyFilters(all: InterviewObject[]): InterviewObject[] {
    return all.filter(ans => inDateRange(ans) && inTypeFilter(ans) && hasCriteria(ans));
  }

  function getChartData(): ChartDataPoint[] {
    const all: InterviewObject[] = [];
    for (let memkey in props.membersInfo) {
      for (let key in props.membersInfo[memkey]) {
        all.push(...props.membersInfo[memkey][key]);
      }
    }
    
    const allFiltered = applyFilters(all);
    const data = allFiltered.map(ans => {
      const date = getDate(ans["timestamp"]);
      return date ? { x: date, y: getScore(ans["timestamp"]), obj: ans } : null;
    }).filter((x): x is ChartDataPoint => x !== null && x.y !== 0);
    data.sort((a, b) => a.x.getTime() - b.x.getTime());
    return data;
  }
  
  function getDateRangeDisplay(): string {
    const start = dateRange.selection.startDate === undefined ? 'Beginning' : formatDate(dateRange.selection.startDate);
    const end = dateRange.selection.endDate ? formatDate(dateRange.selection.endDate) : '';
    return `${start} - ${end} `;
  }

  function formatDate(currentDate: Date): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return currentDate.toLocaleDateString('en-US', options);
  }

  function formatTime(currentDate: Date): string {
    return `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
  }

  function isOverlayClicked(e: React.MouseEvent): void {
    if (overlayRef.current && !overlayRef.current.contains(e.target as Node)) {
      setPickerOpen(false);
    }
  }

  function calcDataAverage(): string {
    const sum = data.reduce((accumulator, currentValue) => accumulator + currentValue.y, 0);
    return (sum / data.length).toFixed(1);
  }

  function getStats(): [string, string, string] {
    const catDict: { [key: string]: { score: number, count: number } } = {};
    const roleDict: { [key: string]: number } = {};
    for (let i = 0; i < data.length; i++) {
      let role = data[i].obj["role"];
      let cat = data[i].obj["specType"];
      if (cat !== undefined && cat !== "" && catDict.hasOwnProperty(cat)) {
        const currCount = catDict[cat].count;
        const currScore = catDict[cat].score;
        catDict[cat] = { score: ((currScore * currCount) + data[i].y) / (currCount + 1), count: currCount + 1 };
      }
      else if (cat !== undefined && cat !== "") {
        catDict[cat] = { score: data[i].y, count: 1 };
      }
      if (role !== undefined && role !== "" && roleDict.hasOwnProperty(role)) {
        roleDict[role] += 1;
      }
      else if (role !== undefined && role !== "") {
        roleDict[role] = 1;
      }
    }
    const roleEntries = Object.entries(roleDict);
    roleEntries.sort((a, b) => a[1] - b[1]);
    const catEntries = Object.entries(catDict);
    catEntries.sort((a, b) => a[1].score - b[1].score);

    const roleMax = roleEntries.length > 0 ? roleEntries[roleEntries.length - 1][0] : "N/A";
    const catMax = catEntries.length > 0 ? catEntries[catEntries.length - 1][0] : "N/A";
    const catMin = catEntries.length > 0 ? catEntries[0][0] : "N/A";

    return [roleMax, catMax, catMin !== catMax ? catMin : "N/A"];
  }

  return (
    <div className="startsection instructions">
      <div className="title2">
        <h2><span>Take a look at what {props.organization} members are practicing </span><span></span></h2>
      </div>

      <div className="container">
        <div className="row">
          <div className="square">
            <h2>{Object.keys(props.membersInfo).length}</h2>
            <p>Members</p>
          </div>
          <div className="square">
            <h2>{data.length}</h2>
            <p>Interviews Practiced</p>
          </div>
          <div className="square">
            <h2>{calcDataAverage()}%</h2>
            <p>Average Interview Score</p>
          </div>
        </div>
        <div className="row">
          <div className="square">
            <h2>{stats[0]}</h2>
            <p>Top Practiced Role</p>
          </div>
          <div className="square">
            <h2>{stats[1]}</h2>
            <p>Average Highest Scoring Category</p>
          </div>
          <div className="square">
            <h2>{stats[2]}</h2>
            <p>Average Lowest Scoring Category</p>
          </div>
        </div>
      </div>
      <div className="chartWrapper2">
        <div className='chartDiv'>
          <ResponsiveContainer width="90%" height="100%">
            <LineChart
              data={data.map(d => ({ x: `${formatDate(d.x)} (${formatTime(d.x)})`, y: d.y }))}
              margin={{
                top: 15,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="x" />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" />
              <Line type="monotone" name="Average Interview Score Percentage over time" dataKey="y" strokeWidth={3} stroke="#ff914d" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default AdminOrgTrend;
