import React from 'react';

import microphoneImage from '../../TDD-Images/microphone-icon.png';
import doneImage from '../../TDD-Images/done-icon.png';
import transcriptImage from '../../TDD-Images/transcript-icon.png';
import { useAppContext } from '../appContext';

interface PrepScreenProps {
  buttonClick: () => void;
}

function PrepScreen(props: PrepScreenProps) {
  const { state, reducers } = useAppContext();
  //console.log(state);
  const { setcurrScreen } = reducers;
  document.body.style.backgroundColor = 'white';
  const question = state.currentQuestion !== -1 ? state.questions[state.currentQuestion] : null;

  return (
    <div className="prepscreensection">
      <div className="question-1-new">
        <h3>Get Ready to Start!</h3>
      </div>
      
      <div className="question-subtext prepsub">
        <h4>Your interview selection: <b>{question === null || question === undefined ? `${state.role === "software engineering" ? "software development" : state.role}, ${state.specType} Interview` : question}</b></h4>
      </div>

      <div className="startScreen">
        <div className="prep-start-instructions2 prepText">
          <div className="prep-start-instructions2-cont">
            <img src={microphoneImage} alt="microphone icon"/>
            <p>Use Mic to Answer Questions</p>
          </div>
          <div className="prep-start-instructions2-cont">
            <img src={transcriptImage} alt="microphone icon"/>
            <p>See Live Conversation</p>
          </div>
          <div className="prep-start-instructions2-cont">
            <img src={doneImage} alt="yellow button icon with 'done' text"/>
            <p>Get Feedback when Done</p>
          </div>
        </div>
      </div>

      <div className="back">
        <i onClick={() => setcurrScreen(1)} className="fas fa-circle-arrow-left fa-2x"></i>
        <p>Back</p>
      </div>
      <div className="next">
        <i onClick={() => props.buttonClick()} className="fas fa-circle-arrow-right fa-2x"></i>
        <p>I'm Ready!</p>
      </div>
    </div>
  );
}

export default PrepScreen;
