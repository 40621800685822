import React from 'react';
import ReactModal from 'react-modal';

interface FeedbackBoxProps {
  showFeedbackBox: boolean;
  toggleFeedbackBox: (show: boolean) => void;
  isHelpful: boolean | null;
  updateIsHelpful: (isHelpful: boolean) => void;
  isHelpfulMessage: string | null;
  updateIsHelpfulMessage: (message: string) => void;
}

const FeedbackBox: React.FC<FeedbackBoxProps> = (props) => {
  const customStyles: ReactModal.Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      maxWidth: '82vw',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1.5rem',
      backgroundColor: '#f4f4f5'
    },
  };

  return (
    <ReactModal
      isOpen={props.showFeedbackBox}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => { props.toggleFeedbackBox(false); }}
      ariaHideApp={false}
    >
      <div className="thumbsCol-popup">
        {props.isHelpfulMessage === null && (
          <>
            <p>Was this feedback helpful?</p>
            <div className="thumbs-popup">
              <i
                onClick={() => props.updateIsHelpful(true)}
                title="helpful"
                className={`fas fa-thumbs-up mr-2 ${props.isHelpful === true ? 'selectedBut' : ''}`}
              ></i>
              <i
                onClick={() => props.updateIsHelpful(false)}
                title="not helpful"
                className={`fas fa-thumbs-down ${props.isHelpful === false ? 'selectedBut' : ''}`}
              ></i>
            </div>
          </>
        )}
        {props.isHelpful !== null && props.isHelpfulMessage === null && (
          <textarea
            id="myFeedbackArea2"
            placeholder={
              props.isHelpful === false
                ? "Please tell us more about what we can improve."
                : "Please tell us more about what you liked."
            }
            name="name"
          />
        )}
        {props.isHelpful !== null && props.isHelpfulMessage === null && (
          <button
            className="formexperiencebuttonfeedback"
            onClick={() => {
              const feedbackElement = document.getElementById('myFeedbackArea2') as HTMLTextAreaElement;
              props.updateIsHelpfulMessage(feedbackElement.value);
              props.toggleFeedbackBox(false);
            }}
          >
            Submit
          </button>
        )}
      </div>
    </ReactModal>
  );
}

export default FeedbackBox;