import React from 'react';
import littleGuy from '../../TDD-Images/little-guy.png';
import { generateGUID } from '../util';
import { GPTResponse } from './types';

interface TranscriptProps {
  promptArray: GPTResponse[];
  showAffirm?: boolean;
  noBottom?: boolean;
  isEndScreen?: boolean;
}

function makePara(x: GPTResponse, index: number) {
  if (x.role === "user") {
    let content = x.content;
    let startCode = content.indexOf("```");

    if (startCode !== -1) {
      let endCode = content.slice(startCode + 3, content.length).indexOf('```') + startCode + 3;
      let afterContent = content.slice(endCode + 3, content.length);
      let beforeContent = content.slice(0, startCode);
      let contentArr = content.slice(startCode + 3, endCode).split('\n');
      let frags = contentArr.map((item, index) => {
        if (!/^\s*$/.test(item)) {
          return (
            <React.Fragment key={generateGUID()}>
              {item}
              {index !== contentArr.length - 1 && <br />}
            </React.Fragment>
          );
        } else {
          return (<React.Fragment key={generateGUID()}></React.Fragment>);
        }
      });
      return <p key={index}><b>You:</b> {beforeContent}<br /> <code>{frags}</code> <br /><br /> {afterContent}</p>;
    } else {
      return <p key={index}><b>You:</b> {content}</p>;
    }
  } else {
    let contentArr = x.content.split('<br/>');
    let frags = contentArr.map((item, index) => {
      if (!/^\s*$/.test(item)) {
        return (
          <React.Fragment key={generateGUID()}>
            {item}
            {index !== contentArr.length - 1 && <br />}
          </React.Fragment>
        );
      } else {
        return (<React.Fragment key={generateGUID()}></React.Fragment>);
      }
    });
    return <p key={index}><b>Interviewer:</b> {frags}</p>;
  }
}

function copyToClipboard() {
  let element = document.getElementById("scrollingDiv");
  
  if (element && window.getSelection) {
    const range = document.createRange();
    range.selectNodeContents(element);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      navigator.clipboard.writeText(selection.toString());
    }
  }
}

function Transcript(props: TranscriptProps) {
  return (
    <div id="scrollingDiv" className={`sidebar ${props.isEndScreen ? 'lightbeige' : ''}`}>
      <div className='transcript'>
        <h2>Transcript</h2>
        <i onClick={() => copyToClipboard()} className="fas fa-clipboard"></i>
      </div>
      {props.promptArray.map((x, index) => makePara(x, index))}
      {props.showAffirm !== false && <div className={props.noBottom ? "prep-image-littleguy-nobottom" : "prep-image-littleguy"}>
        <img src={littleGuy} alt="encouragement text: 'You got this, Bud'" />
      </div>}
    </div>
  );
}

export default Transcript;