import React, { useState, useEffect } from 'react';
import Loading from "react-loading";
import Transcript from './transcript';
import { GetQuestionDisplay, feedbackMap, generateGUID } from '../util';
import { GPTResponse } from './types';

interface EndScreenProps {
  showFeedback: boolean;
  content: string;
  question: string;
  qLevel: string | null;
  promptArray: GPTResponse[];
  showTranscript: boolean;
  displayPastFeedback: boolean;
  adminView?: boolean;
  memberEmail?: string;
  canPickNext?: boolean;
  isRetryPast?: boolean;
  hasPremiumSub?: boolean;
  canNext?: boolean;
  pickNext?: () => void;
  nextClick?: () => void;
  retryClick?: () => void;
  retryPast?: () => void;
  getFeed?: () => void;
  getAction?: () => void;
  getAns?: () => void;
  showPaymentModal?: () => void;
  showAction?: boolean;
  showAns?: boolean;
  loading?: boolean;
  isHelpful?: boolean | null;
  isHelpfulMessage?: string | null;
  updateIsHelpful?: (isHelpful: boolean) => void;
  updateIsHelpfulMessage?: (message: string) => void;
  inOrg?: boolean;
  accessibleToOrg?: boolean;
  updateAccessibleToOrg?: (accessible: boolean) => void;
  privateSession?: boolean;
  setprivateSession?: (privateSession: boolean) => void;
  action?: string;
  hasFeedback?: boolean;
}

function mapArray(contentArr: string[], isCode: boolean = false): JSX.Element[] {
  return contentArr.map((item, index) => {
    if (!/^\s*$/.test(item)) {
      if (!isCode) {
        let rest = item;
        let col = item.indexOf(":");
        let toBold;
        if (col !== -1) {
          toBold = item.slice(0, col);
          rest = col === item.length - 1 ? "" : item.slice(col + 1);
        }
        return (
          <React.Fragment key={generateGUID()}>
            {col !== -1 && <b>{toBold}</b>}
            {rest !== "" && <br />}
            {rest}
            {index !== contentArr.length - 1 && <><br /><br /></>}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={generateGUID()}>
            {item}
            {index !== contentArr.length - 1 && <br />}
          </React.Fragment>
        );
      }
    } else {
      return (<React.Fragment key={generateGUID()}></React.Fragment>)
    }
  });
}

function EndScreen(props: EndScreenProps) {
  const [feedbackConts, setFeedbackConts] = useState<[string[], string[], string[]] | null>(null);
  const [feedbackVisi, setFeedbackVisi] = useState<boolean[]>([]);

  useEffect(() => {
    if (props.showFeedback) {
      feedbackMap(props.content).then(f => {
        if (f !== null) {
          let x = f[0].map(() => false);
          setFeedbackVisi(x);
          setFeedbackConts(f);
        }
      });
    }
  }, [props.content]);

  function updateFeedbackVisi(ind: number) {
    let feedbackNew = feedbackVisi.map((v, i) => i === ind ? !v : v);
    setFeedbackVisi(feedbackNew);
  }

  function dropdownComp() {
    if (!feedbackConts) return null;

    const classNameDict: { [key: string]: string } = {
      "Poor": "improve",
      "Satisfactory": "satisfactory",
      "Good": "good",
      "Exceptional": "exceptional",
      "N/A": "na"
    };

    return feedbackConts[0].map((criteria, i) => (
      <React.Fragment key={i}>
        <div className='feedbackCol'>
          <div className='feedbackRow'>
            <b><p>{criteria} - <span className={classNameDict[feedbackConts[1][i]]}>{feedbackConts[1][i]}</span></p></b>
            <i onClick={() => props.hasPremiumSub || props.adminView ? updateFeedbackVisi(i) : props.showPaymentModal && props.showPaymentModal()} className={feedbackVisi[i] ? "fas fa-angle-up feedbackLeftMargin" : "fas fa-angle-down feedDrop feedbackLeftMargin"}></i>
          </div>
          {feedbackVisi[i] && <p className='mt-2'>{mapArray(feedbackConts[2][i].split('<br/>'))}</p>}
        </div>
        <br />
      </React.Fragment>
    ));
  }

  const classNameT = props.showTranscript ? "startsection main" : "startsection noTranscript";
  const reg = /^```/;
  document.body.style.backgroundColor = 'white';
  let questionFrags = GetQuestionDisplay(props.question, null);
  let beforeCodeFrags, codeSubsetFrags, afterCodeFrags;

  if (!props.showFeedback) {
    let contentArr = props.content.split('<br/>');
    let startCode = contentArr.findIndex(element => reg.test(element));
    let endCode = -1;
    let beforeCode = contentArr;
    let codeSubset: string[] = [];
    let afterCode: string[] = [];
    if (startCode !== -1) {
      endCode = contentArr.slice(startCode + 1, contentArr.length).indexOf('```') + startCode + 1;
    }
    if (startCode !== -1 && endCode !== -1) {
      beforeCode = contentArr.slice(0, startCode);
      codeSubset = contentArr.slice(startCode + 1, endCode);
      afterCode = contentArr.slice(endCode + 1, contentArr.length);
    }

    beforeCodeFrags = mapArray(beforeCode);
    codeSubsetFrags = mapArray(codeSubset, true);
    afterCodeFrags = mapArray(afterCode);
  }

  return (
    <div className="wrapper blackText">
      {props.showTranscript &&
        <Transcript promptArray={props.promptArray}
          isEndScreen={true}
          showAffirm={false}
          noBottom={true} />}
      <div className={classNameT} id="scrolling">
        <div className="headingEnd mt-3 mb-3 pt-1">
          {!props.adminView && <div className={`${props.displayPastFeedback || props.isRetryPast ? "mt-5" : ""} flex-col`}>
            <h2> Congratulations on completing an interview  </h2>
            <p><b>Your interview question</b>: {questionFrags}</p>
          </div>}
          {props.adminView && <div className="mt-5 flex-col">
            <h4> Interview Summary for {props.memberEmail} </h4>
            <p><b>Interview question</b>: {questionFrags}</p>
          </div>}
          {!props.displayPastFeedback && <div className="endButtons">
            {props.canPickNext && !props.isRetryPast && (props.hasPremiumSub) && <div className="flex-col mb-2"><i onClick={() => { props.pickNext && props.pickNext() }} title="pick next question" className="fas fa-circle-arrow-right"></i>Pick Question  </div>}
            {props.canNext && !props.isRetryPast && <div className="flex-col mb-2"> <i onClick={props.nextClick} title="random next question" className="fas fa-circle-arrow-right"></i> {props.canPickNext ? "Random question" : "Next Question"} </div>}
            <div className="flex-col"><i onClick={props.retryClick} title="Retry" className="fas fa-repeat"></i>Retry</div>
          </div>}
          {props.displayPastFeedback && !props.adminView && <div className="endButtons">
            <div className="flex-col mt-3"><i onClick={props.retryPast} title="Retry" className="fas fa-repeat"></i>Retry</div>
          </div>}
        </div>

        <div className="feedButtonWrapper">
          <button className={props.showFeedback ? 'formexperiencebuttonEndSelected' : 'formexperiencebuttonEnd'} onClick={props.getFeed? props.getFeed : ()=>{}} >Feedback </button>
          {(!props.displayPastFeedback || props.action !== "") && <button className={props.showAction ? 'formexperiencebuttonEndSelected' : 'formexperiencebuttonEnd'} onClick={props.getAction? props.getAction : ()=>{}} > Action Steps </button>}
          {!props.displayPastFeedback && <button className={props.showAns ? 'formexperiencebuttonEndSelected' : 'formexperiencebuttonEnd'} onClick={() => props.hasPremiumSub ? props.getAns && props.getAns() : props.showPaymentModal && props.showPaymentModal()} > Sample Solution </button>}
          <div className="endScreenCont">
            {<>
              {!props.showFeedback && <div className="p-1 pl-2 endS leftAlign">
                <p>{beforeCodeFrags}</p>
                <p className="codeP"><code>{codeSubsetFrags}</code></p>
                <p>{afterCodeFrags}</p>
              </div>}
              {props.showFeedback && feedbackConts === null && <div className="p-1 pl-2 endS leftAlign">
                <p>{mapArray(props.content.split('<br/>'))}</p>
              </div>}

              {props.showFeedback && feedbackConts !== null && <div className="p-1 pl-2 endS leftAlign">
                {dropdownComp()}
              </div>}
            </>}

            {props.loading ? <div className="loading">
              <Loading
                type="bubbles" color="#000000"
                height={200} width={100} />
            </div> : <></>}
          </div>
          {!props.displayPastFeedback && props.hasFeedback && <div className="thumbsCol">
            {props.isHelpfulMessage === null && <><p>Was this feedback helpful?</p>
              <div className="thumbs">
                <i onClick={() => props.updateIsHelpful && props.updateIsHelpful(true)} title="helpful" className={`fas fa-thumbs-up mr-2  ${props.isHelpful === true ? 'selectedBut' : ''}`}></i>
                <i onClick={() => props.updateIsHelpful && props.updateIsHelpful(false)} title="not helpful" className={`fas fa-thumbs-down ${props.isHelpful === false ? 'selectedBut' : ''}`}></i>
              </div></>}
            {props.isHelpful !== null && props.isHelpfulMessage === null && <textarea id="myFeedbackArea" placeholder={props.isHelpful === false ? "Please tell us more about what we can improve." : "Please tell us more about what you liked."} />}
            {props.isHelpful !== null && props.isHelpfulMessage === null && <button className="formexperiencebuttonfeedback" onClick={() => {
              const feedbackElement = document.getElementById('myFeedbackArea') as HTMLTextAreaElement;
              props.updateIsHelpfulMessage && props.updateIsHelpfulMessage(feedbackElement.value);
            }}>Submit</button>}
            {props.isHelpfulMessage !== null && <p>Thank you for your feedback!</p>}
          </div>}
          {props.inOrg && props.hasFeedback && props.displayPastFeedback && !props.adminView && <div className="thumbsCol">
            <div className="radio-choice2">
              <input className="privateInput" checked={props.accessibleToOrg !== undefined ? props.accessibleToOrg : false} onChange={(e) => props.updateAccessibleToOrg && props.updateAccessibleToOrg(!e.target.checked)} type="checkbox" id="accessibleToOrg" />
              <p>Accessible to Organization</p>
            </div>
          </div>}
          {props.inOrg && !props.displayPastFeedback && !props.adminView && <div className="thumbsCol">
            <div className="radio-choice2">
              <input className="privateInput" checked={!props.privateSession} onChange={(e) => props.setprivateSession && props.setprivateSession(!e.target.checked)} type="checkbox" id="accessibleToOrg" />
              <p>Accessible to Organization</p>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default EndScreen;
