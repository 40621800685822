// Type for storage items
export type StorageItem = {
  value: any;
  timestamp: number;
  expiry?: number; // optional expiry in milliseconds
};

// Save item to localStorage with optional expiry
export const setStorageItem = (key: string, value: any, expiryMs?: number) => {
  try {
    const item: StorageItem = {
      value,
      timestamp: new Date().getTime(),
      expiry: expiryMs
    };
    localStorage.setItem(key, JSON.stringify(item));
    return true;
  } catch (error) {
    console.error('Error saving to localStorage:', error);
    return false;
  }
};

// Get item from localStorage
export const getStorageItem = <T>(key: string): T | null => {
  try {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return null;

    const item: StorageItem = JSON.parse(itemStr);
    
    // Check if item has expired
    if (item.expiry && new Date().getTime() > item.timestamp + item.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value as T;
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return null;
  }
};

// Remove item from localStorage
export const removeStorageItem = (key: string): boolean => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error('Error removing from localStorage:', error);
    return false;
  }
};

// Clear all items from localStorage
export const clearStorage = (): boolean => {
  try {
    localStorage.clear();
    return true;
  } catch (error) {
    console.error('Error clearing localStorage:', error);
    return false;
  }
}; 