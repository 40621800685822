import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import ExcelReader from './excelReader';
import { roleOptions } from './track';
import CreatableSelect from 'react-select/creatable';
import { OrgQuestion } from './types';

interface AdminQuestionModalProps {
  showQuestionMod: boolean;
  toggleQuestionModal: (show: boolean) => void;
  question: string;
  rubric: string | null;
  selectedInd: number;
  EditQuestion: (v: number, e: string, r: string | null) => void;
  AddQuestion: (v: string, e: string | null) => void;
  AddQuestions: (array: [string, string | null][]) => void;
  category: string;
  setcategory: (category: string) => void;
}

const AdminQuestionModal: React.FC<AdminQuestionModalProps> = (props) => {
  const customStyles: ReactModal.Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '85vw',
      height: '75%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1.5rem',
      backgroundColor: '#f4f4f5'
    },
  };

  const [textareaContent, setTextareaContent] = useState(props.question);
  const [textareaContent2, setTextareaContent2] = useState(props.rubric || '');
  const [localcategory, setlocalcategory] = useState(props.category);
  const [excel, setexcel] = useState<(string | number)[][] | null>(null);

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaContent(event.target.value);
  };

  const handleTextareaChange2 = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaContent2(event.target.value);
  };

  useEffect(() => {
    setTextareaContent(props.question);
    setTextareaContent2(props.rubric || '');
    setexcel(null);
  }, [props.question, props.rubric]);

  return (
    <ReactModal
      isOpen={props.showQuestionMod}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => props.toggleQuestionModal(false)}
      ariaHideApp={false}
    >
      <h3 className='close-modal-x' onClick={() => props.toggleQuestionModal(false)}>x</h3>
      {props.category !== "" &&
        <div className="startsection scroll instructions">
          <div className="question-1-new">
            <div className="question-title">
              <div className="question">
                <h4>Add Custom {props.category} Question</h4>
              </div>
            </div>
            <div className="targetsubsection align-center">
              <div>
                <div className="choices-new">
                  <textarea
                    className="adminQs"
                    id="myFeedbackArea3"
                    placeholder="Specify the question you want to add."
                    value={textareaContent}
                    onChange={handleTextareaChange}
                    name="name"
                  />
                </div>
                <div className="choices-new">
                  <textarea
                    className="adminQs"
                    id="myFeedbackArea4"
                    placeholder="Optional: Specify 3-5 rubrics e.g. Is the answer clear, directly addressing the question with relevant context?."
                    value={textareaContent2}
                    onChange={handleTextareaChange2}
                    name="name"
                  />
                </div>
              </div>
              <div><h3 className='ml-5 mr-5'>OR</h3></div>
              <div>
                <div className="choices-new excelreader">
                  <ExcelReader updateExcel={(cont) => setexcel(cont)} />
                </div>
              </div>
            </div>
            <div>
              <button
                onClick={() =>
                  excel === null
                    ? (props.selectedInd !== -1
                      ? props.EditQuestion(props.selectedInd, textareaContent, textareaContent2 !== '' ? textareaContent2 : null)
                      : props.AddQuestion(textareaContent, textareaContent2 !== '' ? textareaContent2 : null))
                    : props.AddQuestions(excel.map(row => ([row[0].toString(), row[1]?.toString() || null ])))
                }
                className="formexperiencebutton-settings"
              >
                Done!
              </button>
            </div>
          </div>
        </div>}
      {props.category === "" &&
        <div className="question-1-new">
          <div className="question-title">
            <div className="question">
              <h3>Which role are the questions for?</h3>
            </div>
          </div>
          <div className="input-choices-new">
            <CreatableSelect
              placeholder="select existing or type in custom role"
              isClearable
              inputValue={localcategory}
              options={roleOptions}
              onInputChange={(e) => { if (e !== "" || localcategory.length === 1) { setlocalcategory(e); } }}
              onChange={(e) => {
                props.setcategory(e !== null && e.value !== "" ? e.value : localcategory);
                if (e !== null && e.value !== "") { setlocalcategory(e.value) }
              }}
            />
          </div>
        </div>}
    </ReactModal>
  );
}

export default AdminQuestionModal;
