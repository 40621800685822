import React, { useEffect, useState } from 'react';
import '../custom.css';
import { resendVerificationEmail } from '../api';
import Select, { ActionMeta, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { UNIVERSITIES } from './universityList';
import { FormData, Option } from './types';


interface IntroFormProps {
  verified: boolean;
  email: string;
  refreshUser: () => void;
  submitForm: (form: FormData) => void;
}

function IntroForm(props: IntroFormProps) {
  const [form, setForm] = useState<FormData>({ '2': '', '3': '' });
  const [canResend, setCanResend] = useState(true);
  const [isInSchool, setIsInSchool] = useState(false);

  const updateForm = (k: string, v: string | boolean) => {
    const f = { ...form, [k]: v };
    setForm(f);
    //console.log(f);
  };

  const NotEmptyFields = (): boolean => {
    const canBeEmpty = ['0', '3'];
    for (let i = 0; i < canBeEmpty.length; i++) {
      if (form[canBeEmpty[i]] === '') {
        return false;
      }
    }
    return !isInSchool || form['2'] !== '';
  };

  useEffect(() => {
    setIsInSchool(form['1'] === 'Yes, college' || form['1'] === 'Yes, graduate school');
  }, [form]);

  const lengthOfForm = 12;
  
  const roleOptions: Option[] = [
    {value:'undecided', label:'Undecided'},
    { value: 'product management', label: 'Product Management' },
    { value: 'software engineering', label: 'Software Engineering'},
    { value: 'cybersecurity engineering', label: 'Cybersecurity Engineering' },
    { value: 'web development', label: 'Web Development' },
    { value: 'ux designer', label: 'UX Designer' },
    { value: 'business analyst', label: 'Business Analyst' },
    { value: 'data scientist', label: 'Data Scientist' }
  ];
  
  const ethnicityOptions: Option[] = [
    { value: 'American Indian or Alaska Native', label: 'American Indian or Alaska Native' },
    { value: 'Asian/ Asian American', label: 'Asian/ Asian American' },
    { value: 'Black/African American', label: 'Black/African American'},
    { value: 'Hispanic or Latino', label: 'Hispanic or Latino' },
    { value: 'Native Hawaiian or other Pacific Islander', label: 'Native Hawaiian or other Pacific Islander' },
    { value: 'White/Caucasian', label: 'White/Caucasian' },
    { value: 'Other', label: 'Other' },
    { value: 'Prefer not to say', label: 'Prefer not to say' }
  ];
  
  const genderOptions: Option[] = [
    { value: 'Female', label: 'Female' },
    { value: 'Male', label: 'Male' },
    { value: 'Non-Binary', label: 'Non-Binary'},
    { value: 'Prefer not to say', label: 'Prefer not to say' }
  ];
  
  const enrolledOptions: Option[] = [
    { value: 'Yes, college', label: 'Yes, college' },
    { value: 'Yes, graduate school', label: 'Yes, graduate school' },
    { value: 'Yes, bootcamp or other certification program', label: 'Yes, bootcamp or other certification program'},
    { value: 'Recent graduate (0-1 years outside of school)', label: 'Recent graduate (0-1 years outside of school)' },
    { value: 'Not currently enrolled in any training or higher education program', label: 'Not currently enrolled in any training or higher education program' }
  ];
  
  const yesNoOptions: Option[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ];
  
  const frequencyOptions: Option[] = [
    { value: 'Never interviewed for a tech role', label: 'Never interviewed for a tech role' },
    { value: 'Currently interviewing (have 1 or more set up this month and the next)', label: 'Currently interviewing (have 1 or more set up this month and the next).' },
    { value: '3-6 months ago', label: '3-6 months ago'},
    { value: 'More than 6 months ago', label: 'More than 6 months ago' },
  ];
  
  const confidenceOptions: Option[] = [
    { value: 'Not all all confident', label: 'Not all all confident' },
    { value: 'Neutral', label: 'Neutral'},
    { value: 'Somewhat confident', label: 'Somewhat confident' },
    { value: 'Very confident', label: 'Very confident' },
  ];
  
  const discoveryOptions: Option[] = [
    { value: 'The Dev Difference campus workshop', label: 'The Dev Difference campus workshop' },
    { value: 'Google search', label: 'Google search'},
    { value: 'News article / online article', label: 'News article / online article' },
    { value: 'Social Media Ad', label: 'Social Media Ad' },
    { value: 'Friend / Word of Mouth', label: 'Friend / Word of Mouth' },
    { value: 'Career Fair', label: 'Career Fair' },
    { value: 'Other', label: 'Other' }
  ];

  const showSubmit = (Object.keys(form).length === lengthOfForm || (Object.keys(form).length === lengthOfForm - 1 && form[`${lengthOfForm - 2}`] === undefined)) && NotEmptyFields() && form[`${lengthOfForm - 1}`] === true;

  return (
    <div className="intakeform">
       {!props.verified && 
                <>
                <h3>Email Verification Step</h3>
                    <p>{`Please verify your email by clicking the verification link sent to `} <b>{props.email}</b>.
                    <br/>
                    Click the button below once you have verified your email to continue.
                    <br/>
                    Be sure to check your junk folder.</p>

                    <div className="settingsButtons2">
                        <div className = "formexperiencebutton3" onClick={()=>props.refreshUser()}>I'm Verified!</div>
                    </div>
                    {/* {canResend && <button onClick={()=>{resendVerificationEmail(props.email);setcanResend(false);}} className="formexperiencebutton-intake">Resend Verification Email</button>} */}
                </>
            }
      
      {props.verified && (
        <>
          <div className ="intakeform-header">
                <h3>Welcome</h3>
                <h4>To continue, please take a few moments to help us get to know you better.</h4>
            </div>
            <div className="intakeform-question">
                <div className ="intakeform-question-name">
                    <p>First and Last Name<span className="required">*</span></p>
                </div>
                <div className = "choices-new">
                    <input type="text" onChange={(e)=>updateForm('0', e.target.value)} id="userInput" name="userInput2"/>
                </div>
            </div>
          
          <div className="intakeform-question">
            <div className="intakeform-question-name">
              <p>Are you enrolled in school?<span className="required">*</span> </p>
            </div>
            <div className="input-choices-new-full">
              <Select<Option>
                isSearchable={false}
                placeholder=""
                isClearable={false}
                options={enrolledOptions}
                onChange={(e: SingleValue<Option>) => { updateForm('1', e?.value || ''); }}
              />
            </div>
          </div>
          
          { isInSchool &&
           <div className="intakeform-question">
                <div className ="intakeform-question-name">
                    <p>What university are you currently enrolled in?<span className="required">*</span></p>
                </div>
                <div className = "input-choices-new-full">
                    <Select 
                    isSearchable={true}
                    placeholder=""
                    options={UNIVERSITIES} 
                    onChange={(e)=>{if(e !== null){updateForm('2', e.value)}}}/>
                </div>
            </div> }

            <div className="intakeform-question">
                <div className="intakeform-question-name"><p>What role are you interested in interviewing for?<span className="required">*</span> </p></div>
                <div className = "input-choices-new-full">
                    <CreatableSelect 
                    placeholder="select or type in custom role"
                    isClearable 
                    options={roleOptions} 
                    onInputChange={(e)=>{if(e !=="" || form['3'].toString().length === 1){updateForm('3', e);}}} //fix bug on mobile
                    onChange={(e)=>{if(e !== null){updateForm('3', e.value)}}}/>
                </div>
            </div> 

            <div className="intakeform-question">
                <div className="intakeform-question-name"><p>Have you ever completed a behavioral or technical interview?<span className="required">*</span> </p></div>
                <div className = "input-choices-new-full">
                    <Select 
                    isSearchable={false}
                    placeholder=""
                    isClearable={false}
                    options={yesNoOptions} 
                    onChange={(e)=>{if(e !== null){updateForm('4', e.value)}}}/>
                </div>
            </div>

            <div className="intakeform-question">
                <div className="intakeform-question-name"><p>How long has it been since you interviewed for a role in tech?<span className="required">*</span>  </p></div>
                <div className = "input-choices-new-full">
                    <Select 
                    isSearchable={false}
                    placeholder=""
                    isClearable={false}
                    options={frequencyOptions} 
                    onChange={(e)=>{if(e !== null){updateForm('5', e.value)}}}/>
                </div>
            </div>

            <div className="intakeform-question">
                  <div className="intakeform-question-name"><p>How confident are you in your tech interview skills?<span className="required">*</span> </p></div>
                  <div className = "input-choices-new-full">
                    <Select 
                    isSearchable={false}
                    placeholder=""
                    isClearable={false}
                    options={confidenceOptions} 
                    onChange={(e)=>{if(e !== null){updateForm('6', e.value)}}}/>
                </div>
            </div>
            <div className="intakeform-question">
                <div className="intakeform-question-name">
                    <p>Gender<span className="required">*</span> </p>
                </div>
                <div className = "input-choices-new-full">
                    <Select 
                    isSearchable={false}
                    placeholder=""
                    isClearable={false}
                    options={genderOptions} 
                    onChange={(e)=>{if(e !== null){updateForm('7', e.value)}}}/>
                </div>
            </div>

            <div className="intakeform-question">
                <div className="intakeform-question-name">
                    <p>What Ethnicity do you identify with?<span className="required">*</span> </p>
                </div>
                <div className = "input-choices-new-full">
                    <Select 
                    isSearchable={false}
                    placeholder=""
                    isClearable={false}
                    options={ethnicityOptions} 
                    onChange={(e)=>{if(e !== null){updateForm('8', e.value)}}}/>
                
                </div>
            </div>

            <div className="intakeform-question">
                <div className="intakeform-question-name"><p>How did you hear about us?<span className="required">*</span> </p></div>
                <div className = "input-choices-new-full">
                    <Select 
                    isSearchable={false}
                    placeholder=""
                    isClearable={false}
                    options={discoveryOptions} 
                    onChange={(e)=>{if(e !== null){updateForm('9', e.value)}}}/>
                </div>
            </div>

            <div className="intakeform-question noMB">
                <div className ="intakeform-question-name">
                    <p>Enter the Code for your organization (if you have one)</p>
                </div>
                <div className = "choices-new">
                    <input type="text" onChange={(e)=>updateForm('10', e.target.value)} id="userInput" name="userInput4"/>
                </div>
            </div>
          
          {showSubmit && <button onClick={() => props.submitForm(form)} className="formexperiencebutton-intake">Continue</button>}
          
          <div className="intakeform-question">
                <div className="radio-choice">
                    <input onChange={(e)=>updateForm('11', e.target.checked)} type="checkbox" id="termsCheckbox"/>
                    <p>By checking this box, I acknowledge and agree that The Dev Difference may collect, process, and store my personal information, including but not limited to my name, email address, and any content I submit on the platform. This data may be used for the purpose of providing and improving software services, communication with me, and complying with legal obligations.

I understand that my data may be stored on servers located in different jurisdictions and that The Dev Difference will take reasonable measures to protect my information but cannot guarantee absolute security. I also acknowledge that I can request the deletion of my account and associated data at any time. <span className="required">*</span></p>
                </div>
            </div>
        </>
      )}
    </div>
  );
}

export default IntroForm;