import React, { useRef } from 'react';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface PdfExtractorProps {
  resumeFile?: string;
  updateResume: (resume: string, fileName: string) => void;
}

const PdfExtractor: React.FC<PdfExtractorProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  let selectedFile: File | null = null;
  let fileName = props.resumeFile;

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      selectedFile = event.target.files[0];
      await handleFileUpload();
    }
  };

  async function handleFileUpload() {
    if (selectedFile) {
      const fileReader = new FileReader();
      let resume = "";
      fileReader.onload = async () => {
        const typedArray = new Uint8Array(fileReader.result as ArrayBuffer);
        const pdfData = typedArray.buffer;
        try {
          const loadingTask = pdfjs.getDocument({ data: pdfData });
          const pdf = await loadingTask.promise;

          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const textContent = await page.getTextContent();
            const pageText = textContent.items.map((item: any) => item.str !== '' ? item.str : '\n').join('');
            resume = `${resume}${pageText}\n`;
          }
          props.updateResume(resume, selectedFile?.name || '');

        } catch (error) {
          console.error('Error while extracting PDF content:', error);
        }
      };
      fileReader.readAsArrayBuffer(selectedFile);
    }
  }

  return (
    <div className="form-group mt-10">
      <div className="question">
        <p><b>Upload your resume</b></p>
      </div>
      <div className="choices mt-1 pdfUpload">
        <button className="upload-button mr-3" onClick={handleUploadClick}>
          Select File
        </button>
        <p>{fileName ? `${fileName}` : 'No file selected'}</p>
        <input type="file" ref={inputRef} onChange={handleFileChange} accept=".pdf" />
      </div>
    </div>
  );
};

export default PdfExtractor;
